import { DateTime } from "luxon";
import type { MeasurementSequenceGetManyResponseDto } from "../../../backend/src/measurements/dto/measurement-sequence-get-many.dto";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import { PatientSex } from "../../../backend/src/patients/patient-sex";
import type { ReportTemplateVersionCreateResponseDto } from "../../../backend/src/reporting/dto/report-template-version-create.dto";
import {
  getEmptyReportContent,
  type ReportContent,
} from "../../../backend/src/reporting/report-content";
import type {
  StudyClipRegionResponseDto,
  StudyClipResponseDto,
  StudyGetOneResponseDto,
  StudyMeasurementResponseDto,
  StudyMeasurementValueResponseDto,
  StudyRemarkResponseDto,
  StudyReportGetOneResponseDto,
  StudySeriesResponseDto,
} from "../../../backend/src/studies/dto/study-get-one.dto";
import type { StudyReportLatestSignatureResponseDto } from "../../../backend/src/studies/dto/study-report-latest-signature.dto";
import {
  StudyMeasurementDisplayOption,
  StudyMeasurementValueSource,
} from "../../../backend/src/studies/study-measurement-enums";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { getStudyDateTime, toDateTime } from "../../../backend/src/studies/study-time";
import { StudyType } from "../../../backend/src/studies/study-type";
import type { StudyListColumn } from "../../../backend/src/tenants/study-list-column";
import { currentTenant } from "../auth/current-session";

// Define study types for use in the frontend based off the DTOs provided by the backend
export type Study = Omit<StudyGetOneResponseDto, "series"> & {
  series: StudySeries[];
};
export type StudyClip = StudyClipResponseDto & {
  frameStudyClipDetails?: { studyClipId: string; sopInstanceUid: string }[];
};
export type StudySeries = StudySeriesResponseDto;
export type StudyMeasurement = StudyMeasurementResponseDto;
export type StudyMeasurementValue = StudyMeasurementValueResponseDto;
export type StudyClipRegion = StudyClipRegionResponseDto;
export type StudyRemark = StudyRemarkResponseDto;
export type StudyReport = StudyReportGetOneResponseDto & {
  content: ReportContent;
  reportTemplateVersion: ReportTemplateVersion;
};
export type SignatureData = StudyReportLatestSignatureResponseDto;

export type StudyReportDescription = Omit<StudyReport, "content" | "pdf" | "reportTemplateVersion">;

export type ReportTemplateVersion = ReportTemplateVersionCreateResponseDto;
export type MeasurementSequence = MeasurementSequenceGetManyResponseDto[0];

export function getPatientAgeWhenScanned(study: Study): string | undefined {
  // If there is a patient age provided from the DICOM then use that
  if (study.patientAge !== null) {
    return `${Number(study.patientAge.slice(0, -1))}${study.patientAge.slice(3).toLowerCase()}`;
  }

  const age = getPatientAgeInYearsWhenScanned(study);
  if (age === undefined) {
    return;
  }

  return `${age}y`;
}

export function getPatientAgeInYearsWhenScanned(study: Study): number | undefined {
  // If there is a patient age provided from the DICOM then use that
  if (study.patientAge !== null && study.patientAge[3] === "Y") {
    return Number(study.patientAge.slice(0, -1));
  }

  if (study.patientBirthdate === null) {
    return undefined;
  }

  // Fall back to calculating the age in years based on the patient's birth date and the study date
  const birthdate = DateTime.fromISO(study.patientBirthdate, { zone: "utc" });
  const studyDate = getStudyDateTime(study);
  if (studyDate === undefined) {
    return undefined;
  }

  return Math.floor(studyDate.diff(birthdate, "years").years);
}

export function getStudyProcessedClipPercentage(study: Study): string | undefined {
  const percent = ((study.processedClipsCount + study.erroredClipsCount) / study.clipsCount) * 100;

  return `${percent.toFixed()}%`;
}

export function getEmptyStudy(): Study {
  return {
    id: "",
    createdAt: "",
    takenAt: null,
    institution: "",
    referringPhysician: [],
    performingPhysician: [],
    traineeUserId: null,
    technicianUserId: null,
    physicianUserId: null,
    patientMedicalHistory: "",
    patientId: "",
    patientAge: null,
    patientName: [],
    patientBirthdate: null,
    patientSex: PatientSex.Unknown,
    patientWeight: 100,
    patientHeight: 2,
    patientEthnicity: "",
    indication: "",
    isUrgent: false,
    dicomSize: "0",
    type: StudyType.NotSpecified,

    attachedFiles: [],
    reports: [],
    studyInstanceUid: "",
    modality: "",

    assignedUserId: null,
    assigneeLastNotifiedAt: "",

    series: [],
    measurements: [],
    remarks: [],

    clipsCount: 0,
    processedClipsCount: 0,
    erroredClipsCount: 0,

    activeWebhookIntegrationIds: [],
  };
}

export function getEmptyMeasurement(
  m: Partial<StudyMeasurementResponseDto>
): StudyMeasurementResponseDto {
  return {
    name: m.name ?? MeasurementName.CustomValue,
    id: m.id ?? "",
    studyId: m.studyId ?? "",
    customName: m.customName ?? "",
    customUnit: m.customUnit ?? null,
    displayOption: m.displayOption ?? StudyMeasurementDisplayOption.PreferIndexed,
    values: m.values ?? [],
  };
}

export function getEmptyMeasurementValue(
  m: Partial<StudyMeasurementValueResponseDto>
): StudyMeasurementValueResponseDto {
  return {
    id: m.id ?? "",
    measurementId: m.measurementId ?? "",
    measurementTool: m.measurementTool ?? null,
    calculationFormula: null,
    calculationOutputUnit: null,
    calculationVariables: null,
    calculationInputs: [],
    measurementCreationBatchId: m.measurementCreationBatchId ?? "",
    studyClipId: m.studyClipId ?? "",
    source: m.source ?? StudyMeasurementValueSource.Manual,
    selected: m.selected ?? true,
    frame: m.frame ?? 0,
    plane: m.plane ?? null,
    contour: m.contour ?? [],
    createdAt: m.createdAt ?? "",
    createdById: m.createdById ?? "",
    lastUpdatedAt: m.lastUpdatedAt ?? "",
    lastUpdatedById: m.lastUpdatedById ?? "",
    value: m.value ?? 0,
    apiKeyName: m.apiKeyName ?? null,
  };
}

export function getEmptyStudyReportDescription(
  reportTemplateVersionId?: string
): StudyReportDescription {
  return {
    id: "",
    completedAt: null,
    isSigned: false,
    studyId: "",
    createdAt: new Date(),
    createdById: null,
    reportTemplateVersionId: reportTemplateVersionId ?? null,
    completedById: null,
    type: StudyReportType.Preliminary,
    amendmentReason: "",
    analyticsTotalTime: 0,
    analyticsReportPaneClickCount: 0,
    analyticsKeystrokeCount: 0,
    analyticsSentenceLibraryClickCount: 0,
  };
}

export function getEmptyStudyReport(reportTemplateVersion: ReportTemplateVersion): StudyReport {
  return {
    ...getEmptyStudyReportDescription(reportTemplateVersion.id),
    reportTemplateVersion,
    content: getEmptyReportContent(reportTemplateVersion.structure),
  };
}

export function isStudyListColumnVisible(column: StudyListColumn): boolean {
  return !currentTenant.studyListHiddenColumns.includes(column);
}

/** Returns the list of reports sorted by creation timestamp with the most recent report first. */
export function getSortedReports(reports: StudyReportDescription[]): StudyReportDescription[] {
  return [...reports].sort((a, b) => {
    const aTime = toDateTime(a.createdAt);
    const bTime = toDateTime(b.createdAt);
    if (aTime === undefined || bTime === undefined) {
      return 0;
    }

    return bTime.diff(aTime).toMillis();
  });
}

export function getLatestReport(
  reports: StudyReportDescription[]
): StudyReportDescription | undefined {
  return getSortedReports(reports)[0];
}
