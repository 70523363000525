<template>
  <div class="settings-title">Tenant Settings</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button
      v-if="hasAdminTenantCreatePermission"
      class="accented"
      style="justify-self: end"
      @click="showAddTenantModal"
    >
      Create Tenant
    </button>
  </div>

  <SimpleTable
    v-if="!isLoading"
    :columns="columns"
    :items="tenants"
    :sort-column-name="sortColumnName"
    :sort-direction="sortDirection"
    item-key="id"
    @sort="onSortColumn"
    @row-click="onViewTenant"
  >
    <template #cell-name="{ item }">
      <div :data-testid="`view-tenant-${item.name}`">{{ item.name }}</div>
    </template>
    <template #cell-aeTitle="{ value }">
      <code>{{ value }}</code>
    </template>
    <template #cell-dicomSize="{ item }">
      {{ prettyBytes(Number(item.dicomSize)) }}
    </template>
    <template #cell-lastActivityAt="{ item }">
      <template v-if="item.lastActivityAt">
        {{ formatRelativeTime(String(item.lastActivityAt)) }}
      </template>
      <template v-else>-</template>
    </template>
  </SimpleTable>

  <LoadingIndicator v-else style="align-self: center" size="2x" />

  <Modal
    v-if="isAddTenantModalVisible"
    title="Create Tenant"
    :activity-text="isCreatingTenant ? 'Creating tenant' : ''"
    @header-button-click="isAddTenantModalVisible = false"
    @enter-key-press="onCreateTenant"
  >
    <div class="create-tenant-modal">
      <div class="field">
        <b>Name</b>
        <input ref="newTenantNameInputElement" v-model="newTenantName" />
      </div>

      <div class="field">
        <b>AE Title Prefix</b>
        <input v-model="newTenantAeTitlePrefix" maxlength="9" />
      </div>

      <div class="field">
        <b>Salesforce ID (Optional)</b>
        <input v-model="newSalesforceId" />
      </div>

      <button class="accented" :disabled="!isNewTenantComplete" @click="onCreateTenant">
        Create Tenant
      </button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { hasAdminTenantCreatePermission } from "@/auth/authorization";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { useFocus } from "@vueuse/core";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import { computed, ref } from "vue";
import { formatRelativeTime } from "../../../../backend/src/shared/date-time-utils";
import type { TenantGetManyResponseDto } from "../../../../backend/src/tenants/global-admin/dto/tenant-get-many.dto";
import FilterInput from "../../components/FilterInput.vue";
import Modal from "../../components/Modal.vue";
import SimpleTable, {
  SortDirection,
  TableColumnHeaderOptions,
  TableItem,
} from "../../components/ui/SimpleTable.vue";
import router from "../../router";
import { useTenants } from "../../utils/all-tenants";
import { addNotification } from "../../utils/notifications";
import { getRequestErrorMessage } from "../../utils/request-helpers";

const allTenants = useTenants();
const searchTerm = ref("");
const isAddTenantModalVisible = ref(false);

type SortColumnName = keyof TenantGetManyResponseDto[0];

const sortColumnName = ref<SortColumnName>("name");
const sortDirection = ref<SortDirection>("ASC");

const isLoading = ref(false);

const columns = computed<TableColumnHeaderOptions[]>(() => [
  { key: "name", title: "Name", sortable: true },
  { key: "aeTitle", title: "AE Title", sortable: true },
  { key: "studyCount", title: "Studies", sortable: true },
  {
    key: "recentStudyCount",
    title: "Recent Studies",
    sortable: true,
    tooltip: "Number of studies added in the past week",
  },
  { key: "userCount", title: "Users", sortable: true },
  {
    key: "recentActiveUserCount",
    title: "Recent Active Users",
    sortable: true,
    tooltip: "Number of active users in the past week",
  },
  { key: "dicomSize", title: "DICOM Size", sortable: true },
  { key: "lastActivityAt", title: "Last Active", sortable: true },
  { key: "includeInStats", title: "Include in Stats", sortable: true, format: "boolean" },
]);

// Filter, sort, and convert to string values for display
const tenants = computed<TableItem[]>(() => {
  return allTenants.value
    .filter((tenant) => tenant.name.toLowerCase().includes(searchTerm.value.toLowerCase()))
    .sort((tenant0, tenant1) => {
      let items = [tenant0[sortColumnName.value], tenant1[sortColumnName.value]];

      if (sortDirection.value === "DESC") {
        items = [items[1], items[0]];
      }

      if (typeof items[0] === "number") {
        return Number(items[0]) - Number(items[1]);
      }

      return (items[0]?.toString() ?? "").localeCompare(items[1]?.toString() ?? "");
    })
    .map((tenant) => ({
      id: String(tenant.id),
      name: tenant.name,
      aeTitle: tenant.aeTitle,
      dicomSize: tenant.dicomSize,
      studyCount: String(tenant.studyCount),
      recentStudyCount: String(tenant.recentStudyCount),
      userCount: String(tenant.userCount),
      recentActiveUserCount: String(tenant.recentActiveUserCount),
      lastActivityAt: tenant.lastActivityAt !== null ? String(tenant.lastActivityAt) : "",
      includeInStats: String(tenant.includeInStats),
    }));
});

function onSortColumn(columnName: string, direction: SortDirection): void {
  // Validate column name before assignment
  if (isValidSortColumnName(columnName)) {
    sortColumnName.value = columnName;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    sortDirection.value = direction;
  }
}

function isValidSortColumnName(columnName: string): columnName is SortColumnName {
  return [
    "id",
    "name",
    "aeTitle",
    "dicomSize",
    "studyCount",
    "recentStudyCount",
    "userCount",
    "recentActiveUserCount",
    "lastActivityAt",
    "includeInStats",
  ].includes(columnName);
}

async function onViewTenant(tenantId: string): Promise<void> {
  await router.push({ name: "settings-tenants-view", params: { id: tenantId } });
}

function showAddTenantModal(): void {
  isAddTenantModalVisible.value = true;
  newTenantName.value = "";
}

const newTenantName = ref("");
const newTenantAeTitlePrefix = ref("");
const newSalesforceId = ref("");

const newTenantNameInputElement = ref<HTMLInputElement>();
useFocus(newTenantNameInputElement, { initialValue: true });

const isCreatingTenant = ref(false);

const isNewTenantComplete = computed(
  () =>
    newTenantName.value.trim().length > 0 && /^[A-Z0-9-]{2,9}$/.test(newTenantAeTitlePrefix.value)
);

async function onCreateTenant(): Promise<void> {
  if (!isNewTenantComplete.value) {
    return;
  }

  isCreatingTenant.value = true;

  try {
    await axios.post(`/api/global/tenants`, {
      name: newTenantName.value,
      aeTitlePrefix: newTenantAeTitlePrefix.value,
      salesforceAccountId: newSalesforceId.value,
    });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed creating tenant",
    });
    return;
  } finally {
    isCreatingTenant.value = false;
  }

  addNotification({ type: "info", message: "Created new tenant" });

  useTenants(); // Force a refresh of the tenant list

  isAddTenantModalVisible.value = false;
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.create-tenant-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;

  button {
    width: max-content;
    margin: 0 auto;
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
