<template>
  <div class="settings-title">Organization Settings</div>
  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" />

    <button
      v-if="hasAdminOrganizationManagePermission"
      class="accented"
      style="justify-self: end"
      @click="isAddOrganizationModalVisible = true"
    >
      New Organization
    </button>
  </div>
  <SimpleTable
    v-if="!isLoading"
    :columns="columns"
    :items="organizationsForTable"
    @row-click="routeToOrganizationById"
  />

  <LoadingIndicator v-else style="align-self: center" size="2x" />

  <Modal
    v-if="isAddOrganizationModalVisible"
    title="New Organization"
    :activity-text="isCreatingOrganization ? 'Creating organization' : ''"
    @header-button-click="isAddOrganizationModalVisible = false"
  >
    <div class="create-organization-modal">
      <div class="field">
        <b>Name</b>
        <input v-model="newOrganizationName" />
      </div>
    </div>

    <button class="accented" :disabled="!isNewOrganizationComplete" @click="onCreateOrganization">
      Create Organization
    </button>
  </Modal>
</template>

<script setup lang="ts">
import { OrganizationGetResponseDto } from "@/../../backend/src/organizations/dto/organization-get.dto";
import { RestManyResource } from "@/../../backend/src/shared/api/hateaos";
import { hasAdminOrganizationManagePermission } from "@/auth/authorization";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Modal from "@/components/Modal.vue";
import SimpleTable, { TableColumnHeaderOptions, TableItem } from "@/components/ui/SimpleTable.vue";
import router from "@/router";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import FilterInput from "../../components/FilterInput.vue";
const isLoading = ref(true);
const organizations = ref<OrganizationGetResponseDto[]>([]);

// Define columns
const columns = computed<TableColumnHeaderOptions[]>(() => [
  { key: "name", title: "Name" },
  { key: "ssoStrategy", title: "SSO Strategy" },
  { key: "isSsoEnforced", title: "SAML Enforced", format: "boolean" },
]);

// Convert organizations to simple objects with string values
const organizationsForTable = computed<TableItem[]>(() =>
  organizations.value
    .filter((org) => org.name.toLowerCase().includes(searchTerm.value.toLowerCase()))
    .map((org) => ({
      id: String(org.id),
      name: org.name,
      ssoStrategy: org.ssoStrategy !== null ? org.ssoStrategy.toUpperCase() : "NONE",
      isSsoEnforced: String(org.isSsoEnforced),
    }))
);

const searchTerm = ref("");
const isAddOrganizationModalVisible = ref(false);
const isCreatingOrganization = ref(false);
const newOrganizationName = ref("");

const isNewOrganizationComplete = computed(() => {
  return newOrganizationName.value.trim() !== "";
});

async function fetchOrganizations() {
  isLoading.value = true;
  try {
    const response =
      await axios.get<RestManyResource<OrganizationGetResponseDto>>("/api/organizations");

    organizations.value = response.data.data ?? [];
  } finally {
    isLoading.value = false;
  }
}

async function onCreateOrganization(): Promise<void> {
  isCreatingOrganization.value = true;

  try {
    await axios.post("/api/organizations", { name: newOrganizationName.value });
    isAddOrganizationModalVisible.value = false;
    await fetchOrganizations();

    // Find org with new org name and route to it
    const newOrg = organizations.value.find((org) => org.name === newOrganizationName.value);
    if (newOrg) {
      await routeToOrganizationById(newOrg.id);
    }

    newOrganizationName.value = "";
  } finally {
    isCreatingOrganization.value = false;
  }
}

async function routeToOrganizationById(orgId: string): Promise<void> {
  await router.push({ name: "settings-organizations-view", params: { id: orgId } });
}

onMounted(fetchOrganizations);
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}
</style>
