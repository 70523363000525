<template>
  <div class="content">
    <template v-if="hasStudyListViewPermission">
      <div class="study-list-options">
        <FilterInput
          v-model="studyListStore.state.filters.searchFilter"
          data-testid="study-list-filter"
          placeholder="Patient name, ID, institution, etc."
          class="study-list-filter"
        />

        <DatePicker
          v-model="studyListStore.state.filters.dateFilter"
          mode="date"
          is-range
          :max-date="new Date()"
          placeholder="Study date"
          data-testid="study-date-advanced-filter"
          background-color="var(--bg-color-1)"
        />

        <StudyTypeDropdown
          v-if="isStudyListColumnVisible(StudyListColumn.StudyType)"
          class="study-list-filter"
          :model-value="studyListStore.state.filters.studyTypeFilter"
          placeholder="Study type"
          data-testid="study-type-filter"
          @update:model-value="
            (newValue) =>
              studyListStore.setStudyTypeFilter(newValue !== StudyType.NotSpecified ? newValue : '')
          "
        />

        <UserDropdown
          v-model="studyListStore.state.filters.assignedUserFilter"
          placeholder="Assigned to"
          class="study-list-filter"
          data-testid="assigned-user-filter"
          style="max-width: 200px"
        />

        <span class="clear-filters" @click="studyListStore.clearFilters">
          <template
            v-if="
              studyListStore.state.filters.assignedUserFilter !== '' ||
              studyListStore.state.filters.searchFilter !== '' ||
              studyListStore.state.filters.dateFilter !== null ||
              studyListStore.state.filters.studyTypeFilter !== '' ||
              studyListStore.state.filters.reportStatusFilters.some((filter) => filter.value)
            "
          >
            Clear filters
          </template>
        </span>

        <b v-if="studyListStore.totalCount !== undefined" style="text-align: right">
          {{ studyListStore.totalCount }}
          {{ studyListStore.totalCount === 1 ? "result" : "results" }}
        </b>
        <div v-else />

        <IconButton
          icon="file-export"
          tooltip="Export study list"
          @click="showStudyListExportModal = true"
        />

        <BackgroundSelectPopper v-model="selectedBackground" />
      </div>

      <div
        class="studies-table"
        :class="{ 'show-borders': selectedBackground === 'solid' }"
        data-testid="studies-table"
      >
        <div style="display: contents">
          <div class="header-cell" style="padding-left: 0" data-testid="status-filter-dropdown">
            <FilterDropdown
              :filters="studyListStore.state.filters.reportStatusFilters"
              @update:filters="studyListStore.setReportStatusFilters"
            />
          </div>

          <div
            class="header-cell sortable"
            data-testid="patient-name-sort"
            @click="studyListStore.setSortColumn('patientName')"
          >
            <div class="title">Patient Name</div>
            <FontAwesomeIcon
              v-if="studyListStore.state.filters.sortColumnName === 'patientName'"
              :icon="sortIconName"
              class="sort-icon"
            />
          </div>

          <div v-if="isStudyListColumnVisible(StudyListColumn.PatientID)" class="header-cell">
            <div class="title">{{ currentTenant.patientIdLabel }}</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.PatientBirthdate)"
            class="header-cell"
            data-testid="patient-birthdate-header"
          >
            <div class="title">Birthdate</div>
          </div>

          <div
            class="header-cell sortable"
            data-testid="study-date-sort"
            @click="studyListStore.setSortColumn('takenAt')"
          >
            <div class="title no-ellipsis">Study Date</div>
            <FontAwesomeIcon
              v-if="studyListStore.state.filters.sortColumnName === 'takenAt'"
              :icon="sortIconName"
              class="sort-icon"
            />
          </div>

          <div class="header-cell">
            <div class="title">Modality</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.StudyType)"
            class="header-cell"
            data-testid="study-type-header"
          >
            <div class="title">Type</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Institute)"
            class="header-cell sortable"
            @click="studyListStore.setSortColumn('institution')"
          >
            <div class="title">Institute</div>
            <FontAwesomeIcon
              v-if="studyListStore.state.filters.sortColumnName === 'institution'"
              :icon="sortIconName"
              class="sort-icon"
            />
          </div>

          <div v-if="isStudyListColumnVisible(StudyListColumn.PerformedBy)" class="header-cell">
            <div class="title">Performed By</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Trainee)"
            class="header-cell"
            data-testid="trainee-column-header"
          >
            <div class="title">
              {{ currentTenant.traineeLabel }}
            </div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Technician)"
            class="header-cell"
            data-testid="technician-column-header"
          >
            <div class="title">{{ currentTenant.technicianLabel }}</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Physician)"
            class="header-cell"
            data-testid="physician-column-header"
          >
            <div class="title">{{ currentTenant.physicianLabel }}</div>
          </div>

          <div class="header-cell">
            <div class="title">Assignee</div>
          </div>

          <div class="header-cell" />
        </div>

        <StudyListRow
          v-for="study in studyListStore.studies"
          :key="study.id"
          :study="study"
          @view-report="viewReport(study)"
          @study-clicked="loadStudy(study)"
          @study-deleted="studyListStore.reloadStudies"
          @patient-identity-updated="studyListStore.reloadStudies"
        />

        <!-- Loading indicator for first render -->
        <div v-if="studyListStore.studyListQuery.isLoading" class="loading-indicator-container">
          <Transition name="fade">
            <LoadingIndicator size="2x" />
          </Transition>
        </div>

        <div
          v-else-if="
            studyListStore.studyListQuery.hasNextPage ||
            studyListStore.studyListQuery.isFetchingNextPage
          "
          ref="infiniteScrollTrigger"
          class="loading-indicator-container"
        >
          <Transition name="fade">
            <LoadingIndicator v-if="studyListStore.studyListQuery.isFetchingNextPage" size="2x" />
          </Transition>
        </div>

        <div v-if="studyListStore.studyListQuery.isError" class="loading-error">
          Error loading studies
        </div>
      </div>
    </template>

    <div v-else class="missing-permission-message">
      You do not have permission to view the study list.
      <br />
      <br />
      Please contact an administrator to get access.
    </div>
  </div>

  <ViewReportsModal
    v-if="selectedStudy !== undefined"
    :study-id="selectedStudy.id"
    :reports="selectedStudy.reports"
    @close="selectedStudy = undefined"
  />

  <ExportStudyListModal
    v-if="showStudyListExportModal && hasStudyListExportPermission"
    :study-list-filters="{
      studyDateFilter: studyListStore.state.filters.dateFilter,
      assignedUserFilter: studyListStore.state.filters.assignedUserFilter,
    }"
    @close="showStudyListExportModal = false"
  />
</template>

<script setup lang="ts">
import IconButton from "@/components/IconButton.vue";
import router from "@/router";
import { isStudyListColumnVisible, type Study } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useLocalStorage } from "@vueuse/core";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { StudyType } from "../../../backend/src/studies/study-type";
import { StudyListColumn } from "../../../backend/src/tenants/study-list-column";
import { hasStudyListExportPermission, hasStudyListViewPermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import BackgroundSelectPopper from "../components/BackgroundSelectPopper.vue";
import DatePicker from "../components/DatePicker.vue";
import FilterInput from "../components/FilterInput.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import StudyTypeDropdown from "../components/StudyTypeDropdown.vue";
import UserDropdown from "../components/UserDropdown.vue";
import { BASE_URL } from "../environment";
import ViewReportsModal from "../reporting/ViewReportsModal.vue";
import ExportStudyListModal from "./ExportStudyListModal.vue";
import FilterDropdown from "./FilterDropdown.vue";
import StudyListRow from "./StudyListRow.vue";

import { useStudyListStore } from "@/state/stores/study-list";

const studyListStore = useStudyListStore();
const selectedStudy = ref<Study | undefined>();
const showStudyListExportModal = ref(false);
const selectedBackground = useLocalStorage("study-list-background", "solid");
const infiniteScrollTrigger = ref<HTMLElement | null>(null);
let observer: IntersectionObserver | null = null;

const sortIconName = computed(() =>
  studyListStore.state.filters.sortDirection === "ASC" ? "chevron-up" : "chevron-down"
);

const backgroundImageStyle = computed(() => {
  if (selectedBackground.value === "solid") {
    return "none";
  }
  return `url("${BASE_URL}backgrounds/${selectedBackground.value}.png")`;
});

async function loadStudy(study: Study): Promise<void> {
  await router.push({ name: "study-view", params: { id: study.id } });
}

function viewReport(study: Study): void {
  selectedStudy.value = study;
}

onMounted(() => {
  studyListStore.enableQuery();
  setupInfiniteScroll();
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});

// Reattach observer when filters or studies change (DOM updates)
watch(
  [() => studyListStore.studies.length, () => studyListStore.state.filters],
  async () => {
    await nextTick();

    if (observer && infiniteScrollTrigger.value) {
      observer.disconnect();
      observer.observe(infiniteScrollTrigger.value);
    }
  },
  { deep: true }
);

function setupInfiniteScroll(): void {
  observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      if (
        entry.isIntersecting &&
        studyListStore.studyListQuery.hasNextPage &&
        !studyListStore.studyListQuery.isFetchingNextPage
      ) {
        void studyListStore.studyListQuery.fetchNextPage();
      }
    },
    {
      // Add a buffer to the bottom of the list to trigger loading the next page
      // when the user is near the bottom of the list.
      rootMargin: "0px 0px 300px 0px",

      // Trigger loading the next page when the infinite scroll trigger is 1% into the viewport
      threshold: 0.01,
    }
  );

  if (infiniteScrollTrigger.value) {
    observer.observe(infiniteScrollTrigger.value);
  }
}

const cssGridTemplateColumns = computed(() => {
  const columns = ["32px", "minmax(200px, 2fr)"];

  if (isStudyListColumnVisible(StudyListColumn.PatientID)) {
    columns.push("minmax(100px, 1.5fr)");
  }

  if (isStudyListColumnVisible(StudyListColumn.PatientBirthdate)) {
    columns.push("max-content");
  }

  columns.push("max-content", "80px");

  for (const column of [
    StudyListColumn.Institute,
    StudyListColumn.PerformedBy,
    StudyListColumn.Trainee,
    StudyListColumn.Technician,
    StudyListColumn.Physician,
    StudyListColumn.StudyType,
  ]) {
    if (isStudyListColumnVisible(column)) {
      columns.push("minmax(150px, 1.5fr)");
    }
  }

  columns.push("minmax(150px, 1.5fr)", "minmax(78px, auto)");
  return columns.join(" ");
});

const studyListOptionsGridTemplateColumns = computed(() => {
  const columns = ["320px 240px auto auto"];

  if (isStudyListColumnVisible(StudyListColumn.StudyType)) {
    columns.push("auto");
  }

  columns.push("1fr auto auto");
  return columns.join(" ");
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.content,
.header-cell {
  background-attachment: fixed;
  background-size: cover;
  background-color: var(--bg-color-2);
  background-image: v-bind("backgroundImageStyle");
}

.study-list-options {
  display: grid;
  grid-template-columns: v-bind("studyListOptionsGridTemplateColumns");
  align-items: center;
  gap: 12px;
  padding: 12px 16px 12px 12px;
}

.study-list-filter {
  background: var(--bg-color-1);
}

.clear-filters {
  text-decoration: underline;
  margin-top: 4px;
  cursor: pointer;
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.studies-table {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  margin: 0 1rem;

  display: grid;
  grid-template-columns: v-bind("cssGridTemplateColumns");
  grid-auto-rows: 32px;

  &.show-borders {
    :deep(> .grid-table-row) {
      &:hover,
      &.selected {
        color: var(--text-color-2);
        > * {
          background-color: var(--bg-color-4);
        }
      }
      > * {
        border-bottom: 1px solid #434343;

        &:first-child {
          border-left: 1px solid #434343;
        }

        &:nth-child(-n + 2) {
          background-color: var(--bg-color-2);
          position: sticky;
          left: 0;
          z-index: 1;
        }

        &:nth-child(2) {
          left: 32px;
          border-right: 1px solid #434343;
          margin-right: -1px;
          border-left: none;
        }

        &:last-child {
          background-color: var(--bg-color-2);
          border: 1px solid #434343;
          border-top: none;
          position: sticky;
          right: 0;
        }
      }

      &:hover,
      &.selected {
        > * {
          background-color: var(--bg-color-4);
        }
      }
    }

    .header-cell {
      &:first-child {
        border-radius: 5px 0 0 0;
        box-shadow: 10px -10px 0 10px var(--bg-color-2);
        border-left: 1px solid #434343;
      }

      &:nth-child(-n + 2) {
        position: sticky;
        left: 0;
        z-index: 2;
      }

      &:nth-child(2) {
        left: 32px;
        border-right: 1px solid #434343;
        border-left: none;
        margin-right: -1px;
      }

      &:last-child {
        border-radius: 0 5px 0 0;
        position: sticky;
        border: 1px solid #434343;
        right: 0;
        top: 0;
        z-index: 97;
        box-shadow: 10px -10px 0 10px var(--bg-color-2);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color-1);
      }
    }
  }
}

.header-cell {
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  transition: color 1000ms ease;
  z-index: 1;
  padding-left: 8px;

  border-top: 1px solid var(--border-color-1);
  background-color: #2d2d2d;

  &.sortable {
    cursor: pointer;
    gap: 12px;

    &:hover {
      color: var(--text-color-2);

      .sort-icon {
        color: var(--accent-color-2);
      }
    }

    .sort-icon {
      color: var(--accent-color-1);
      transition: color 100ms ease;
      padding-right: 12px;
    }
  }

  .title {
    grid-area: title;
    font-weight: bold;
    font-size: 1.1em;
    white-space: nowrap;

    &:not(.no-ellipsis) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.loading-indicator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  grid-column: 1 / -1;
}

.loading-error {
  font-weight: bold;
  color: red;
  padding: 32px 0;
  text-align: center;
  width: 100%;
  grid-column: 1 / -1;
}

.missing-permission-message {
  flex: 1;
  display: grid;
  place-content: center;
  text-align: center;
}
</style>
