import { useDebounceFn } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { createStudyListActions } from "./actions";
import { getInfiniteStudies } from "./api";
import { createStateComputed, state } from "./state";

export const useStudyListStore = defineStore("studyList", () => {
  const queryEnabled = ref(false);

  // Function to enable the query when the study list is visited
  function enableQuery(): void {
    queryEnabled.value = true;
  }

  // API Queries - only enabled when actually visiting the study list
  const studyListQuery = getInfiniteStudies(queryEnabled);

  // Create computed state properties based on query data
  const stateComputed = createStateComputed(() => studyListQuery.data.value?.pages);

  // Refreshes the study list if there are unprocessed studies
  async function performRefresh(): Promise<void> {
    if (stateComputed.studiesNotProcessedCount.value === 0) {
      state.value.isRefreshingUnprocessedStudies = false;
      return;
    }

    state.value.isRefreshingUnprocessedStudies = true;
    await studyListQuery.refetch();

    if (stateComputed.studiesNotProcessedCount.value > 0) {
      // Schedule another refresh
      void refreshUnprocessedStudies();
    } else {
      state.value.isRefreshingUnprocessedStudies = false;
    }
  }

  const refreshUnprocessedStudies = useDebounceFn(() => {
    void performRefresh();
  }, 20000); // 20 second debounce delay

  // Watch for changes to start the refresh cycle
  watch(stateComputed.studiesNotProcessedCount, (count, prevCount) => {
    // Only start a new refresh cycle if:
    // 1. We went from 0 to some unprocessed studies, or
    // 2. We're not already refreshing
    if (prevCount === 0 && count > 0 && !state.value.isRefreshingUnprocessedStudies) {
      void refreshUnprocessedStudies();
    }
  });

  // Create actions with access to the query
  const actions = createStudyListActions(studyListQuery);

  return {
    state,
    studyListQuery,
    enableQuery,
    ...stateComputed,
    ...actions,
  };
});
