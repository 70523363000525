import type { UseInfiniteQueryReturnType } from "@tanstack/vue-query";
import type { Study } from "../../../utils/study-data";
import type { SortColumnName, StudyListQueryPage, StudyListStatusFilter } from "./state";
import { getInitialReportStatusFilters, state } from "./state";

interface StudyListResult {
  pages: StudyListQueryPage[];
}

/**
 * Actions for mutating the study list store.
 *
 * Note; our infinite query setup is keyed based on filters.
 * If any filter related actions are called, it will trigger a refetch.
 *
 * @param studyListQuery The infinite query instance from the store
 * @returns An object containing all the study list actions
 */
export function createStudyListActions(
  studyListQuery: UseInfiniteQueryReturnType<StudyListResult, Error>
) {
  /**
   * Reloads the studies by triggering a refetch
   */
  function reloadStudies(): void {
    clearStudyUpdates();
    void studyListQuery.refetch();
  }

  /**
   * Used for searching studies by patient name, or other criteria
   *
   * @param filter The search filter text
   */
  function setSearchFilter(filter: string): void {
    state.value.filters.searchFilter = filter;
  }

  /**
   * Used for filtering studies by date range
   *
   * @param filter The date filter object or null to clear
   */
  function setDateFilter(filter: { start: Date; end: Date } | null): void {
    state.value.filters.dateFilter = filter;
  }

  /**
   * Filters studies by assigned user
   *
   * @param filter The assigned user filter
   */
  function setAssignedUserFilter(filter: string): void {
    state.value.filters.assignedUserFilter = filter;
  }

  /**
   * Filters studies by study type. i.e. Echo Stress.
   *
   * @param filter The study type filter
   */
  function setStudyTypeFilter(filter: string): void {
    state.value.filters.studyTypeFilter = filter;
  }

  /**
   * Filters studies by report status. i.e. Completed, In Progress, etc.
   *
   * @param filters The report status filters
   */
  function setReportStatusFilters(filters: StudyListStatusFilter[]): void {
    state.value.filters.reportStatusFilters = filters;
  }

  /**
   * Sets the sort column and toggles direction if already selected
   *
   * @param columnName The column name to sort by
   */
  function setSortColumn(columnName: SortColumnName): void {
    if (state.value.filters.sortColumnName === columnName) {
      state.value.filters.sortDirection =
        state.value.filters.sortDirection === "DESC" ? "ASC" : "DESC";
    } else {
      state.value.filters.sortColumnName = columnName;
      state.value.filters.sortDirection = "ASC";
    }
  }

  /**
   * Clears all filters to their default values
   */
  function clearFilters(): void {
    state.value.filters.searchFilter = "";
    state.value.filters.dateFilter = null;
    state.value.filters.assignedUserFilter = "";
    state.value.filters.studyTypeFilter = "";
    state.value.filters.reportStatusFilters = getInitialReportStatusFilters();
    state.value.filters.sortColumnName = "takenAt";
    state.value.filters.sortDirection = "DESC";
  }

  function updateStudy(studyId: string, updates: Partial<Study>): void {
    state.value.updatedStudies[studyId] = { ...state.value.updatedStudies[studyId], ...updates };
  }

  function clearStudyUpdates(): void {
    state.value.updatedStudies = {};
  }

  return {
    reloadStudies,
    setSearchFilter,
    setDateFilter,
    setAssignedUserFilter,
    setStudyTypeFilter,
    setReportStatusFilters,
    setSortColumn,
    clearFilters,
    updateStudy,
  };
}
