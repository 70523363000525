<template>
  <a @click="fileInputElement?.click()">
    <FontAwesomeIcon data-testid="upload-file-button" icon="upload" />
    <b>Upload</b>

    <input
      ref="fileInputElement"
      data-testid="upload-file-input"
      multiple
      hidden
      type="file"
      @change="
        isUploadFilesModalVisible = true;
        selectedFileCount = fileInputElement?.files?.length ?? 0;
      "
    />

    <Modal
      v-if="isUploadFilesModalVisible"
      title="Upload Files"
      @header-button-click="hideUploadFilesModal"
    >
      <div class="deidentify-container">
        <div v-if="currentTenant.isDeidentificationPermitted">
          <div class="toggle">
            <b>Enable Deidentification</b>
            <ToggleSwitch
              v-model="isDeidentificationEnabled"
              data-testid="enable-deidentification"
            />
          </div>

          <p>
            Deidentification means certain patient and study fields will not be shown, such as the
            patient name, ID, and birthdate.
          </p>
        </div>

        <button class="accented" data-testid="upload-files-button" @click="onUploadFiles()">
          Upload {{ selectedFileCount }} File{{ selectedFileCount !== 1 ? "s" : "" }}
        </button>
      </div>
    </Modal>
  </a>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useEventListener } from "@vueuse/core";
import { ref } from "vue";
import { currentTenant } from "../auth/current-session";
import { uploadJobStore } from "../state/stores/upload-job.store";

const fileInputElement = ref<HTMLInputElement | null>(null);

const isUploadFilesModalVisible = ref(false);

function hideUploadFilesModal(): void {
  // Clear out the value on the input field so that repeated selection of the same file(s)
  // triggers a re-upload
  fileInputElement.value!.value = "";

  isUploadFilesModalVisible.value = false;
}

const isDeidentificationEnabled = ref(false);

const selectedFileCount = ref(0);

const { getActiveUploadJobCount, addUploadJobsFromFileList } = uploadJobStore();

async function onUploadFiles(): Promise<void> {
  isUploadFilesModalVisible.value = false;

  if (fileInputElement.value?.files) {
    await addUploadJobsFromFileList(fileInputElement.value.files, isDeidentificationEnabled.value);

    fileInputElement.value.value = "";
  }
}

// If there are uploads in progress when the user tries to close the page then prompt them that they
// may want to wait for the uploads to complete. Unfortunately it's not possible to control the text
// of the prompt, so they'll likely just get a generic "you have unsaved changes" message.
function onBeforeUnload(e: Event): void {
  if (getActiveUploadJobCount() !== 0) {
    // Both of the following are needed for it to work in Firefox, Safari, and Chrome
    e.preventDefault();
    e.returnValue = true;
  }
}

useEventListener(window, "beforeunload", onBeforeUnload);
</script>

<style scoped lang="scss">
.deidentify-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 440px;
}

.toggle {
  display: flex;
  gap: 16px;
  align-items: center;
}

button {
  width: max-content;
  align-self: center;
}
</style>
