import axios, { type AxiosResponse } from "axios";
import saveAs from "file-saver";
import JSZip from "jszip";
import { defineStore } from "pinia";
import prettyBytes from "pretty-bytes";
import type { Ref } from "vue";
import { reactive, ref, shallowRef } from "vue";
import { type StudyGetOneResponseDto } from "../../../../backend/src/studies/dto/study-get-one.dto";
import { getClips } from "../../../../backend/src/studies/study-helpers";
import { addNotification } from "../../utils/notifications";
import { type Study } from "../../utils/study-data";

export enum DownloadStatus {
  Downloading = "downloading",
  Ready = "ready",
  Failed = "failed",
  UnableToDownload = "unable-to-download",
  Downloaded = "downloaded",
}

export const useDownloadStore = defineStore("downloads", () => {
  const studies = shallowRef<DownloadItem[]>([]);
  const isNewDownload = ref(false);
  const numberOfDownloads = ref(0);

  async function addStudy(study: Study) {
    const length = studies.value.push(createDownloadItem(study));

    isNewDownload.value = true;
    numberOfDownloads.value = length;

    await studies.value[length - 1].getStudy();
    await studies.value[length - 1].downloadStudyDicomFiles();
  }

  function removeStudy(studyId: string) {
    const index = studies.value.findIndex((item) => item.study.id === studyId);
    studies.value[index].abortDownload();
    studies.value.splice(index, 1);

    numberOfDownloads.value = studies.value.length;
  }

  function clearDownloads() {
    studies.value = studies.value.filter(
      (download: DownloadItem) => download.status.value === DownloadStatus.Downloading
    );
    numberOfDownloads.value = studies.value.length;
  }

  async function saveDicomZipFile(studyId: string) {
    const download = studies.value.find((item) => item.study.id === studyId);
    if (download === undefined) {
      return;
    }

    await download.saveDicomZipFile();
    download.status.value = DownloadStatus.Downloaded;
  }

  return {
    studies,
    addStudy,
    removeStudy,
    clearDownloads,
    saveDicomZipFile,
    isNewDownload,
    numberOfDownloads,
  };
});

interface DownloadItem {
  study: Study;
  downloadProgress: number[];
  status: Ref<DownloadStatus>;

  abortDownload(): void;
  getStudy(): Promise<void>;
  downloadStudyDicomFiles(): Promise<void>;
  saveDicomZipFile(): Promise<void>;
  getTextForDownloadStatus(): string;
  getDownloadState(): DownloadStatus;
}

function createDownloadItem(newStudy: Study): DownloadItem {
  let study: StudyGetOneResponseDto = newStudy;
  const downloadProgress = reactive(new Array<number>(study.clipsCount + 1).fill(0));
  const zipFile = new JSZip();
  const abortController = new AbortController();
  const status = ref<DownloadStatus>(DownloadStatus.Downloading);

  function abortDownload() {
    abortController.abort();
    status.value = DownloadStatus.Failed;
  }

  function getDownloadState(): DownloadStatus {
    return status.value;
  }

  async function downloadStudyClipDicomFile(clipId: string, clipIndex: number) {
    let response: AxiosResponse<Blob> | undefined = undefined;

    try {
      response = await axios.get<Blob>(
        `/api/studies/${study.id}/clips/${clipId}/image-data?type=dicom`,
        {
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total === undefined) {
              return;
            }

            downloadProgress[1 + clipIndex] = progressEvent.loaded / progressEvent.total;
          },
          timeout: 0,
          responseType: "blob",
          signal: abortController.signal,
        }
      );
    } catch {
      return undefined;
    }

    return response.data;
  }

  async function fetchStudyDicomDir() {
    let response: AxiosResponse<Blob> | undefined = undefined;

    try {
      response = await axios.get<Blob>(`/api/studies/${study.id}/dicomdir`, {
        responseType: "blob",
        signal: abortController.signal,
      });
    } catch {
      addNotification({ type: "error", message: "Failed generating DICOMDIR for study" });
      return undefined;
    }

    return response.data;
  }

  async function saveDicomZipFile() {
    try {
      const blob = await zipFile.generateAsync({ type: "blob" });

      saveAs(blob, `HeartLab - DICOMs for ${study.studyInstanceUid}.zip`);
    } catch (error) {
      addNotification({ type: "error", message: "Failed saving DICOMs to ZIP file" });
      return;
    }
  }

  async function getStudy() {
    let response: AxiosResponse<StudyGetOneResponseDto> | undefined = undefined;

    try {
      response = await axios.get<StudyGetOneResponseDto>(`/api/studies/${study.id}`, {
        signal: abortController.signal,
      });
    } catch {
      if (!abortController.signal.aborted) {
        addNotification({ type: "error", message: "Failed getting study information" });
        status.value = DownloadStatus.Failed;
      }

      return;
    }

    study = response.data;
    downloadProgress[0] = 1;

    if (getClips(study).some((clip) => clip.deidentify)) {
      addNotification({
        type: "error",
        message: "Study cannot be downloaded because it is deidentified",
      });
      status.value = DownloadStatus.UnableToDownload;
      abortController.abort();
    }
  }

  async function downloadStudyDicomFiles() {
    // Generate DICOMDIR file
    const dicomdirBlob = await fetchStudyDicomDir();
    if (dicomdirBlob === undefined) {
      return;
    }

    zipFile.file("DICOMDIR", dicomdirBlob);

    // Sort the files by SeriesInstanceUID then SOP instance UID which is what the DICOMDIR expects
    const clips = study.series
      .sort((a, b) => a.seriesInstanceUid.localeCompare(b.seriesInstanceUid))
      .flatMap((s) => s.clips.sort((a, b) => a.sopInstanceUid.localeCompare(b.sopInstanceUid)));

    // Handle faked CT/MR clips
    const clipsToDownload: string[] = clips.flatMap((c) => {
      if (c.frameStudyClipDetails === undefined) {
        return [c.id];
      } else {
        return [...c.frameStudyClipDetails]
          .sort((a, b) => a.sopInstanceUid.localeCompare(b.sopInstanceUid))
          .map((d) => d.studyClipId);
      }
    });

    for (const [clipIndex, studyClipId] of clipsToDownload.entries()) {
      const blob = await downloadStudyClipDicomFile(studyClipId, clipIndex);
      if (blob === undefined) {
        if (!abortController.signal.aborted) {
          addNotification({ type: "error", message: "Failed downloading DICOM file" });
        }
        return;
      }

      zipFile.file(`${clipIndex}`.padStart(16, "0"), blob);
    }

    status.value = DownloadStatus.Ready;
  }

  function getTextForDownloadStatus(): string {
    let downloadType = "";

    switch (status.value) {
      case DownloadStatus.Downloading:
        downloadType = "Downloading";
        break;
      case DownloadStatus.Ready:
        downloadType = "Ready";
        break;
      case DownloadStatus.Downloaded:
        downloadType = "Downloaded";
        break;
      case DownloadStatus.Failed:
        downloadType = "Failed";
        break;
      case DownloadStatus.UnableToDownload:
        downloadType = "Cannot Download";
        break;
    }

    return `${downloadType} ${prettyBytes(parseInt(study.dicomSize))}`;
  }

  return {
    study,
    downloadProgress,
    status,
    abortDownload,
    getStudy,
    downloadStudyDicomFiles,
    saveDicomZipFile,
    getTextForDownloadStatus,
    getDownloadState,
  };
}
