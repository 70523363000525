import type { AxiosResponse } from "axios";
import axios from "axios";
import JSZip from "jszip";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { reactive, ref, watch } from "vue";
import type { StorageGetPresignedUrlResponseDto } from "../../../../backend/src/storage/dto/get-presigned-url.dto";
import { ObjectType } from "../../../../backend/src/storage/object-type";
import { UPLOAD_SIZE_LIMIT } from "../../../../backend/src/storage/upload-size-limit";
import type { IngestUploadedFileResponseDto } from "../../../../backend/src/studies/dto/ingest-uploaded-file.dto";
import { updateLastActivityTimestamp } from "../../auth/auto-sign-out";
import { addNotification } from "../../utils/notifications";
import { useStudyListStore } from "./study-list";

export enum UploadJobState {
  Pending = "pending",
  InProgress = "inProgress",
  Completed = "completed",
  Error = "error",
  Cancelled = "cancelled",
  Info = "info",
}

export interface UploadJob {
  id: string;
  title: string;
  progress?: number;
  state: UploadJobState;
  message?: string;
  isZip?: boolean;
  abortController?: AbortController;
  file?: File;
  deidentify?: boolean;
}

export const uploadJobStore = defineStore("uploadJobs", () => {
  const UPLOAD_CONCURRENCY = 8;

  const uploadJobs = ref<UploadJob[]>([]);
  const studyListStore = useStudyListStore();

  // When the number of active uploads goes from 0 to non-zero, refresh the study list
  let previousActiveCount = 0;
  watch(
    () => getActiveUploadJobCount(),
    (currentCount) => {
      // If we had active uploads before and now we don't, refresh the study list
      if (previousActiveCount > 0 && currentCount === 0) {
        // Slight delay to ensure backend has processed the uploads
        setTimeout(() => {
          studyListStore.reloadStudies();
        }, 500);
      }
      previousActiveCount = currentCount;
    }
  );

  // Core store management functions
  function addUploadJob(uploadJob: UploadJob) {
    uploadJobs.value.push(uploadJob);
  }

  // State check/utility functions
  function getActiveUploadJobCount(): number {
    return uploadJobs.value.filter(
      (uploadJob) =>
        (uploadJob.state === UploadJobState.Pending ||
          uploadJob.state === UploadJobState.InProgress) &&
        uploadJob.isZip !== true
    ).length;
  }

  function hasFilesWithState(state: UploadJobState): boolean {
    return uploadJobs.value.some((job) => job.state === state);
  }

  function isRetryableError(uploadJob: UploadJob): boolean {
    if (uploadJob.state !== UploadJobState.Error && uploadJob.state !== UploadJobState.Cancelled) {
      return false;
    }
    return uploadJob.message === "Failed uploading file";
  }

  function clearNotifications(): void {
    uploadJobs.value = uploadJobs.value.filter((job) => {
      const keep = job.state === UploadJobState.Pending || job.state === UploadJobState.InProgress;
      if (!keep) {
        // Even though we reassign the uploadJobs value, references to large resources are still
        // held in memory
        clearFileReference(job);
      }
      return keep;
    });
  }

  // File validation and processing
  function isDicom(fileBuffer: Uint8Array): boolean {
    return (
      fileBuffer.length >= 132 &&
      fileBuffer[128] === 68 &&
      fileBuffer[129] === 73 &&
      fileBuffer[130] === 67 &&
      fileBuffer[131] === 77
    );
  }

  async function getZipObjectBlob(zipObject: JSZip.JSZipObject): Promise<Blob | null> {
    // Ignore OS metadata files
    if (
      zipObject.dir ||
      zipObject.name.startsWith("__MACOSX/") ||
      zipObject.name.endsWith(".DS_Store") ||
      zipObject.name.endsWith("desktop.ini") ||
      zipObject.name.toLowerCase().endsWith("dicomdir")
    ) {
      return null;
    }

    const blob = await zipObject.async("blob");
    if (blob.size > UPLOAD_SIZE_LIMIT) {
      addFileTooLargeEntry(zipObject.name);
      return null;
    }

    const zipObjectUint8Array = new Uint8Array(await blob.arrayBuffer());
    if (!isDicom(zipObjectUint8Array)) {
      return null;
    }

    return blob;
  }

  function addFileTooLargeEntry(filename: string): void {
    addUploadJob({
      id: uuidv4(),
      title: filename,
      state: UploadJobState.Error,
      message: "This file exceeds the upload size limit of 1GB",
    });

    addNotification({
      type: "error",
      message: "One or more files exceeded the upload size limit",
    });
  }

  // Upload core functions
  async function getPresignedUrl(
    contentLength: number
  ): Promise<StorageGetPresignedUrlResponseDto> {
    const queryParams = new URLSearchParams({
      contentLength: String(contentLength),
    });

    let response: AxiosResponse<StorageGetPresignedUrlResponseDto> | undefined = undefined;

    try {
      response = await axios.get<StorageGetPresignedUrlResponseDto>(
        `/api/storage/presigned-url?${queryParams.toString()}`
      );
    } catch (error) {
      throw Error("Failed getting presigned URL");
    }

    return response.data;
  }

  async function uploadFile(url: string, file: File, uploadJob: UploadJob): Promise<void> {
    try {
      await axios.put(url, file, {
        onUploadProgress(progressEvent) {
          if (progressEvent.total === undefined) {
            return;
          }

          uploadJob.progress = progressEvent.loaded / progressEvent.total;
          uploadJobs.value = [...uploadJobs.value];

          // Prevent automatic sign out while uploads are occurring
          updateLastActivityTimestamp();
        },
        timeout: 0,
        signal: uploadJob.abortController?.signal,
        headers: { "x-amz-tagging": `Type=${ObjectType.UploadedFile}` },
      });
    } catch (error) {
      if (uploadJob.abortController?.signal.aborted === true) {
        uploadJob.state = UploadJobState.Cancelled;
      } else {
        throw Error("Failed uploading file");
      }
    }
  }

  async function ingestUploadedFile(
    uploadedFileName: string,
    uploadJob: UploadJob,
    deidentify: boolean
  ): Promise<void> {
    let response: AxiosResponse<IngestUploadedFileResponseDto> | undefined = undefined;
    try {
      response = await axios.post<IngestUploadedFileResponseDto>(
        `/api/studies/ingest-uploaded-file`,
        { uploadedFileName, deidentify }
      );
    } catch {
      throw Error("Failed ingesting DICOM file");
    }

    if (response.data.isDuplicate) {
      uploadJob.state = UploadJobState.Info;
      uploadJob.message = "This DICOM is already present";
    } else {
      uploadJob.state = UploadJobState.Completed;
      uploadJob.message = undefined;
    }
  }

  function clearFileReference(uploadJob: UploadJob): void {
    uploadJob.file = undefined;
    uploadJob.abortController = undefined;
  }

  async function performUpload(uploadJob: UploadJob) {
    try {
      if (uploadJob.state === UploadJobState.Pending) {
        uploadJob.state = UploadJobState.InProgress;
      }

      const { presignedUrl, uploadedFileName } = await getPresignedUrl(uploadJob.file?.size ?? 0);

      if (uploadJob.file === undefined) {
        throw Error("No file to upload");
      }

      await uploadFile(presignedUrl, uploadJob.file, uploadJob);

      if (uploadJob.state !== UploadJobState.Cancelled) {
        await ingestUploadedFile(uploadedFileName, uploadJob, uploadJob.deidentify ?? false);
      }
    } catch (error) {
      console.error(error);

      uploadJob.state = UploadJobState.Error;
      uploadJob.message = "Failed uploading file";
      // Don't clear the file reference on error so it can be retried
      addNotification({ type: "error", message: "One or more files failed to upload" });
      return;
    }

    // Only clear file reference after success
    clearFileReference(uploadJob);
  }

  // Upload job management functions
  function startFileUpload(title: string, file: File, deidentify: boolean): void {
    const uploadJob: UploadJob = reactive({
      id: uuidv4(),
      title,
      uploadProgress: 0,
      state: UploadJobState.Pending,
      abortController: new AbortController(),
      message: "Upload is pending",
      file,
      deidentify,
    });

    addUploadJob(uploadJob);

    const timer = setInterval(() => {
      // Limit the number of upload PUT requests that are run concurrently
      if (
        uploadJobs.value.filter(
          (uploadJob) => uploadJob.state === UploadJobState.InProgress && uploadJob.isZip !== true
        ).length >= UPLOAD_CONCURRENCY
      ) {
        return;
      }

      clearInterval(timer);

      uploadJob.state = UploadJobState.InProgress;

      void performUpload(uploadJob);
    }, 100);
  }

  async function uploadZipDicomEntries(file: File, deidentify: boolean): Promise<void> {
    const zipExtraction: UploadJob = reactive({
      id: uuidv4(),
      title: `Extracting ${file.name} ...`,
      progress: 0,
      state: UploadJobState.InProgress,
      isZip: true,
    });

    addUploadJob(zipExtraction);

    try {
      const zip = await JSZip.loadAsync(file);
      const filenames = Object.keys(zip.files);

      const processedFiles = await Promise.all(
        filenames.map(async (name, index) => {
          const blob = await getZipObjectBlob(zip.files[name]);
          if (blob === null) {
            return null;
          }

          const dicomFile = new File([blob], name);
          startFileUpload(`${file.name}: ${name}`, dicomFile, deidentify);
          zipExtraction.progress = index / filenames.length;

          return true;
        })
      );

      // Count successful files after all promises complete
      const dicomCount = processedFiles.filter((result) => result !== null).length;

      if (dicomCount === 0) {
        zipExtraction.state = UploadJobState.Info;
        zipExtraction.message = "This ZIP file contained no DICOMs";
        addNotification({ type: "error", message: "ZIP file contained no DICOMs" });
      } else {
        zipExtraction.title = `Extracted ${file.name}`;
        zipExtraction.state = UploadJobState.Completed;
        zipExtraction.message = `Found ${dicomCount} DICOM files in this ZIP to upload`;
      }
    } catch {
      zipExtraction.state = UploadJobState.Error;
      zipExtraction.message = "Failed reading this file as a ZIP";
      addNotification({ type: "error", message: "Error reading ZIP file" });
    }
  }

  // Public API functions
  async function addUploadJobsFromFileList(selectedFiles: FileList, deidentify: boolean) {
    for (const file of selectedFiles) {
      if (file.type === "application/zip" || file.type === "application/x-zip-compressed") {
        await uploadZipDicomEntries(file, deidentify);
      } else if (isDicom(new Uint8Array(await file.arrayBuffer()))) {
        if (file.size > UPLOAD_SIZE_LIMIT) {
          addFileTooLargeEntry(file.name);
          continue;
        }

        startFileUpload(file.name, file, deidentify);
      } else {
        uploadJobs.value.push({
          id: uuidv4(),
          title: file.name,
          state: UploadJobState.Error,
          message: "This file is neither a DICOM nor a ZIP",
          file: undefined,
        });

        addNotification({
          type: "error",
          message: "One or more files were not of a supported type",
        });
      }
    }
  }

  async function retryUploadJob(uploadJob: UploadJob) {
    // Though the retry is already checked in the UI, we check here again to ensure the retry
    // is not attempted if the job is not in a retryable state
    if (!isRetryableError(uploadJob)) {
      return;
    }

    const retryJob = uploadJobs.value.find((job) => job.id === uploadJob.id);

    if (retryJob && retryJob.file) {
      retryJob.state = UploadJobState.Pending;
      retryJob.message = "Upload is pending";
      retryJob.abortController = new AbortController();

      await performUpload(retryJob);
    }
  }

  async function retryAllFailedUploads() {
    // Use Promise.all with map instead of forEach to properly handle async operations
    await Promise.all(
      uploadJobs.value
        .filter((job) => job.state === UploadJobState.Error)
        .map((job) => retryUploadJob(job))
    );
  }

  async function cancelInProgressUploadJobs(): Promise<void> {
    uploadJobs.value.forEach((uploadJob) => {
      uploadJob.abortController?.abort();
      uploadJob.state = UploadJobState.Cancelled;
      clearFileReference(uploadJob);
    });

    // Wait for upload jobs to no longer be in progress
    while (getActiveUploadJobCount() !== 0) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  function hasRetryableErrors(): boolean {
    return uploadJobs.value.some((job) => isRetryableError(job));
  }

  return {
    // Public API
    addUploadJobsFromFileList,
    retryUploadJob,
    retryAllFailedUploads,
    isRetryableError,
    uploadJobs,
    cancelInProgressUploadJobs,
    getActiveUploadJobCount,
    clearNotifications,
    hasFilesWithState,
    hasRetryableErrors,
  };
});
