import type { Study } from "@/utils/study-data";
import { useStorage } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { StudyReportStatus } from "../../../reporting/report-status";

export type SortColumnName = "institution" | "patientName" | "takenAt";
export type SortDirection = "ASC" | "DESC";

export interface StudyListStatusFilter {
  reportStatus: StudyReportStatus | null;
  filterText: string;
  value: boolean;
}

export interface StudyListFilters {
  searchFilter: string;
  dateFilter: { start: Date; end: Date } | null;
  assignedUserFilter: string;
  studyTypeFilter: string;
  reportStatusFilters: StudyListStatusFilter[];
  sortColumnName: SortColumnName;
  sortDirection: SortDirection;
}

export interface StudyListState {
  filters: StudyListFilters;
  pageSize: number;
  isRefreshingUnprocessedStudies: boolean;
  updatedStudies: Record<string, Partial<Study> | undefined>;
}

// Create persistent reactive references for sort preferences
const persistentSortColumnName = useStorage<SortColumnName>(
  "study-list-sort-column-name",
  "takenAt"
);
const persistentSortDirection = useStorage<SortDirection>("study-list-sort-direction", "DESC");

export const INITIAL_FILTERS: StudyListFilters = {
  searchFilter: "",
  dateFilter: null,
  assignedUserFilter: "",
  studyTypeFilter: "",
  reportStatusFilters: getInitialReportStatusFilters(),
  sortColumnName: persistentSortColumnName.value,
  sortDirection: persistentSortDirection.value,
};

/**
 * State for the study list store is mostly concerned with the current query status.
 *
 * Study List data is maintained through useInfiniteQuery.
 */
export const state = ref<StudyListState>({
  filters: INITIAL_FILTERS,
  pageSize: 50,
  isRefreshingUnprocessedStudies: false,
  updatedStudies: {},
});

// Watch for changes to sorting preferences and persist them
watch(
  () => state.value.filters.sortColumnName,
  (newValue) => {
    persistentSortColumnName.value = newValue;
  }
);

watch(
  () => state.value.filters.sortDirection,
  (newValue) => {
    persistentSortDirection.value = newValue;
  }
);

export interface StudyListQueryPage {
  studies: Study[];
  count: number;
}

/**
 * Factory function to create computed properties for the study list state based on the query results.
 * This allows the computed properties to be reactive and update when the query results change.
 *
 * @param getPages - A function that returns the query results as an array of StudyListQueryPage objects.
 * @returns An object containing the computed properties.
 */
export function createStateComputed(getPages: () => StudyListQueryPage[] | undefined) {
  // Computed property to flatten all studies into a single array for rendering
  const studies = computed<Study[]>(() => {
    const pages = getPages();
    if (!pages) return [];

    // Apply the optimistic updates to the studies
    return pages
      .flatMap((page) => page.studies)
      .map((study) => {
        const update = state.value.updatedStudies[study.id];
        return update ? { ...study, ...update } : study;
      });
  });

  const latestPageLength = computed<number>(() => {
    const pages = getPages();
    if (!pages) return 0;

    return pages[pages.length - 1].studies.length;
  });

  // Computed property for counting studies that need processing
  const studiesNotProcessedCount = computed<number>(() => {
    return studies.value.filter((study) => study.processedClipsCount !== study.clipsCount).length;
  });

  // Computed property to get total count
  const totalCount = computed<number>(() => {
    const firstPage = getPages()?.[0];
    return firstPage?.count ?? 0;
  });

  return {
    studies,
    latestPageLength,
    studiesNotProcessedCount,
    totalCount,
  };
}

export function getReportStatusName(reportStatus: StudyReportStatus): string {
  switch (reportStatus) {
    case StudyReportStatus.AmendmentInProgress:
      return "Amendment in progress";
    case StudyReportStatus.PreliminaryReportApproved:
      return "Preliminary approved";
    case StudyReportStatus.ReportFinalized:
      return "Finalized";
    case StudyReportStatus.ReportInProgress:
      return "In progress";
    default:
      return "";
  }
}

export function getInitialReportStatusFilters(): StudyListStatusFilter[] {
  return [
    { reportStatus: null, filterText: "Not started", value: false },
    ...Object.values(StudyReportStatus).map((reportStatus: StudyReportStatus) => ({
      reportStatus,
      filterText: getReportStatusName(reportStatus),
      value: false,
    })),
  ];
}
