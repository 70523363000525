import { z } from "zod";
import { ReportStructureInvalidException } from "../exceptions/report-structure-invalid.exception";
import { MeasurementName } from "../measurements/measurement-names";

/*
 * Report structure schemas and types.
 */

/** The supported fonts that can be used to render a report. */
export enum ReportTemplateFont {
  /**
   * Default sans serif font that maps to a common sans serif font such as Helvetica, Arial, or
   * Liberation Sans.
   */
  SansSerif = "sansSerif",

  /**
   * Gilmer is a distinctive geometric sans serif font with big z-height values for characters. It
   * was specifically requested by a customer for use on their reports.
   */
  Gilmer = "gilmer",
}

export const ReportSectionTypeSchema = z.enum(["heading", "fields", "table", "rwma"]);

const ReportSectionStructuredFieldTypeSchema = z.enum(["text", "dropdown", "measurement"]);

const ReportStructuredFieldMeasurementFieldSchema = z.strictObject({
  // The name of the measurement to show.
  name: z.nativeEnum(MeasurementName),

  // Whether or not the measurement should be shown as indexed. Note that not all measurements
  // can be indexed.
  isIndexed: z.boolean(),
});

// A `text` field shows text on the report. The text can be static or be editable by the user, and
// default text for the field can be provided. This default value can include dynamic content, which
// are specified using double curly braces.
const ReportStructuredFieldTextSchema = z.strictObject({
  type: z.literal("text"),

  // The default text content of this field. This can optionally specify dynamic content using
  // double curly brace notation that contains arbitrary calculations.
  //
  // An example of a text value with dynamic content is`"{{ patient.age }} years"`.
  text: z.string(),

  // Whether the text shown for this field, as specified by the `text` value, can be edited when
  // filling in the report.
  isEditable: z.boolean(),
});

// A `measurement` field takes its value from a measurement in the study, where the specific
// measurement is specified by `measurementName`. The measurement can optionally be indexed by BSA,
// and the value can be allowed to be edited by the user on the report if desired.
const ReportStructuredFieldMeasurementSchema = z.strictObject({
  type: z.literal("measurement"),

  // The name of the measurement to show for this field.
  measurementName: z.nativeEnum(MeasurementName).nullable(),

  // Whether or not the measurement should be shown as indexed.
  isIndexed: z.boolean(),

  // Whether the value in this field can be edited when filling in the report.
  isEditable: z.boolean(),
});

// A `dropdown` field has its value selected from a dropdown. Alternatively, a custom free text
// value can be entered if there is an option with an id of `custom`.
const ReportStructuredFieldDropdownSchema = z.strictObject({
  type: z.literal("dropdown"),

  options: z.array(
    z.strictObject({
      // The unique id for this structured field option
      id: z.string(),

      // The name for this structured field option to display in the dropdown
      name: z.string(),

      // The sentence to include in the report when this structured field option is selected. This
      // is only relevant when the field is of type "sentence".
      sentence: z.string(),
    })
  ),

  // Whether multiple options can be selected from the dropdown list.
  isMultiSelect: z.boolean(),

  // Whether the options are mapped to sentences on the report.
  isSentence: z.boolean(),
});

// The following properties are common to all structured fields.
const ReportStructuredFieldCommonSchema = z.strictObject({
  // The unique ID for this structured field
  id: z.string(),

  // The name to display for this structured field
  name: z.string(),

  // Styling applied to this field's content.
  styling: z.strictObject({
    // Whether to show the field's text italicized.
    italic: z.boolean(),

    // Whether to show the field's text bold.
    bold: z.boolean(),

    // The color to use for the field's text, in the format #RRGGBB.
    color: z.string().regex(/^#[0-9a-f]{6}$/i),
  }),

  // When this field is in a table section, how the table cell's contents should be aligned
  cellAlignment: z
    .strictObject({
      horizontal: z.enum(["left", "center", "right"]),
      vertical: z.enum(["top", "center", "bottom"]),
    })
    .optional(),
});

export const ReportSectionStructuredFieldSchema = z.discriminatedUnion("type", [
  ReportStructuredFieldTextSchema.merge(ReportStructuredFieldCommonSchema),
  ReportStructuredFieldMeasurementSchema.merge(ReportStructuredFieldCommonSchema),
  ReportStructuredFieldDropdownSchema.merge(ReportStructuredFieldCommonSchema),
]);

// The maximum number of columns allowed in a section
export const REPORT_TEMPLATE_SECTION_COLUMNS_MAX_COUNT = 14;

export const ReportSectionSchema = z.strictObject({
  // The unique ID for this section
  id: z.string(),

  // The name to display for this section on the report
  name: z.string(),

  // The type of this section:
  //
  // - `heading`: This section type displays the section's name as a heading. No other content is
  //              displayed.
  //
  // - `fields`: This section type displays columns of structured fields, where the behavior and
  //             appearance of each field can be configured individually.
  //
  // - `table`: Similar to a section of type `fields`, but the fields are rendered as a table with
  //            grid lines on the final report and a header row and column will also be rendered if
  //            there are static text fields in the first row and column.
  // - `rwma`: This section type displays the RWMA diagrams.
  type: ReportSectionTypeSchema,

  // Whether to show the free-text comment field for this section
  isCommentFieldVisible: z.boolean(),

  // The default value of the free-text comment field for this section
  commentFieldDefault: z.string(),

  // The columns of structured fields to show for this section
  structuredFieldColumns: z
    .array(z.array(ReportSectionStructuredFieldSchema))
    .min(1)
    .max(REPORT_TEMPLATE_SECTION_COLUMNS_MAX_COUNT),

  // The IDs of the report sentence groups that are recommended for this section
  sentenceGroupIds: z.array(z.string()),

  // Whether to compact the fields in this section on the final report. This involves removing
  // fields that have no value/data entered and then redistributing the fields amongst the columns
  // so that all columns have the same number of fields (or to within one field if the number of
  // fields doesn't divide evenly into the number of columns).
  isFieldCompactionEnabled: z.boolean(),

  // When this section is a table, whether to show grid lines, style static text fields bold with a
  // background color, and increase cell padding. When this is disabled, tables become purely a
  // layout construct and have no visual presence.
  isFormattingEnabled: z.boolean(),
});

const ReportMeasurementGroupItemSchema = z.strictObject({
  // The specific measurement to show. Note that custom measurements can't be put into
  // groups.
  name: z.nativeEnum(MeasurementName),

  // Whether the unindexed value of the measurement should be visible on the report by
  // default.
  isUnindexedVisible: z.boolean(),

  // Whether the indexed value of the measurement should be visible on the report by
  // default. Note that this is only relevant to measurements on which indexing is
  // actually offered and it must also be enabled on the StudyMeasurement.displayOption
  // for the report too.
  isIndexedVisible: z.boolean(),
});

export const MeasurementGroupsSchema = z.array(
  z.strictObject({
    // The display name of this measurement group
    name: z.string(),

    // The columns of measurements to show under this measurement group
    columns: z.array(z.array(ReportMeasurementGroupItemSchema)).min(3).max(3),
  })
);

export enum ReportComponent {
  Sections = "sections",
  MeasurementGroups = "measurementGroups",
  Signature = "signature",
}

export enum ReportFontSizeOption {
  Title = "title",
  Header = "header",
  Normal = "normal",
  Heading = "heading",
  Footer = "footer",
  Measurement = "measurement",
  Table = "table",
}

export enum ReportMarginOption {
  Top = "top",
  Bottom = "bottom",
  Horizontal = "horizontal",
}

export enum ReportSpacingOption {
  Section = "section",
  Heading = "heading",
  Field = "field",
  Table = "table",
}

// A single report sentence group in the sentence library for a report template
export const ReportSentenceGroupSchema = z.object({
  // The name of this sentence group
  name: z.string(),

  // The sentences that comprise this sentence group
  sentences: z.array(z.string()),
});

// The sentence groups for a report template, keyed by ID
export const ReportSentenceGroupsSchema = z.record(ReportSentenceGroupSchema);

/** The supported header text alignment options. */
export enum ReportTemplateHeaderTextAlignment {
  /** Left alignment of header text. */
  Left = "left",

  /** Center alignment of header text. */
  Center = "center",

  /** Right alignment of header text. */
  Right = "right",
}

export const ReportStructureSchema = z.strictObject({
  // The title to show in the header of the report in front of the patient's name
  title: z.string(),

  // The font to use for the report
  font: z.nativeEnum(ReportTemplateFont),

  // The font sizes to use on the report in em units
  fontSizes: z.strictObject({
    // The font size of the title of the report
    title: z.number(),

    // The font size of the header text in the top right of the report
    header: z.number(),

    // The font size of the footer text at the bottom of the report
    footer: z.number(),

    // The font size of most text on the report
    normal: z.number(),

    // The font size of headings on the report, e.g. section headings, measurement group headings
    heading: z.number(),

    // The font size of measurement names and values on the report
    measurement: z.number(),

    // The font size of text in tables on the report
    table: z.number(),
  }),

  // The page margins of the report in em units. These define the area of the page that the header
  // and footer must stay inside of, and where the body content will be laid out.
  margins: z.strictObject({
    top: z.number(),
    bottom: z.number(),
    horizontal: z.number(),
  }),

  // The spacing used when laying out report elements, in em units.
  spacing: z.strictObject({
    // The vertical spacing between sections
    section: z.number(),

    // The vertical spacing above headings, in addition to the vertical spacing between sections
    heading: z.number(),

    // The vertical spacing between fields in a section
    field: z.number(),

    // The vertical and horizontal spacing used in table cells
    table: z.number(),
  }),

  // The text to show on the right side of the report header
  headerText: z.string(),

  // The alignment of the text on the right side of the report header
  headerTextAlignment: z.nativeEnum(ReportTemplateHeaderTextAlignment),

  // The text to show in the footer of the report
  footerText: z.string(),

  // The image data URI for this report's logo, or an empty string if no logo is specified
  logoDataUri: z.string(),

  // The layout configuration to use for the report
  layout: z.strictObject({
    // Whether to show the left-hand pane containing information on the patient and the study
    isLeftPaneVisible: z.boolean(),

    // Whether to show the built-in indication field at the top of the list of sections.
    isIndicationFieldVisible: z.boolean(),

    // Whether to show the built-in medical history field at the top of the list of sections.
    isMedicalHistoryFieldVisible: z.boolean(),

    // Controls the ordering and inclusion/exclusion of the primary components of the report
    components: z
      .strictObject({ name: z.nativeEnum(ReportComponent), isVisible: z.boolean() })
      .array(),
  }),

  // The sections of the report
  sections: z.array(ReportSectionSchema),

  // The measurement groups to show on the report. Measurements are shown in columns, and any
  // measurements not in a group are put into an "Other Measurements" group.
  measurementGroups: MeasurementGroupsSchema,

  // The sentence groups for this report template, keyed by ID. This is set automatically with the
  // relevant content from the global sentence library when the report template is published.
  sentenceGroups: ReportSentenceGroupsSchema,
});

export type ReportSectionStructuredFieldType = z.infer<
  typeof ReportSectionStructuredFieldTypeSchema
>;
export type ReportSectionDropdownFieldStructure = z.infer<
  typeof ReportStructuredFieldDropdownSchema
>;
export type ReportSectionMeasurementFieldStructure = z.infer<
  typeof ReportStructuredFieldMeasurementFieldSchema
>;
export type ReportSectionCommonFieldStructure = z.infer<typeof ReportStructuredFieldCommonSchema>;
export type ReportSectionStructuredField = z.infer<typeof ReportSectionStructuredFieldSchema>;
export type ReportSectionType = z.infer<typeof ReportSectionTypeSchema>;
export type ReportSectionStructure = z.infer<typeof ReportSectionSchema>;
export type ReportMeasurementGroupItem = z.infer<typeof ReportMeasurementGroupItemSchema>;
export type ReportSentenceGroups = z.infer<typeof ReportSentenceGroupsSchema>;
export type ReportStructure = z.infer<typeof ReportStructureSchema>;

/**
 * Returns whether the given section should be shown as a horizontal divider.
 */
export function isSectionHorizontalDivider(section: ReportSectionStructure): boolean {
  return section.type === "heading" && section.name === "-";
}

/** Returns the default page margins, in em. */
export function getDefaultMargins(): { top: number; bottom: number; horizontal: number } {
  return { top: 1, bottom: 2, horizontal: 1.5 };
}

/** Returns the default font sizes, in em. */
export function getDefaultFontSizes(): {
  title: number;
  header: number;
  footer: number;
  normal: number;
  heading: number;
  measurement: number;
  table: number;
} {
  return {
    title: 2.2,
    header: 0.9,
    footer: 0.9,
    normal: 1.1,
    heading: 1.2,
    measurement: 1,
    table: 1.1,
  };
}

/** Returns the default spacings, in em */
export function getDefaultSpacing(): {
  section: number;
  heading: number;
  field: number;
  table: number;
} {
  return {
    section: 0.8,
    heading: 0,
    field: 0.2,
    table: 0.4,
  };
}

/**
 * Returns the default footer text. This can include dynamic content, which are specified using
 * double curly braces.
 */
export function getDefaultFooterText(): string {
  return "{{ patient.name }} – {{ patient.id }} – {{ study.date }}";
}

/** Returns the default report component ordering. */
export function getDefaultComponents(): { name: ReportComponent; isVisible: boolean }[] {
  return [
    { name: ReportComponent.Sections, isVisible: true },
    { name: ReportComponent.MeasurementGroups, isVisible: true },
    { name: ReportComponent.Signature, isVisible: true },
  ];
}

/** Returns the default styling for fields.  */
export function getDefaultFieldStyling(): { bold: boolean; italic: boolean; color: string } {
  return { bold: false, italic: false, color: "#000000" };
}

/** Returns a valid report structure for an empty report with no details or sections configured. */
export function getEmptyReportStructure(title = ""): ReportStructure {
  return {
    title,
    margins: getDefaultMargins(),
    spacing: getDefaultSpacing(),
    font: ReportTemplateFont.SansSerif,
    fontSizes: getDefaultFontSizes(),
    headerText: "",
    headerTextAlignment: ReportTemplateHeaderTextAlignment.Right,
    footerText: getDefaultFooterText(),
    logoDataUri: "",
    sections: [],
    sentenceGroups: {},
    layout: {
      isLeftPaneVisible: false,
      isIndicationFieldVisible: false,
      isMedicalHistoryFieldVisible: false,
      components: getDefaultComponents(),
    },
    measurementGroups: [],
  };
}

/**
 * Checks whether the given value is a valid report structure. Raises exceptions with relevant
 * details if some part of the passed value is invalid. Returns a parsed/typed version on success.
 */
export function validateReportStructure(structureValue: unknown): ReportStructure {
  // The structure must match the base report structure schema
  const structure = ReportStructureSchema.parse(structureValue);

  const allSections = [...structure.sections];

  for (const section of allSections) {
    // Sections must all have unique IDs
    if (allSections.some((s) => s !== section && s.id === section.id)) {
      throw new ReportStructureInvalidException("Report section IDs must be unique");
    }

    // Sections that are just headings must not have any structured fields
    if (section.type === "heading" && section.structuredFieldColumns.flat().length !== 0) {
      throw new ReportStructureInvalidException(
        "Report section heading must not have any structured field columns"
      );
    }

    const fields = section.structuredFieldColumns.flat();

    // Fields must all have unique IDs
    if (fields.length !== new Set(fields.map((field) => field.id)).size) {
      throw new ReportStructureInvalidException("Report fields must have unique IDs");
    }

    for (const field of fields) {
      // Require fields in table sections to specify alignment
      if (
        (section.type === "table" && field.cellAlignment === undefined) ||
        (section.type !== "table" && field.cellAlignment !== undefined)
      ) {
        throw new ReportStructureInvalidException(
          "Fields in tables must have a specified cell alignment"
        );
      }

      if (field.type === "dropdown") {
        // Dropdown fields must have at least one option
        if (!field.isMultiSelect && field.options.length === 0) {
          throw new ReportStructureInvalidException(
            "Dropdown fields must have at least one option"
          );
        }

        // If a dropdown field has a blank option, it must be first in the list
        const indexOfBlankOption = field.options.findIndex((option) => option.id === "");
        if (indexOfBlankOption !== -1 && indexOfBlankOption !== 0) {
          throw new ReportStructureInvalidException(
            "Dropdown fields must have the blank option first when it is present"
          );
        }

        // If a dropdown field has a "custom" option, it must be last in the list
        const indexOfCustomOption = field.options.findIndex((option) => option.id === "custom");
        if (indexOfCustomOption !== -1 && indexOfCustomOption !== field.options.length - 1) {
          throw new ReportStructureInvalidException(
            "Dropdown fields must have the 'custom' option last, when present"
          );
        }
      }
    }

    // Field compaction should never be enabled on tables as by definition they should keep their
    // shape
    if (section.type === "table" && section.isFieldCompactionEnabled) {
      throw new ReportStructureInvalidException(
        "Report field compaction must always be turned off for tables"
      );
    }
  }

  return structure;
}

/**
 * Prepares a report structure for publishing by converting UUIDs to numbers in order to save space.
 */
export function prepareReportStructureForPublication(structure: ReportStructure): void {
  // Minify each section
  for (let i = 0; i < structure.sections.length; i++) {
    structure.sections[i].id = i.toString();
    if (structure.sections[i].type === "rwma") {
      continue;
    }

    const fields = structure.sections[i].structuredFieldColumns.flat();
    for (let j = 0; j < fields.length; j++) {
      const field = fields[j];
      field.id = j.toString();

      if (field.type === "dropdown") {
        const options = field.options;
        for (let k = 0; k < options.length; k++) {
          if (options[k].id !== "" && options[k].id !== "custom") {
            options[k].id = k.toString();
          }
        }
      }
    }
  }
}

/**
 * Returns all of the measurements that have been placed into measurement groups in the given report
 * structure, keyed by the measurement name for efficient lookups.
 */
export function getMeasurementGroupItems(
  structure: ReportStructure
): Map<MeasurementName, ReportMeasurementGroupItem> {
  const result = new Map<MeasurementName, ReportMeasurementGroupItem>();

  for (const measurementGroup of structure.measurementGroups) {
    for (const column of measurementGroup.columns) {
      for (const item of column) {
        result.set(item.name, item);
      }
    }
  }

  return result;
}
