import { AuthMethod } from "./auth-method";
import type { AppSession } from "./session.store";

export const TOTP_REQUIRED_STRING = "totp_required";
export const TENANT_SELECTION_REQUIRED_STRING = "tenant_selection_required";
export const COOKIE_NAME = "sid";

/** Removes the session from the request and soft delete it from the database */
export async function destroySessionPromise(session: AppSession): Promise<void> {
  return new Promise((resolve, reject) => {
    session.destroy((err: Error | null) => {
      if (err) {
        reject(err);
      }
      resolve();
    });
  });
}

// User API Key's are deprecated and should be replaced with API Keys
export function isAuthMethodApiKey(authMethod: AuthMethod): boolean {
  return authMethod === AuthMethod.UserApiKey || authMethod === AuthMethod.ApiKey;
}
