import type { Ref } from "vue";
import { ref, shallowReactive } from "vue";
import { clamp, positiveModulo } from "../../../backend/src/shared/math-utils";
import { isMeasuring } from "../measurements/measurement-tool-state";
import { useStudyViewImageData } from "../state/stores/study-image.store";
import { ColorMap } from "../utils/color-map";
import { isPlaywrightTest } from "../utils/e2e-test-utils";
import type { Study, StudyClip } from "../utils/study-data";

/**
 * The maximum number of clips that can be selected at once. This is used to size arrays that track
 * data tied to each selected clip.
 */
export const MAX_SELECTED_CLIPS = 16;

/**
 * Describes an individual heartbeat within a study clip, calculated from the clip's R-wave times.
 * There can be any number of heartbeats on a study clip. The times are stored as a whole number
 * of microseconds.
 */
export interface Heartbeat {
  readonly firstFrame: number;
  readonly lastFrame: number;
  readonly startTime: number;
  readonly endTime: number;
  readonly length: number;
}

/**
 * Holds the state required for the playback and rendering of a study clip within a ClipViewer
 * instance.
 */
export interface ClipModel {
  /** The study clip this clip model is for. */
  readonly clip?: StudyClip;

  /**
   * The images for each frame of the clip that are used during playback. Note that the frames for
   * clips are loaded asynchronsouly into this array, which is why it can contain undefined values.
   * An undefined value means that the image for that frame is not yet loaded.
   */
  readonly loadedFrames: (HTMLImageElement | undefined)[];

  /**
   * The heartbeats for the clip, calculated from the clip's R-wave times. These are shown in the
   * clip viewer so that individual heartbeats can be selected for playback instead of always
   * playing the entire clip. This is useful for comparing heartbeats across different clips.
   */
  readonly heartbeats: Heartbeat[];

  /** The duration of the entire clip, in microseconds. */
  readonly clipDuration: number;

  /** The duration of a single frame in this clip, in microseconds. */
  readonly frameDuration: number;

  /** The color map used for rendering the clip. */
  colorMap: Ref<ColorMap>;

  /** Whether the clip is currently playing. */
  isPlaying: Ref<boolean>;

  /** Whether the clip is currently being scrubbed by the user. */
  isScrubbing: Ref<boolean>;

  /**
   * Whether the clip is currently being displayed in CT mode.
   */
  isInCTMode: Ref<boolean>;

  /**
   * When set, only the measurement value with this ID will be shown on the clip viewer. This is
   * used when stepping through measurements for a study.
   */
  soloMeasurementValueId: Ref<string | undefined>;

  /** Returns the current playback timestamp of this clip, in microseconds. */
  getCurrentTime: () => number;

  /**
   * Sets the current playback timestamp of this clip, in microseconds. The given value will be
   * clamped to the duration of the clip, and also to the start and end times of any selected
   * heartbeat.
   */
  setCurrentTime: (time: number) => void;

  /**
   * Steps this clip model's current time by the given number of microseconds, wrapping inside the
   * clip or selected heartbeat as appropriate. The time specified can be positive or negative in
   * order to move forwards or backwards.
   */
  stepCurrentTime: (delta: number) => void;

  /** The index of the currently selected heartbeat, if any. */
  getSelectedHeartbeatIndex: () => number | undefined;

  /** Sets or clears the currently selected heartbeat. */
  setSelectedHeartbeatIndex: (newBeatIndex: number | undefined) => void;

  /** Returns the currently selected heartbeat, if any. */
  getSelectedHeartbeat: () => Heartbeat | undefined;

  /**
   * Returns the index of the current frame, based on the `currentTime` value. Note that this is the
   * frame that is intended for display, but it won't be visible if its image data isn't loaded.
   */
  getCurrentFrame: () => number;

  /**
   * Directly sets the current frame and pauses playback. If this value falls outside the bounds of
   * the currently selected heartbeat then the heartbeat will be deselected.
   */
  setCurrentFrame: (frame: number) => void;

  /**
   * Steps the current frame by the given change in frames, wrapping inside the clip or selected
   * heartbeat as appropriate.
   */
  onStepFrame: (delta: number) => void;
}

/**
 * Creates a ClipModel for managing loading and playback of the given study clip.
 *
 * Note that this returns a ShallowReactive because reactivity is handled as required by the
 * individual properties of ClipModel, which also avoids issues with unwanted deep unwrapping when
 * passing a ClipModel as a prop.
 */
export function createClipModel(study: Study, clip: StudyClip): ClipModel {
  const studyViewImageData = useStudyViewImageData();

  const loadedFrames = studyViewImageData.getClipFrames(study, clip);
  const heartbeats = getClipHeartbeats(clip);

  const frameDuration = Math.floor(1000000 / (clip.fps ?? 1));
  const clipDuration = (clip.frameCount ?? 0) * frameDuration;

  const selectedHeartbeatIndex = ref<number | undefined>(undefined);

  // In the tests, don't start clips playing by default as this can lead to unpredictable behavior
  // due to timing differences
  const isPlaying = ref(!isPlaywrightTest() && !isMeasuring.value);

  // Don't autoplay CT or MR clips
  if (clip.modality === "CT" || clip.modality === "MR") {
    isPlaying.value = false;
  }

  const isScrubbing = ref(false);
  const currentTime = ref(0);
  const colorMap = ref(ColorMap.Grey);

  function setCurrentTime(time: number): void {
    currentTime.value = clamp(Math.floor(time), 0, clipDuration - 1);

    // If a heartbeat is selected then clamp the new time within the bounds of the beat
    if (selectedHeartbeatIndex.value !== undefined) {
      const heartbeat = heartbeats[selectedHeartbeatIndex.value];
      currentTime.value = clamp(currentTime.value, heartbeat.startTime, heartbeat.endTime);
    }
  }

  function stepCurrentTime(delta: number): void {
    if (clip.frameCount === null || clip.frameCount < 1) {
      return;
    }

    let duration = clipDuration;
    let offset = 0;

    // If a heartbeat is selected then wrap the time value inside that heartbeat's from and end
    // times rather than the entire clip
    if (selectedHeartbeatIndex.value !== undefined) {
      duration = heartbeats[selectedHeartbeatIndex.value].length;
      offset = heartbeats[selectedHeartbeatIndex.value].startTime;
    }

    let result = currentTime.value - offset + delta;

    // Wrap result inside the valid range
    result = positiveModulo(result, duration);

    currentTime.value = result + offset;
  }

  function getCurrentFrame(): number {
    return Math.floor(currentTime.value / frameDuration);
  }

  function setCurrentFrame(frame: number): void {
    if (clip.frameCount === null) {
      return;
    }

    currentTime.value = (clamp(frame, 0, clip.frameCount - 1) + 0.5) * frameDuration;
    isPlaying.value = false;

    // Clear the currently selected heartbeat if the new current time is outside of its bounds
    if (selectedHeartbeatIndex.value !== undefined) {
      const heartbeat = heartbeats[selectedHeartbeatIndex.value];
      if (currentTime.value < heartbeat.startTime || currentTime.value > heartbeat.endTime) {
        selectedHeartbeatIndex.value = undefined;
      }
    }
  }

  function onStepFrame(delta: number): void {
    isPlaying.value = false;
    stepCurrentTime(delta * frameDuration);
  }

  function getSelectedHeartbeatIndex(): number | undefined {
    return selectedHeartbeatIndex.value;
  }

  function setSelectedHeartbeatIndex(newBeatIndex: number | undefined): void {
    if (newBeatIndex === undefined || heartbeats.length === 0) {
      selectedHeartbeatIndex.value = undefined;
      return;
    }

    selectedHeartbeatIndex.value = clamp(newBeatIndex, 0, heartbeats.length - 1);

    // Clamp the current time inside the newly selected heartbeat
    const heartbeat = heartbeats[selectedHeartbeatIndex.value];
    currentTime.value = clamp(currentTime.value, heartbeat.startTime, heartbeat.endTime);
  }

  function getSelectedHeartbeat(): Heartbeat | undefined {
    if (selectedHeartbeatIndex.value === undefined) {
      return undefined;
    }

    return heartbeats[selectedHeartbeatIndex.value];
  }

  return shallowReactive({
    clip,
    loadedFrames,
    heartbeats,
    clipDuration,
    frameDuration,
    getCurrentTime: () => currentTime.value,
    setCurrentTime,
    stepCurrentTime,
    isPlaying,
    isScrubbing,
    colorMap,
    isInCTMode: ref(false),
    soloMeasurementValueId: ref(),
    getCurrentFrame,
    setCurrentFrame,
    onStepFrame,
    getSelectedHeartbeatIndex,
    setSelectedHeartbeatIndex,
    getSelectedHeartbeat,
  });
}

function getClipHeartbeats(clip: StudyClip | undefined): Heartbeat[] {
  if (clip === undefined || clip.fps === null || clip.frameCount === null) {
    return [];
  }

  const beats = [];

  for (let i = 0; i < clip.rWaveTimes.length - 1; i++) {
    // Convert R-wave times into frame offsets, and then reconstruct the start and end times for the
    // heartbeat using these frame offsets in order to ensure there is exact alignment to frame
    // boundaries
    const firstFrame = Math.min(
      Math.floor((clip.rWaveTimes[i] / 1000) * clip.fps),
      clip.frameCount - 1
    );
    const lastFrame =
      Math.min(Math.floor((clip.rWaveTimes[i + 1] / 1000) * clip.fps), clip.frameCount) - 1;
    const frameDuration = Math.floor(1000000 / clip.fps);

    beats.push({
      firstFrame,
      startTime: firstFrame * frameDuration,
      lastFrame,
      endTime: lastFrame * frameDuration + frameDuration - 1,
      length: (lastFrame - firstFrame + 1) * frameDuration,
    });
  }

  return beats;
}
