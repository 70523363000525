<template>
  <Popper
    ref="popper"
    class="upload-popper"
    placement="bottom-end"
    :offset-distance="2"
    :disable-on-click-outside="disableOnClickOutside"
    @open="isDropdownOpen = true"
    @close="isDropdownOpen = false"
  >
    <Modal
      v-if="isRetryAllFailedModalVisible"
      title="There are uploads with retryable errors"
      @header-button-click="onModalClose"
    >
      <p class="modal-text">Clearing this list will also clear uploads that failed with errors.</p>

      <div class="modal-button-container">
        <button @click="onModalClose">Cancel</button>
        <button
          class="clear-all-button"
          @click="
            () => {
              clearNotifications();
              onModalClose();
            }
          "
        >
          Clear All
        </button>
      </div>
    </Modal>
    <button
      data-testid="file-upload-button"
      :data-test-completed-job-count="
        uploadJobs.filter(
          (job) => job.state === UploadJobState.Completed || job.state === UploadJobState.Info
        ).length
      "
      class="upload-button"
      :class="{ active: isDropdownOpen, open: isDropdownOpen }"
    >
      <template v-if="hasFilesWithState(UploadJobState.InProgress)"> Uploading… </template>
      <template v-else-if="hasFilesWithState(UploadJobState.Error)">
        Upload Complete (With Errors)
      </template>
      <template v-else> Upload Complete </template>

      <LoadingIndicator v-if="hasFilesWithState(UploadJobState.InProgress)" />

      <FontAwesomeIcon
        v-if="hasFilesWithState(UploadJobState.Error)"
        icon="triangle-exclamation"
        class="error-icon"
      />
      <template v-else-if="!hasFilesWithState(UploadJobState.InProgress)">
        <FontAwesomeIcon
          v-if="hasFilesWithState(UploadJobState.Info)"
          icon="circle-info"
          class="info-icon"
        />
        <FontAwesomeIcon v-else icon="check" class="check-icon" />
      </template>
    </button>

    <template #content>
      <div class="upload-popper-content">
        <div class="upload-list">
          <UploadJobWidget v-for="job in sortedUploadJobs" :key="job.id" :job="job" />
        </div>

        <div class="footer">
          <b>{{ footerText }} </b>
          <div class="footer-button-container">
            <button
              class="footer-button"
              data-testid="retry-all-failed-uploads-button"
              :disabled="isRetryAllFailedButtonDisabled"
              @click="retryAllFailedUploads"
            >
              Retry all failed
            </button>

            <button
              :disabled="
                !hasFilesWithState(UploadJobState.Completed) &&
                !hasFilesWithState(UploadJobState.Error) &&
                !hasFilesWithState(UploadJobState.Info)
              "
              class="footer-button"
              @click.stop.prevent="onClear"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { UploadJobState, uploadJobStore, type UploadJob } from "../state/stores/upload-job.store";
import LoadingIndicator from "./LoadingIndicator.vue";
import UploadJobWidget from "./UploadJobWidget.vue";

const { uploadJobs } = storeToRefs(uploadJobStore());
const { clearNotifications, hasFilesWithState, retryAllFailedUploads, hasRetryableErrors } =
  uploadJobStore();

const isRetryAllFailedModalVisible = ref(false);
const sortedUploadJobs = computed(() =>
  [...uploadJobs.value].sort((a, b) => {
    const order = [
      UploadJobState.Error,
      UploadJobState.Info,
      UploadJobState.InProgress,
      UploadJobState.Completed,
    ];

    function getSortKey(job: UploadJob): string {
      return `${job.isZip === true ? 0 : 1}${order.indexOf(job.state)}${job.title}`;
    }

    return getSortKey(a).localeCompare(getSortKey(b));
  })
);

const popper = ref<{ close: () => void } | null>(null);
const disableOnClickOutside = ref(false);

const isDropdownOpen = ref(false);

const isRetryAllFailedButtonDisabled = computed(() => {
  if (hasFilesWithState(UploadJobState.InProgress)) {
    return true;
  }

  return !hasRetryableErrors();
});

const footerText = computed(() => {
  let dicomUploadCount = 0;
  let dicomUploadInProgressCount = 0;
  let dicomUploadErrorCount = 0;
  let dicomDuplicateCount = 0;

  for (const job of uploadJobs.value) {
    if (job.isZip === true) {
      continue;
    }

    dicomUploadCount++;

    if (job.state === UploadJobState.Pending || job.state === UploadJobState.InProgress) {
      dicomUploadInProgressCount++;
    } else if (job.state === UploadJobState.Error) {
      dicomUploadErrorCount++;
    } else if (job.state === UploadJobState.Info) {
      dicomDuplicateCount++;
    }
  }

  if (dicomUploadCount === 0) {
    return "";
  }

  let result = `${dicomUploadCount} upload${dicomUploadCount > 1 ? "s" : ""}`;

  if (dicomUploadErrorCount !== 0) {
    result += `, ${dicomUploadErrorCount} error${dicomUploadErrorCount > 1 ? "s" : ""}`;
  }

  if (dicomDuplicateCount !== 0) {
    result += `, ${dicomDuplicateCount} duplicate${dicomDuplicateCount > 1 ? "s" : ""}`;
  }

  if (dicomUploadInProgressCount !== 0) {
    result += `, ${dicomUploadInProgressCount} in progress…`;
  }

  return result;
});

function onModalClose(): void {
  disableOnClickOutside.value = false;
  isRetryAllFailedModalVisible.value = false;
}

function onClear(): void {
  if (hasRetryableErrors()) {
    disableOnClickOutside.value = true;
    isDropdownOpen.value = false;
    isRetryAllFailedModalVisible.value = true;
  } else {
    clearNotifications();
  }
}
</script>

<style scoped lang="scss">
:deep(.upload-popper) {
  background-color: var(--bg-color-4);
  border-radius: var(--border-radius) 0 var(--border-radius) var(--border-radius);
}

.upload-button {
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .check-icon {
    color: var(--confirm-color-2);
  }

  .error-icon {
    color: red;
  }

  .info-icon {
    color: #fedf3e;
  }
}

.upload-list {
  max-height: 50vh;
  overflow-y: auto;
  width: 500px;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.upload-popper-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-button-container {
  display: flex;
  gap: 4px;
}

.footer-button {
  align-self: flex-end;
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  background-color: var(--bg-color-2);

  &:not(:disabled) {
    &:not(:active) {
      &:hover,
      &.active {
        background-color: var(--bg-color-2);
      }
    }
  }
}

button {
  &.clear-all-button {
    background-color: var(--notification-error-icon-bg-color);
    &:hover {
      background-color: var(--notification-error-icon-bg-color);
      opacity: 0.8;
    }
  }
}

.modal-text {
  text-align: left;
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
</style>
