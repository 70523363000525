<template>
  <div class="organization-view">
    <div class="header">
      <h1>Configure Organization</h1>
      <div v-if="isSaving" class="saving-indicator">
        <span>Saving...</span>
      </div>
    </div>

    <div v-if="organization" class="organization-form">
      <div class="layout-container">
        <!-- Organization Details Section -->
        <div class="form-section details-section">
          <h2>Details</h2>
          <div class="form-group">
            <label for="orgName">Organization Name</label>
            <input
              id="orgName"
              v-model="organization.name"
              class="form-input"
              @input="isDirty = true"
            />
          </div>

          <div class="form-grid">
            <div class="form-group">
              <label for="ssoEnforced">SSO Enforced</label>
              <div class="toggle-wrapper">
                <label class="toggle">
                  <input
                    id="ssoEnforced"
                    v-model="organization.isSsoEnforced"
                    type="checkbox"
                    @change="isDirty = true"
                  />
                  <span class="slider"></span>
                </label>
                <span>{{ organization.isSsoEnforced ? "Enabled" : "Disabled" }}</span>
              </div>
            </div>

            <div v-if="organization.isSsoEnforced" class="form-group">
              <label for="ssoStrategy">SSO Strategy</label>
              <select
                id="ssoStrategy"
                v-model="organization.ssoStrategy"
                class="form-select"
                @change="isDirty = true"
              >
                <option :value="null">None</option>
                <option value="saml">SAML</option>
                <option value="oidc">OIDC (Coming Soon)</option>
              </select>
            </div>
          </div>

          <div v-if="organization.isSsoEnforced" class="form-group">
            <label>Allowed Domains</label>
            <div class="domain-list">
              <div
                v-for="(domain, index) in organization.allowedDomains"
                :key="index"
                class="domain-item"
              >
                <input
                  v-model="organization.allowedDomains[index]"
                  class="form-input"
                  @input="isDirty = true"
                />
                <button class="remove-button" @click="removeAllowedDomain(index)">×</button>
              </div>
              <div class="domain-add">
                <input
                  v-model="newDomain"
                  class="form-input"
                  placeholder="Add new domain"
                  @keyup.enter="addAllowedDomain"
                />
                <button class="add-button" @click="addAllowedDomain">+</button>
              </div>
            </div>
          </div>

          <!-- SAML Configuration Section -->
          <div
            v-if="organization.isSsoEnforced && organization.ssoStrategy === 'saml'"
            class="saml-config-section"
          >
            <h3>SAML Configuration</h3>

            <div class="form-group">
              <label for="idpEntityId">Identity Provider Entity ID</label>
              <input
                id="idpEntityId"
                v-model="samlConfig.idpEntityId"
                class="form-input"
                @input="isDirty = true"
              />
            </div>

            <div class="form-group">
              <label for="ssoUrl">Single Sign-On URL</label>
              <input
                id="ssoUrl"
                v-model="samlConfig.ssoUrl"
                class="form-input"
                @input="isDirty = true"
              />
            </div>

            <div class="form-group">
              <label for="sloUrl">Single Logout URL (Optional)</label>
              <input
                id="sloUrl"
                v-model="samlConfig.sloUrl"
                class="form-input"
                @input="isDirty = true"
              />
            </div>

            <div class="form-group">
              <label for="x509cert">X.509 Certificate</label>
              <textarea
                id="x509cert"
                v-model="samlConfig.x509cert"
                class="form-textarea"
                rows="5"
                @input="isDirty = true"
              ></textarea>
              <span class="helper-text">Paste the identity provider's certificate here</span>
            </div>

            <div class="form-grid">
              <div class="form-group">
                <label for="emailAttribute">Email Attribute</label>
                <input
                  id="emailAttribute"
                  v-model="samlConfig.emailAttribute"
                  class="form-input"
                  placeholder="email"
                  @input="isDirty = true"
                />
              </div>

              <div class="form-group">
                <label for="firstNameAttribute">First Name Attribute (Optional)</label>
                <input
                  id="firstNameAttribute"
                  v-model="samlConfig.firstNameAttribute"
                  class="form-input"
                  placeholder="givenName"
                  @input="isDirty = true"
                />
              </div>

              <div class="form-group">
                <label for="lastNameAttribute">Last Name Attribute (Optional)</label>
                <input
                  id="lastNameAttribute"
                  v-model="samlConfig.lastNameAttribute"
                  class="form-input"
                  placeholder="familyName"
                  @input="isDirty = true"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="spEntityId">Service Provider Entity ID</label>
              <input
                id="spEntityId"
                v-model="samlConfig.spEntityId"
                class="form-input"
                placeholder="pulse-saml"
                @input="isDirty = true"
              />
            </div>

            <div class="form-group">
              <label for="isActive">SAML Configuration Active</label>
              <div class="toggle-wrapper">
                <label class="toggle">
                  <input
                    id="isActive"
                    v-model="samlConfig.isActive"
                    type="checkbox"
                    @change="isDirty = true"
                  />
                  <span class="slider"></span>
                </label>
                <span>{{ samlConfig.isActive ? "Active" : "Inactive" }}</span>
              </div>
            </div>
          </div>

          <div class="actions">
            <button class="save-button" :disabled="!isDirty" @click="saveOrganization">
              Save Changes
            </button>
          </div>
        </div>

        <!-- Tenant Management Section -->
        <div class="form-section tenants-section">
          <h2>Tenant Management</h2>
          <p>Drag and drop tenants between lists to assign them to this organization</p>

          <div class="tenant-management">
            <div class="tenant-lists">
              <div class="tenant-list">
                <h3>Available Tenants</h3>
                <div class="filter-input-container">
                  <input
                    v-model="availableTenantFilter"
                    class="filter-input"
                    placeholder="Filter available tenants..."
                    @input="updateAvailableTenantsList"
                  />
                  <span
                    v-if="availableTenantFilter"
                    class="clear-filter"
                    @click="clearAvailableFilter"
                  >
                    ×
                  </span>
                </div>
                <div ref="availableTenantsList" class="sortable-list">
                  <div
                    v-for="tenant in filteredAvailableTenants"
                    :key="tenant.id"
                    class="tenant-item"
                    :data-id="tenant.id"
                  >
                    {{ tenant.name }}
                  </div>
                  <div v-if="filteredAvailableTenants.length === 0" class="empty-list">
                    {{ availableTenantFilter ? "No matching tenants" : "No available tenants" }}
                  </div>
                </div>
              </div>

              <div class="tenant-list">
                <h3>Assigned Tenants</h3>
                <div class="filter-input-container">
                  <input
                    v-model="assignedTenantFilter"
                    class="filter-input"
                    placeholder="Filter assigned tenants..."
                    @input="updateAssignedTenantsList"
                  />
                  <span
                    v-if="assignedTenantFilter"
                    class="clear-filter"
                    @click="clearAssignedFilter"
                  >
                    ×
                  </span>
                </div>
                <div ref="assignedTenantsList" class="sortable-list">
                  <div
                    v-for="tenant in filteredAssignedTenants"
                    :key="tenant.id"
                    class="tenant-item"
                    :data-id="tenant.id"
                  >
                    {{ tenant.name }}
                  </div>
                  <div v-if="filteredAssignedTenants.length === 0" class="empty-list">
                    {{ assignedTenantFilter ? "No matching tenants" : "No assigned tenants" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="actions">
              <button class="save-button" :disabled="!isTenantsDirty" @click="saveTenants">
                Save Tenant Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="loading">
      <div class="spinner"></div>
      <p>Loading organization data...</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TenantGetManyResponseDto } from "@/../../backend/src/tenants/global-admin/dto/tenant-get-many.dto";
import { ThemeProperty } from "@/themes/theme";
import { charcoalTheme } from "@/themes/theme-charcoal";
import { addNotification } from "@/utils/notifications";
import { getRequestErrorMessage } from "@/utils/request-helpers";
import axios, { AxiosResponse } from "axios";
import Sortable from "sortablejs";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import {
  OrganizationGetOneResponseDto,
  SamlConfigDto,
} from "../../../backend/src/organizations/dto/organization-get.dto";
import { SSOStrategy } from "../../../backend/src/organizations/sso-strategy";

// Define interfaces for our data
interface TenantBase {
  id: string;
  name: string;
}
// Route and organization data
const route = useRoute();
const organizationId = computed<string>(() => route.params.id as string);
const organization = ref<OrganizationGetOneResponseDto | null>(null);

const isDirty = ref<boolean>(false);
const isTenantsDirty = ref<boolean>(false);
const isSaving = ref<boolean>(false);
const newDomain = ref<string>("");

// SAML Configuration with default values
const samlConfig = ref<SamlConfigDto>({
  idpEntityId: "",
  ssoUrl: "",
  sloUrl: null,
  x509cert: "",
  emailAttribute: "email",
  firstNameAttribute: "givenName",
  lastNameAttribute: "familyName",
  spEntityId: "pulse-saml",
  isActive: true,
});

// Get theme colors
const themeColors = charcoalTheme.values;

// Tenants data
const allTenants = ref<TenantGetManyResponseDto>([]);

// Track assigned tenants separately to avoid type issues
const assignedTenants = ref<TenantBase[]>([]);

// Computed list of available tenants (not yet assigned to organization)
const availableTenants = computed(() => {
  if (assignedTenants.value.length === 0) return allTenants.value;

  // Create a set of assigned tenant IDs
  const assignedIds = new Set(assignedTenants.value.map((t) => t.id));
  // Return tenants that aren't in the assigned set
  return allTenants.value.filter((t) => !assignedIds.has(t.id));
});

// Filter for available tenants
const availableTenantFilter = ref<string>("");
// Filter for assigned tenants
const assignedTenantFilter = ref<string>("");

// Filtered available tenants list based on search input
const filteredAvailableTenants = computed(() => {
  if (!availableTenantFilter.value.trim()) {
    return availableTenants.value;
  }

  const searchTerm = availableTenantFilter.value.toLowerCase().trim();

  return availableTenants.value.filter((tenant) => tenant.name.toLowerCase().includes(searchTerm));
});

// Filtered assigned tenants list based on search input
const filteredAssignedTenants = computed(() => {
  if (!assignedTenantFilter.value.trim()) {
    return assignedTenants.value;
  }

  const searchTerm = assignedTenantFilter.value.toLowerCase().trim();

  return assignedTenants.value.filter((tenant) => tenant.name.toLowerCase().includes(searchTerm));
});

// Sortable instances - prefixed with _ to avoid unused variable warnings
let _availableSortable: Sortable | null = null;
let _assignedSortable: Sortable | null = null;

// DOM refs
const availableTenantsList = ref<HTMLElement | null>(null);
const assignedTenantsList = ref<HTMLElement | null>(null);

// Fetch organization data
onMounted(async () => {
  await fetchOrganization();
  await fetchAllTenants();

  initSortable();
});

const sortableOptions = {
  group: "tenants",
  animation: 150,
  onEnd: handleTenantMove,
};

// Initialize SortableJS
function initSortable() {
  if (!availableTenantsList.value || !assignedTenantsList.value) return;

  // Available tenants list
  _availableSortable = Sortable.create(availableTenantsList.value, sortableOptions);
  _assignedSortable = Sortable.create(assignedTenantsList.value, sortableOptions);
}

// Fetch organization data
async function fetchOrganization() {
  let response: AxiosResponse<OrganizationGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<OrganizationGetOneResponseDto>(
      `/api/organizations/${organizationId.value}`
    );
  } catch {
    addNotification({ type: "error", message: "Failed loading organization" });
    return;
  }

  // Set organization directly from the DTO response
  organization.value = response.data;

  // Initialize SAML configuration
  resetSamlConfig();

  // If the organization has an existing SAML config, populate the form with its values
  if (organization.value.samlConfig !== null) {
    const orgSamlConfig = organization.value.samlConfig as SamlConfigDto;

    samlConfig.value = {
      idpEntityId: orgSamlConfig.idpEntityId || "",
      ssoUrl: orgSamlConfig.ssoUrl || "",
      sloUrl: orgSamlConfig.sloUrl,
      x509cert: orgSamlConfig.x509cert || "",
      emailAttribute: orgSamlConfig.emailAttribute || "email",
      firstNameAttribute: orgSamlConfig.firstNameAttribute,
      lastNameAttribute: orgSamlConfig.lastNameAttribute,
      spEntityId: orgSamlConfig.spEntityId || "pulse-saml",
      isActive: orgSamlConfig.isActive,
    };

    organization.value.samlConfig = orgSamlConfig;
  }

  // Initialize assigned tenants - we can reuse the tenants from the organization
  if (Array.isArray(organization.value.tenants)) {
    assignedTenants.value = organization.value.tenants;
  }
}

// Reset SAML configuration to default values
function resetSamlConfig() {
  samlConfig.value = {
    idpEntityId: "",
    ssoUrl: "",
    sloUrl: null,
    x509cert: "",
    emailAttribute: "email",
    firstNameAttribute: "givenName",
    lastNameAttribute: "familyName",
    spEntityId: "pulse-saml",
    isActive: true,
  };
}

// Fetch all tenants for the available list
async function fetchAllTenants() {
  if (organization.value === null) return;

  const response = await axios.get<TenantGetManyResponseDto>("/api/global/tenants");
  allTenants.value = response.data;

  // Now that we have all tenants, update assignedTenants with the complete tenant objects
  if (organization.value.tenants && organization.value.tenants.length > 0) {
    const orgTenantIds = organization.value.tenants.map((tenant) => tenant.id);
    const orgTenantIdSet = new Set(orgTenantIds);

    // Filter allTenants to get the complete tenant objects for the assigned tenants
    const matchingTenants: TenantBase[] = [];

    for (const tenant of allTenants.value) {
      if (orgTenantIdSet.has(tenant.id)) {
        matchingTenants.push({
          id: tenant.id,
          name: tenant.name,
        });
      }
    }

    if (matchingTenants.length > 0) {
      assignedTenants.value = matchingTenants;
    }
  }
}

// Handle tenant drag and drop
function handleTenantMove(_evt: Sortable.SortableEvent) {
  // Get all tenant IDs from the assigned list
  const assignedTenantIds = Array.from(
    assignedTenantsList.value?.querySelectorAll(".tenant-item") ?? []
  )
    .map((el) => (el as HTMLElement).dataset.id)
    .filter((id): id is string => Boolean(id));

  if (assignedTenantIds.length > 0) {
    // Find the full tenant objects for these IDs and extract just id and name
    const newAssignedTenants: TenantBase[] = [];

    assignedTenantIds.forEach((id) => {
      const tenant = allTenants.value.find((t) => t.id === id);

      if (tenant) {
        newAssignedTenants.push({
          id: tenant.id,
          name: tenant.name,
        });
      }
    });

    // Update the assignedTenants ref
    assignedTenants.value = newAssignedTenants;
    isTenantsDirty.value = true;
  } else {
    // Empty list case
    assignedTenants.value = [];
    isTenantsDirty.value = true;
  }
}

function addAllowedDomain() {
  if (organization.value === null) return;
  if (newDomain.value === "") return;

  organization.value.allowedDomains.push(newDomain.value);
  newDomain.value = "";
  isDirty.value = true;
}

// Remove a domain
function removeAllowedDomain(index: number) {
  if (organization.value === null) return;

  organization.value.allowedDomains.splice(index, 1);
  isDirty.value = true;
}

// Save organization details
async function saveOrganization() {
  if (organization.value === null) return;
  isSaving.value = true;

  try {
    const updateData: Partial<OrganizationGetOneResponseDto> = {
      name: organization.value.name,
      isSsoEnforced: organization.value.isSsoEnforced,
      ssoStrategy: organization.value.ssoStrategy,
      allowedDomains: organization.value.allowedDomains,
    };

    // Include SAML config if SSO strategy is SAML
    if (organization.value.ssoStrategy === SSOStrategy.SAML) {
      updateData.samlConfig = { ...samlConfig.value };
    }

    await axios.patch(`/api/organizations/${organizationId.value}`, updateData);
    isDirty.value = false;

    // Refresh the organization data to get the updated SAML config
    await fetchOrganization();

    addNotification({ type: "info", message: "Organization updated successfully" });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed saving organization",
    });
  } finally {
    isSaving.value = false;
  }
}

// Save tenant changes
async function saveTenants() {
  if (organization.value === null) return;

  isSaving.value = true;

  try {
    const tenantIds = assignedTenants.value.map((t) => t.id);
    await axios.patch(`/api/organizations/${organizationId.value}/tenants`, { tenantIds });

    // After saving, refresh the organization data to get the updated tenants
    await fetchOrganization();
    isTenantsDirty.value = false;

    addNotification({ type: "info", message: "Organization tenants updated successfully" });
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed saving tenants",
    });
  } finally {
    isSaving.value = false;
  }
}

// Update Sortable instance when the filtered list changes
async function updateAvailableTenantsList() {
  if (_availableSortable) {
    // We need to reinitialize the sortable list after the DOM updates
    // This ensures Sortable works with the filtered items
    await nextTick();

    _availableSortable.destroy();
    if (availableTenantsList.value) {
      _availableSortable = Sortable.create(availableTenantsList.value, sortableOptions);
    }
  }
}

// Update Sortable instance for assigned tenants list when filtered
async function updateAssignedTenantsList() {
  if (_assignedSortable) {
    await nextTick();

    _assignedSortable.destroy();
    if (assignedTenantsList.value) {
      _assignedSortable = Sortable.create(assignedTenantsList.value, sortableOptions);
    }
  }
}

// Clear the available tenants filter
async function clearAvailableFilter() {
  availableTenantFilter.value = "";
  await updateAvailableTenantsList();
}

// Clear the assigned tenants filter
async function clearAssignedFilter() {
  assignedTenantFilter.value = "";
  await updateAssignedTenantsList();
}

// Watch for changes in available tenants and update sortable
watch(availableTenants, updateAvailableTenantsList);
// Watch for changes in assigned tenants and update sortable
watch(assignedTenants, updateAssignedTenantsList);
</script>

<style scoped>
.organization-view {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  max-width: 1600px;
  margin: 0 auto;
  color: v-bind("themeColors[ThemeProperty.TextColor1]");
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: v-bind("themeColors[ThemeProperty.TextColor2]");
}

.saving-indicator {
  display: flex;
  align-items: center;
  color: v-bind("themeColors[ThemeProperty.TextColor1]");
  font-style: italic;
}

.organization-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.layout-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 1024px) {
  .layout-container {
    grid-template-columns: minmax(450px, 1fr) 1.7fr;
  }
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 640px) {
  .form-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.form-section {
  background: v-bind("themeColors[ThemeProperty.BackgroundColor2]");
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  padding: 1.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.details-section {
  display: flex;
  flex-direction: column;
}

.tenants-section {
  display: flex;
  flex-direction: column;
}

.form-section h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  color: v-bind("themeColors[ThemeProperty.TextColor2]");
  border-bottom: 1px solid v-bind("themeColors[ThemeProperty.BorderColor1]");
  padding-bottom: 0.8rem;
}

.saml-config-section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid v-bind("themeColors[ThemeProperty.BorderColor1]");
  width: 100%;
  box-sizing: border-box;
}

.saml-config-section h3 {
  margin-top: 0;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
  color: v-bind("themeColors[ThemeProperty.TextColor2]");
}

.form-group {
  margin-bottom: 1.2rem;
  width: 100%;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: v-bind("themeColors[ThemeProperty.TextColor1]");
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 0.6rem;
  border: 1px solid v-bind("themeColors[ThemeProperty.BorderColor1]");
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  font-size: 1rem;
  background-color: v-bind("themeColors[ThemeProperty.BackgroundColor3]");
  color: v-bind("themeColors[ThemeProperty.TextColor1]");
  font-family: inherit;
  box-sizing: border-box;
}

.form-textarea {
  resize: vertical;
  min-height: 100px;
}

.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  outline: none;
  border-color: v-bind("themeColors[ThemeProperty.InputFocusBorderColor]");
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.helper-text {
  display: block;
  margin-top: 0.3rem;
  font-size: 0.85rem;
  color: v-bind("themeColors[ThemeProperty.AccentColor1]");
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: v-bind("themeColors[ThemeProperty.BackgroundColor4]");
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: v-bind("themeColors[ThemeProperty.TextColor1]");
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: v-bind("themeColors[ThemeProperty.ButtonAccentedBackgroundColor]");
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.domain-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.domain-item,
.domain-add {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.remove-button,
.add-button {
  border: none;
  background: v-bind("themeColors[ThemeProperty.NotificationErrorBackgroundColor]");
  color: v-bind("themeColors[ThemeProperty.TextColor2]");
  width: 28px;
  height: 28px;
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.add-button {
  background: v-bind("themeColors[ThemeProperty.ButtonSuccessBackgroundColor]");
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.save-button {
  background-color: v-bind("themeColors[ThemeProperty.ButtonAccentedBackgroundColor]");
  color: v-bind("themeColors[ThemeProperty.TextColor2]");
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.save-button:hover {
  background-color: v-bind("themeColors[ThemeProperty.ButtonAccentedActiveColor]");
}

.save-button:disabled {
  background-color: v-bind("themeColors[ThemeProperty.ButtonAccentedDisabledColor]");
  cursor: not-allowed;
}

.tenant-management {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
}

.tenant-lists {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  flex-grow: 1;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .tenant-lists {
    grid-template-columns: 1fr 1fr;
  }
}

.tenant-list {
  display: flex;
  flex-direction: column;
  background: v-bind("themeColors[ThemeProperty.BackgroundColor3]");
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  padding: 1.25rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.tenant-list h3 {
  margin-top: 0;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: v-bind("themeColors[ThemeProperty.TextColor2]");
}

.filter-input-container {
  position: relative;
  margin-bottom: 0.8rem;
  width: 100%;
  box-sizing: border-box;
}

.filter-input {
  width: 100%;
  padding: 0.6rem;
  padding-right: 2rem;
  border: 1px solid v-bind("themeColors[ThemeProperty.BorderColor1]");
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  font-size: 0.9rem;
  background-color: v-bind("themeColors[ThemeProperty.BackgroundColor3]");
  color: v-bind("themeColors[ThemeProperty.TextColor1]");
  box-sizing: border-box;
}

.filter-input:focus {
  outline: none;
  border-color: v-bind("themeColors[ThemeProperty.InputFocusBorderColor]");
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.clear-filter {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: v-bind("themeColors[ThemeProperty.AccentColor1]");
  font-size: 1.2rem;
}

.sortable-list {
  min-height: 200px;
  border: 1px dashed v-bind("themeColors[ThemeProperty.BorderColor1]");
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  padding: 0.5rem;
  background: v-bind("themeColors[ThemeProperty.BackgroundColor2]");
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.tenant-item {
  padding: 0.8rem;
  background: v-bind("themeColors[ThemeProperty.BackgroundColor4]");
  border-radius: v-bind("themeColors[ThemeProperty.BorderRadius]");
  margin-bottom: 0.5rem;
  cursor: move;
  transition: background-color 0.2s;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
}

.tenant-item:hover {
  background: v-bind("themeColors[ThemeProperty.BackgroundColor5]");
}

.empty-list {
  color: v-bind("themeColors[ThemeProperty.AccentColor1]");
  font-style: italic;
  text-align: center;
  padding: 2rem 0;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: v-bind("themeColors[ThemeProperty.ButtonAccentedBackgroundColor]");
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
