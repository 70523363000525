<template>
  <div
    data-testid="uploading-file"
    class="upload-job"
    :class="{ zip: job.isZip, [job.state]: true }"
  >
    <div class="upload-job-title">{{ job.title }}</div>

    <div class="upload-job-icon">
      <LoadingIndicator
        v-if="job.state === UploadJobState.Pending || job.state === UploadJobState.InProgress"
      />
      <div v-else-if="job.state === UploadJobState.Error">
        <FontAwesomeIcon :data-testid="`file-status-icon-${job.state}`" :icon="icon" />
        <FontAwesomeIcon
          v-if="isRetryableError(job)"
          class="upload-job-error-retry-icon"
          icon="rotate-right"
          data-testid="file-status-icon-retry"
          @click="retryUploadJob(job)"
        />
      </div>
      <FontAwesomeIcon v-else :data-testid="`file-status-icon-${job.state}`" :icon="icon" />
    </div>

    <div
      v-if="job.state === UploadJobState.InProgress || job.message"
      style="grid-area: status; margin-top: 8px"
    >
      <div v-if="job.state === UploadJobState.InProgress" class="upload-progress">
        <div
          class="upload-progress-bar"
          role="progressbar"
          :style="{ width: `${(job.progress ?? 0) * 100}%` }"
        />
      </div>
      <div v-else style="text-align: justify">
        {{ job.message }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { UploadJob, UploadJobState, uploadJobStore } from "../state/stores/upload-job.store";
import LoadingIndicator from "./LoadingIndicator.vue";

interface Props {
  job: UploadJob;
}

const props = defineProps<Props>();

const { retryUploadJob, isRetryableError } = uploadJobStore();

const icon = computed((): string => {
  switch (props.job.state) {
    case UploadJobState.Error:
      return "triangle-exclamation";
    case UploadJobState.Info:
      return "circle-info";
    case UploadJobState.Completed:
      return "check";
    default:
      return "";
  }
});
</script>

<style scoped lang="scss">
.upload-job {
  display: grid;
  grid-template-areas: "name icon" "status status";
  grid-template-columns: 1fr auto;
  column-gap: 16px;
  background: var(--bg-color-2);
  border-radius: var(--border-radius);
  padding: 8px;

  &.zip {
    background: none;
  }

  &.completed {
    .upload-job-icon {
      color: var(--confirm-color-2);
    }
  }

  &.error {
    .upload-job-icon {
      color: red;
    }

    .upload-job-error-retry-icon {
      color: var(--accent-color-1);
      padding-left: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.info {
    .upload-job-icon {
      color: #fedf3e;
    }
  }
}

.upload-job-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-progress {
  height: 6px;
  border-radius: var(--border-radius);
  background-color: var(--accent-color-1);
  overflow: hidden;
  align-self: center;
}

.upload-progress-bar {
  background-color: var(--accent-color-2);
  height: 6px;
  transition: width 100ms linear;
}

.clear-notifications-button {
  align-self: flex-end;
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  background-color: var(--bg-color-2);

  &:not(:disabled) {
    &:not(:active) {
      &:hover,
      &.active {
        background-color: var(--bg-color-2);
      }
    }
  }
}
</style>
