<template>
  <AuthPage
    auth-button-visible
    :auth-button-text="authButtonText"
    :auth-button-enabled="isAuthButtonEnabled"
    :auth-button-spinner="isWorking"
    @submit="onSubmit"
  >
    <div v-if="isLoading" class="loading-container">
      <LoadingIndicator />
      <span>Loading tenant options...</span>
    </div>

    <div v-else-if="error" class="error-container">
      <FontAwesomeIcon icon="exclamation-circle" size="lg" />
      <span>{{ error }}</span>
    </div>

    <div v-else class="tenant-select-container">
      <div class="auth-text">
        <div>{{ email }}</div>
        <b style="font-size: 16px">Which tenant would you like to sign in to?</b>
      </div>

      <div class="tenant-options">
        <div
          v-for="tenant in tenantOptions"
          :key="tenant.id"
          data-testid="tenant-option"
          class="tenant-option"
          :class="{ selected: selectedTenantId === tenant.id }"
          @click="selectTenant(tenant.id)"
        >
          {{ tenant.name }}
          <FontAwesomeIcon
            v-if="tenant.id === selectedTenantId"
            class="icon"
            icon="check"
            size="lg"
          />
        </div>
      </div>
    </div>
  </AuthPage>
</template>

<script setup lang="ts">
import { getRequestErrorMessage } from "@/utils/request-helpers";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { addNotification } from "../utils/notifications";
import AuthPage from "./AuthPage.vue";
import { fetchCurrentTenantAndUser } from "./current-session";

interface TenantOption {
  id: string;
  name: string;
}

interface JwtPayload {
  email: string;
  organizationId: string;
  tenantOptions: TenantOption[];
}

// Local storage key for the last selected tenant
const LAST_SELECTED_TENANT_KEY = "lastSelectedTenantId";

const route = useRoute();
const router = useRouter();
const redirectTo = useRouteQuery("redirectTo");
const lastSelectedTenantId = useStorage<string>(LAST_SELECTED_TENANT_KEY, "");

const token = ref<string>("");
const email = ref<string>("");
const tenantOptions = ref<TenantOption[]>([]);
const selectedTenantId = ref<string>("");
const isLoading = ref<boolean>(true);
const isWorking = ref<boolean>(false);
const error = ref<string | null>(null);
const authButtonText = ref<string>("Continue");

const isAuthButtonEnabled = computed(() => {
  return selectedTenantId.value !== "" || error.value !== null;
});

onMounted(() => {
  try {
    const hashParams = new URLSearchParams(route.hash.substring(1));
    const tokenFromHash = hashParams.get("token");

    if (tokenFromHash === null) {
      throw new Error("No token found in URL");
    }

    token.value = tokenFromHash;

    const payload = jwtDecode<JwtPayload>(tokenFromHash);

    email.value = payload.email;
    tenantOptions.value = payload.tenantOptions;

    if (tenantOptions.value.length === 0) {
      throw new Error("No tenant options available for this user");
    }

    const lastSelectedIndex = tenantOptions.value.findIndex(
      (tenant) => tenant.id === lastSelectedTenantId.value
    );

    if (lastSelectedIndex >= 0) {
      // Use last selected tenant
      selectedTenantId.value = lastSelectedTenantId.value;

      // Move last selected tenant to top of the list if not already there
      if (lastSelectedIndex > 0) {
        const lastSelectedTenant = tenantOptions.value.splice(lastSelectedIndex, 1)[0];
        tenantOptions.value.unshift(lastSelectedTenant);
      }
    } else {
      // Default to first tenant
      selectedTenantId.value = tenantOptions.value[0].id;
    }
  } catch {
    error.value = "There was an error signing in";
    authButtonText.value = "Back to Sign In";
  } finally {
    isLoading.value = false;
  }
});

// Save selected tenant ID to localStorage whenever it changes
watch(selectedTenantId, (newValue) => {
  if (newValue) {
    lastSelectedTenantId.value = newValue;
  }
});

function selectTenant(tenantId: string) {
  selectedTenantId.value = tenantId;
}

async function onSubmit() {
  if (error.value !== null) {
    await goToSignIn();
    return;
  }

  if (!selectedTenantId.value) {
    return;
  }

  isWorking.value = true;

  try {
    await axios.post(
      "/api/auth/saml/select-tenant",
      {
        token: token.value,
        selectedTenantId: selectedTenantId.value,
        redirectTo: [redirectTo.value].flat()[0],
      },
      {
        withCredentials: true, // Ensure cookies are sent with the request
      }
    );

    // Only follow redirect if we successfully fetch the tenant and user
    if ((await fetchCurrentTenantAndUser()) === 200) {
      // Follow redirect path from query parameter or default to study-list
      const redirectPath = [redirectTo.value].flat()[0] ?? "/study-list";
      await router.push(redirectPath);
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      addNotification({
        type: "error",
        message: getRequestErrorMessage(err) ?? "Login has expired. Please try again.",
      });

      // If unauthorized, redirect back to sign in
      if (err.response.status === 401) {
        setTimeout(() => {
          goToSignIn().catch((e: unknown) => console.error(e));
        }, 3000);
      }
    }

    isWorking.value = false;
  }
}

async function goToSignIn(): Promise<void> {
  await router.push("/sign-in");
}
</script>

<style scoped lang="scss">
.tenant-select-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.auth-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 8px;
}

.tenant-options {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  /* Use min-height and max-height instead of fixed height */
  min-height: auto;
  max-height: 280px; /* Maximum height for many tenant options */
  overflow-y: auto;
  padding-right: 8px;
  /* Add scrollbar styling for better UX */
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color-1) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-color-1);
    border-radius: 3px;
  }

  .tenant-option {
    padding: 12px 16px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid var(--border-color-1);
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: var(--accent-color-1);
    }

    &.selected {
      border-color: var(--accent-color-1);
      background-color: rgba(var(--accent-color-1-rgb), 0.15);
    }

    .icon {
      color: var(--accent-color-green);

      animation: fadeIn 0.2s ease-in-out;
    }
  }
}

.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
  text-align: center;
}

.error-container {
  color: var(--warning-color-1);

  svg {
    font-size: 32px;
  }
}

.back-button {
  margin-top: 16px;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid var(--border-color-1);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: var(--accent-color-1);
    background-color: rgba(255, 255, 255, 0.05);
  }
}
</style>
