<template>
  <div class="about selectable-text">
    <div class="table" data-testid="about-content">
      <img class="header" src="/logo-with-name.png" />

      <div class="outlined-text" style="grid-area: ref-icon">REF</div>
      <div style="grid-area: ref-text">HeartLab</div>

      <IndustryIcon style="grid-area: date-icon" width="32px" height="32px" />

      <div style="grid-area: date-text">{{ RELEASE_DATE }}</div>

      <div style="grid-area: gtin-icon"><strong>GTIN</strong></div>
      <div style="grid-area: gtin-text">09421907090023</div>

      <div class="outlined-text" style="grid-area: lot-icon">LOT</div>
      <div style="grid-area: lot-text">
        {{ appVersion }} build {{ RELEASE_SHA.substring(0, 7) }}
      </div>

      <div style="grid-area: licenses">
        <p>41670 Radiological PACS</p>
        <p>Rx Only</p>
        <p>The use of the software is subject to the accompanying licence terms.</p>

        <InstructionsForUse />

        <p>
          Hosted in
          <template v-if="awsRegion === 'ap-southeast-2'"> Sydney, Australia </template>
          <template v-else-if="awsRegion === 'us-west-2'">Oregon, USA</template>
          <template v-else> {{ awsRegion }} </template>
        </p>
      </div>

      <div
        style="
          grid-area: manufacturer-text;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
        "
      >
        <div style="display: flex; align-items: flex-end; gap: 16px" @dblclick="throwError">
          <IndustryIcon
            style="grid-area: manufacturer-icon"
            class="manufacturer-icon"
            width="32px"
            height="32px"
          />
          <div class="column-header">HeartLab Limited</div>
        </div>

        <p>
          Copyright &copy; HeartLab Limited.
          <br />
          All rights reserved.
        </p>

        <p>
          <a class="manufacturer-link" href="https://heartlab.com" target="_blank">
            https://heartlab.com
          </a>
        </p>

        <p>
          305/150 Karangahape Road<br />
          Auckland 1010<br />
          New Zealand
        </p>

        <br />

        <p>
          <b>Australian Sponsor</b><br />
          Emergo Australia<br />
          Level 20 Tower II<br />
          Darling Park<br />
          201 Sussex Street<br />
          Sydney, NSW 2000<br />
          Australia<br />
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { currentTenant } from "./auth/current-session";
import { RELEASE_DATE, RELEASE_SHA } from "./environment";
import IndustryIcon from "./icons/Industry.svg.vue";
import InstructionsForUse from "./icons/InstructionsForUse.svg.vue";

const awsRegion = window.heartlab?.awsRegion ?? currentTenant.awsRegion;
const appVersion = window.heartlab?.appVersion ?? "X.X.X";

function throwError() {
  if (window.heartlab?.environmentName === "staging") {
    throw Error("You found an error!");
  }
}
</script>

<style scoped lang="scss">
.about {
  display: grid;
  place-content: start center;
  background: var(--bg-color-1);
  flex: 1;
}

.table {
  display: grid;
  grid-template-areas:
    "header   header     header     header            header"
    "left-pad ref-icon   ref-text   manufacturer-text right-pad"
    "left-pad date-icon  date-text  manufacturer-text right-pad"
    "left-pad gtin-icon  gtin-text  manufacturer-text right-pad"
    "left-pad lot-icon   lot-text   manufacturer-text right-pad"
    "left-pad licenses   licenses   manufacturer-text right-pad";
  grid-template-columns: 50px min-content 240px 240px 50px;
  gap: 8px 15px;
  align-items: center;
}

.header {
  grid-area: header;
  margin: 60px 0;
  width: 70%;
  justify-self: center;
}

.column-header {
  font-weight: bold;
  font-size: 1.5em;
}

.outlined-text {
  width: min-content;
  height: min-content;
  font-weight: bold;
  padding: 4px;
  line-height: 1em;
  border: 1px solid white;
}

.manufacturer-link {
  color: white;
  font-style: italic;
  text-decoration: underline;
}

.manufacturer-icon {
  fill: var(--text-color-1);
}
</style>
