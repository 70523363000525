import type { Study } from "@/utils/study-data";
import { getEmptyStudy } from "@/utils/study-data";
import { useInfiniteQuery, useQueryClient } from "@tanstack/vue-query";
import axios from "axios";
import { DateTime } from "luxon";
import { watch, type Ref } from "vue";
import type { StudyGetManyResponseDto } from "../../../../../backend/src/studies/dto/study-get-many.dto";
import type { StudyGetOneResponseDto } from "../../../../../backend/src/studies/dto/study-get-one.dto";
import { currentUser, currentTenant } from "@/auth/current-session";
import type { StudyListQueryPage } from "./state";
import { state, type StudyListStatusFilter } from "./state";

/**
 * Returns a query object for the study list.
 *
 * @param enabled - A ref to control whether the query is enabled
 * @returns A query object for the study list.
 */
export function getInfiniteStudies(enabled: Ref<boolean>) {
  const queryClient = useQueryClient();

  watch(
    () => state.value.updatedStudies,
    (newValue) => {
      if (Object.keys(newValue).length > 0) {
        void queryClient.invalidateQueries({
          queryKey: ["studies"],
          refetchType: "active",
        });
      }
    },
    { deep: true }
  );

  return useInfiniteQuery<StudyListQueryPage>({
    queryKey: ["studies", state.value.filters, currentUser.id, currentTenant.id],
    queryFn: async ({ pageParam }) => {
      const page = typeof pageParam === "number" ? pageParam : 0;
      const response = await axios.get<StudyGetManyResponseDto>(
        `/api/studies?${getQueryParams(page).toString()}`
      );
      const studies = response.data.studies.map((study: StudyGetOneResponseDto) => ({
        ...getEmptyStudy(),
        ...study,
      })) as Study[];

      return {
        studies,
        count: response.data.count,
      };
    },
    getNextPageParam: (lastPage: StudyListQueryPage, allPages: StudyListQueryPage[]) => {
      const totalFetched = allPages.reduce((total, page) => total + page.studies.length, 0);

      return totalFetched < lastPage.count ? allPages.length : undefined;
    },

    // Only run the query if enabled is true
    enabled: () => enabled.value,

    // Consider data stale after 3 minutes. This causes a refetch.
    staleTime: 1000 * 60 * 3,

    // If a user navigates to a different page and then returns to the study list,
    // refetch the data.
    refetchOnWindowFocus: true,

    // Start with the first page.
    initialPageParam: 0,
  });
}

export function getQueryParams(pageParam: number): URLSearchParams {
  const query = new URLSearchParams({
    limit: state.value.pageSize.toString(),
    offset: (pageParam * state.value.pageSize).toString(),
    sortColumnName: state.value.filters.sortColumnName,
    sortDirection: state.value.filters.sortDirection,
  });

  if (state.value.filters.searchFilter) {
    query.append("filter", state.value.filters.searchFilter);
  }

  if (state.value.filters.dateFilter !== null) {
    query.append(
      "takenAtBefore",
      `${DateTime.fromJSDate(state.value.filters.dateFilter.end).toFormat("yyyy-MM-dd")}T23:59:59`
    );
    query.append(
      "takenAtAfter",
      `${DateTime.fromJSDate(state.value.filters.dateFilter.start).toFormat("yyyy-MM-dd")}T00:00:00`
    );
  }

  if (state.value.filters.assignedUserFilter !== "") {
    query.append("assignedUserFilter", state.value.filters.assignedUserFilter);
  }

  if (state.value.filters.studyTypeFilter !== "") {
    query.append("studyTypeFilter", state.value.filters.studyTypeFilter);
  }

  const activeStatusFilters = state.value.filters.reportStatusFilters.filter(
    (filter: StudyListStatusFilter) => filter.value
  );

  if (activeStatusFilters.length > 0) {
    query.append(
      "reportStatus",
      activeStatusFilters
        .map((filter: StudyListStatusFilter) => filter.reportStatus ?? "notStarted")
        .join(",")
    );
  }

  return query;
}
