<template>
  <div class="settings-title">Defaults Measurement Display Options</div>

  <div class="top-row">
    <FilterInput v-model="searchTerm" placeholder="Search" data-testid="search-filter" />
  </div>

  <div class="measurements">
    <template v-for="(measurement, index) of filteredMeasurements" :key="measurement.name">
      <div>{{ getMeasurementDisplayName(measurement.name, "indexed") }}</div>
      <DropdownWidget
        class="display-option-dropdown"
        :model-value="filteredMeasurements[index].displayOption"
        :items="[
          { value: StudyMeasurementDisplayOption.Unindexed, text: 'Display unindexed' },
          { value: StudyMeasurementDisplayOption.PreferIndexed, text: 'Display indexed' },
          {
            value: StudyMeasurementDisplayOption.UnindexedAndIndexed,
            text: 'Display unindexed and indexed',
          },
        ]"
        :data-testid="`display-option-dropdown-${index}`"
        @update:model-value="saveDebounced(index, $event as StudyMeasurementDisplayOption)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { isMeasurementIndexable } from "@/measurements/measurement-helpers";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, ref } from "vue";
import { getMeasurementDisplayName } from "../../../backend/src/measurements/measurement-display";
import { MeasurementName } from "../../../backend/src/measurements/measurement-names";
import { StudyMeasurementDisplayOption } from "../../../backend/src/studies/study-measurement-enums";
import { currentUser } from "../auth/current-session";
import DropdownWidget from "../components/DropdownWidget.vue";
import FilterInput from "../components/FilterInput.vue";
import { addNotification } from "../utils/notifications";

const searchTerm = ref("");

const defaultIndexedMeasurements = Object.values(MeasurementName).filter((measurement) =>
  isMeasurementIndexable(measurement)
);

const displayOptions = computed(() => {
  if (currentUser.measurementDisplayOptionDefaults === undefined) {
    return defaultIndexedMeasurements.map((measurementName) => ({
      name: measurementName,
      displayOption: StudyMeasurementDisplayOption.PreferIndexed,
    }));
  }

  return defaultIndexedMeasurements.map((measurementName) => {
    const displayOptionDefault = currentUser.measurementDisplayOptionDefaults?.find(
      (defaults) => defaults.measurementName === measurementName
    ) ?? { displayOption: StudyMeasurementDisplayOption.PreferIndexed };

    return {
      name: measurementName,
      displayOption: displayOptionDefault.displayOption,
    };
  });
});

const filteredMeasurements = computed(() =>
  displayOptions.value
    .filter((measurement) =>
      getMeasurementDisplayName(measurement.name, "indexed")
        .toLowerCase()
        .includes(searchTerm.value.toLowerCase().trim())
    )
    .sort((a, b) => a.name.localeCompare(b.name))
);

const debouncedSave = useDebounceFn(() => {
  void save();
}, 1000);

async function saveDebounced(filteredIndex: number, newValue: StudyMeasurementDisplayOption) {
  const index = displayOptions.value.findIndex(
    (measurement) => measurement.name === filteredMeasurements.value[filteredIndex].name
  );

  currentUser.measurementDisplayOptionDefaults = displayOptions.value.map((option, i) =>
    i === index
      ? { measurementName: displayOptions.value[i].name, displayOption: newValue }
      : {
          measurementName: displayOptions.value[i].name,
          displayOption: option.displayOption,
        }
  );

  await debouncedSave();
}

async function save(): Promise<void> {
  try {
    await axios.patch(`/api/user/current`, {
      measurementDisplayOptionDefaults: currentUser.measurementDisplayOptionDefaults,
    });

    addNotification({ type: "info", message: "Defaults display options saved" });
  } catch {
    addNotification({ type: "error", message: "Failed saving defaults display options" });
    return;
  }
}
</script>

<style scoped lang="scss">
.top-row {
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
}

.measurements {
  display: grid;
  grid-template-columns: minmax(250px, max-content) minmax(220px, max-content);
  gap: 4px 32px;
  align-items: center;
}

.display-option-dropdown {
  border: 0;
  background-color: var(--bg-color-2);
}
</style>
