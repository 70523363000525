import { datadogRum } from "@datadog/browser-rum";
import {
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_SITE,
  RELEASE_SHA,
} from "./environment";

export function setupDatadogRealUserMonitoring(): void {
  if (
    (window.heartlab?.environmentName ?? "") === "" ||
    DATADOG_SITE === undefined ||
    DATADOG_RUM_APPLICATION_ID === undefined ||
    DATADOG_RUM_CLIENT_TOKEN === undefined
  ) {
    return;
  }

  datadogRum.init({
    applicationId: DATADOG_RUM_APPLICATION_ID,
    clientToken: DATADOG_RUM_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: "heartlab-frontend",
    env: window.heartlab?.environmentName,
    version: `${window.heartlab?.appVersion}-${RELEASE_SHA.substring(0, 7)}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}
