export const TOO_MANY_USERS = "too_many_users";
export const USER_NOT_FOUND = "user_not_found";
export const EMAIL_MISMATCH = "email_mismatch";
export const USER_NOT_IN_ORGANIZATION = "user_not_in_organization";
export const NO_TENANTS_FOUND = "no_tenants_found";
export const SSO_NOT_ENABLED = "sso_not_enabled";
export const DOMAIN_NOT_ALLOWED = "domain_not_allowed";

export enum AuthFactor {
  EmailPassword = "email-password",
  Microsoft = "microsoft",
  MFA = "mfa",
  StudyShareLink = "study-share-link",
  GlobalAdmin = "global-admin",
  SAML = "saml",
}
