<template>
  <Tooltip>
    <FontAwesomeIcon
      :icon="isUpdating ? 'refresh' : 'flag'"
      :spin="isUpdating"
      :class="{ urgent: props.study.isUrgent }"
      fixed-width
      class="study-urgent-flag"
      data-testid="study-urgent-flag"
      @click="onClick"
    />
    <template #content>
      <template v-if="props.study.isUrgent">
        Study is urgent.
        <template v-if="hasStudyUpdatePermission">Click to mark it as not urgent.</template>
      </template>
      <template v-else>
        Study is not urgent.
        <template v-if="hasStudyUpdatePermission">Click to mark it as urgent.</template>
      </template>
    </template>
  </Tooltip>
</template>

<script setup lang="ts">
import Tooltip from "@/components/Tooltip.vue";
import { useStudyListStore } from "@/state/stores/study-list";
import { addNotification } from "@/utils/notifications";
import { Study } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { ref } from "vue";
import { hasStudyUpdatePermission } from "../auth/authorization";

interface Props {
  study: Study;
}

const props = defineProps<Props>();

const studyListStore = useStudyListStore();

const isUpdating = ref(false);

async function onClick(): Promise<void> {
  if (!hasStudyUpdatePermission.value) {
    return;
  }

  isUpdating.value = true;
  const newUrgentState = !props.study.isUrgent;

  try {
    await axios.patch(`/api/studies/${props.study.id}/urgency`, null, {
      params: { isUrgent: newUrgentState },
    });
  } catch {
    addNotification({ type: "error", message: "Failed changing urgency of study" });
    return;
  } finally {
    isUpdating.value = false;
    studyListStore.updateStudy(props.study.id, { isUrgent: !props.study.isUrgent });
  }

  studyListStore.updateStudy(props.study.id, { isUrgent: newUrgentState });
  // eslint-disable-next-line vue/no-mutating-props
  props.study.isUrgent = newUrgentState;

  addNotification({
    type: "info",
    message: `Marked study as ${newUrgentState ? "urgent" : "not urgent"}`,
  });
}
</script>

<style scoped lang="scss">
.study-urgent-flag {
  color: var(--accent-color-1);
  transition:
    color 100ms ease,
    background-color 100ms ease,
    filter 100ms ease;
  cursor: pointer;

  &.urgent {
    color: var(--accent-color-hot);

    &:hover {
      color: var(--accent-color-hot);
      filter: contrast(1.25);
    }
  }

  &:hover {
    color: var(--accent-color-2);
  }
}
</style>
