<template>
  <Modal
    v-if="!isOnline && !IS_DEV_MODE"
    title="Internet Connection Lost"
    :show-close-button="false"
  >
    <div class="network-connection-lost">Waiting for the internet connection to be restored.</div>
  </Modal>

  <div class="header" :class="{ 'on-auth-page': isAuthPageVisible }">
    <template v-if="!isAuthPageVisible">
      <RouterLink
        v-if="hasStudyListViewPermission"
        to="/study-list"
        data-testid="branding-link"
        style="display: contents"
      >
        <Branding />
      </RouterLink>
      <RouterLink v-else-if="currentUser.id === ''" to="/sign-in" style="display: contents">
        <Branding />
      </RouterLink>
      <Branding v-else />
    </template>

    <Tooltip
      v-if="studyId && !hasStudyUpdatePermission"
      content="You only have permission to view this study"
      placement="right"
      data-testid="view-only-label"
    >
      <div class="view-only">Viewing only</div>
    </Tooltip>

    <div style="margin-left: auto" />

    <UploadJobsDropdown v-if="uploadJobs.length > 0" />
    <DownloadPopper v-if="!isAuthPageVisible && downloadStore.numberOfDownloads > 0" />
    <Popper
      class="dropdown-menu-container"
      placement="bottom-start"
      :interactive="false"
      :offset-distance="2"
      @open="isMenuDropdownOpen = true"
      @close="isMenuDropdownOpen = false"
    >
      <button
        data-testid="dropdown-button"
        class="dropdown-button"
        :class="{ active: isMenuDropdownOpen, open: isMenuDropdownOpen }"
      >
        <div v-if="!isGuest && currentUser.id !== ''" class="text-content">
          {{ currentUser.name.length ? currentUser.name : currentUser.email }}
        </div>
        <Chevron :is-dropdown-open="isMenuDropdownOpen" />
      </button>

      <template #content>
        <div class="dropdown-menu">
          <template v-if="currentUser.id !== '' && !isGuest">
            <div class="user-details-item">
              <FontAwesomeIcon icon="user" size="lg" />
              <div class="user-name-and-email">
                <span class="text-content">
                  {{ currentUser.name.length ? currentUser.name : currentUser.email }}
                </span>
                <span class="text-content">{{ currentUser.email }}</span>
              </div>
            </div>

            <div class="divider" />
          </template>

          <UploadFiles v-if="hasStudyUploadPermission" class="menu-item" />

          <ImportStudies
            v-if="hasDicomEndpointQueryPermission && currentTenant.hasDicomEndpoints"
            class="menu-item"
          />

          <RouterLink
            v-if="hasCurrentUserUpdatePermission"
            class="menu-item"
            to="/settings/user"
            data-testid="settings-user-link"
          >
            <FontAwesomeIcon icon="gear" size="lg" fixed-width />
            <b>Settings</b>
          </RouterLink>

          <RouterLink v-if="hasAuditLogViewPermission" class="menu-item" to="/audit-logs">
            <FontAwesomeIcon icon="user-lock" fixed-width />
            <b>Audit Logs</b>
          </RouterLink>

          <a
            v-if="!isGuest"
            target="_blank"
            href="https://help.heartlab.com"
            data-testid="help-support-link"
            class="menu-item"
          >
            <FontAwesomeIcon icon="circle-info" size="lg" />
            Help
          </a>

          <a
            v-if="!isGuest"
            class="menu-item"
            data-testid="contact-support"
            @click="isContactSupportModalVisible = true"
          >
            <FontAwesomeIcon icon="envelope" size="lg" />
            Contact Support
          </a>

          <RouterLink to="/about" class="menu-item">
            <FontAwesomeIcon icon="house" fixed-width />
            <b>About</b>
          </RouterLink>

          <template v-if="currentUser.id">
            <div class="divider" />

            <a class="menu-item" data-testid="sign-out" @click="onSignOutClick">
              <FontAwesomeIcon icon="sign-out-alt" fixed-width />
              <b>Sign Out</b>
            </a>
          </template>
        </div>
      </template>
    </Popper>

    <Teleport to="#warning-banner-target">
      <GlobalAdminInitiatedBanner v-if="currentUser.createdByGlobalAdminLogin" />
    </Teleport>

    <NotForClinicalUseBanner v-if="!isAuthPageVisible" />
  </div>

  <RouterView />
  <NotificationList />

  <OnboardingModal v-if="!currentUser.onboarded" />

  <ContactSupportModal
    v-if="isContactSupportModalVisible"
    @close="isContactSupportModalVisible = false"
  />
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useNetwork } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { signOut } from "./auth/authentication";
import {
  hasAuditLogViewPermission,
  hasCurrentUserUpdatePermission,
  hasDicomEndpointQueryPermission,
  hasStudyListViewPermission,
  hasStudyUpdatePermission,
  hasStudyUploadPermission,
  isGuest,
} from "./auth/authorization";
import { currentTenant, currentUser } from "./auth/current-session";
import { getBrowserName, isBrowserSupported } from "./browser-check";
import Branding from "./components/Branding.vue";
import ContactSupportModal from "./components/ContactSupportModal.vue";
import DownloadPopper from "./components/DownloadPopper.vue";
import GlobalAdminInitiatedBanner from "./components/GlobalAdminInitiatedBanner.vue";
import ImportStudies from "./components/ImportStudies.vue";
import Modal from "./components/Modal.vue";
import NotForClinicalUseBanner from "./components/NotForClinicalUseBanner.vue";
import NotificationList from "./components/NotificationList.vue";
import Tooltip from "./components/Tooltip.vue";
import UploadFiles from "./components/UploadFiles.vue";
import UploadJobsDropdown from "./components/UploadJobsDropdown.vue";
import Chevron from "./components/ui/chevron/Chevron.vue";
import { IS_DEV_MODE } from "./environment";
import OnboardingModal from "./onboarding/OnboardingModal.vue";
import router from "./router";
import { useDownloadStore } from "./state/stores/study-download.store";
import { uploadJobStore } from "./state/stores/upload-job.store";
import { onPrimaryWindowMessageReceived } from "./study-view/multi-window/primary-window-messages";
import { WindowType, getWindowType } from "./study-view/multi-window/secondary-window";
import { postMessageToSecondaryWindow } from "./study-view/multi-window/secondary-window-messages";

interface Props {
  studyId?: string;
}

defineProps<Props>();

const { cancelInProgressUploadJobs, getActiveUploadJobCount } = uploadJobStore();
const { uploadJobs } = storeToRefs(uploadJobStore());

const downloadStore = useDownloadStore();
const isAuthPageVisible = computed(
  () =>
    router.currentRoute.value.name === "sign-in" ||
    router.currentRoute.value.name === "accept-invitation" ||
    router.currentRoute.value.name === "passcode-prompt" ||
    router.currentRoute.value.name === "reset-password"
);

const isMenuDropdownOpen = ref(false);

const { isOnline } = useNetwork();

const isContactSupportModalVisible = ref(false);

if (!isBrowserSupported()) {
  alert(
    [
      `Your browser ${getBrowserName()} is unsupported.`,
      "Some parts of the application may not work correctly.",
      "It is recommended to use a recent version of Chrome, Firefox, Safari, or Edge.",
    ].join("\n\n")
  );
}

async function onSignOutClick(): Promise<void> {
  if (getActiveUploadJobCount() !== 0) {
    if (!confirm("There are uploads in progress. Are you sure you want to sign out?")) {
      return;
    }

    await cancelInProgressUploadJobs();
  }

  await signOut();
}

//
// Secondary window handling
//

if (getWindowType() === WindowType.Primary) {
  // Close any secondary windows when the main window is closed or refreshed
  window.onbeforeunload = (): void => {
    postMessageToSecondaryWindow({ type: "close-window" });
  };

  // Action any keyboard shortcuts forwarded through from the secondary window
  onPrimaryWindowMessageReceived("secondary-window-key-pressed", (message) =>
    window.dispatchEvent(new KeyboardEvent("keydown", { key: message.key }))
  );
}
</script>

<style scoped lang="scss">
.header {
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--bg-color-3);
  border-bottom: 1px solid var(--border-color-1);

  &.on-auth-page {
    z-index: 2;
    background: none;
    border-bottom: none;
  }
}

.dropdown-button {
  max-width: 50vw;

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.text-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(.dropdown-menu-container) {
  background-color: var(--bg-color-4);
  border-radius: var(--border-radius) 0 var(--border-radius) var(--border-radius);
  padding: 4px 8px;

  // Disable fade-in
  .fade-enter-active {
    transition: opacity 0s ease;
  }
}

.dropdown-menu {
  min-width: 180px;
  font-weight: bold;
  display: flex;
  flex-direction: column;

  .divider {
    align-self: stretch;
    height: 1px;
    background: var(--accent-color-1);
    margin: 6px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    height: 32px;
    margin-left: 8px;
    gap: 8px;
    cursor: pointer;
    line-height: 1em;

    > :first-child {
      flex: 0 0 30px;
    }

    a.router-link-active {
      color: var(--accent-color-2);
    }
  }
}

.user-details-item {
  padding: 8px 16px 6px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.user-name-and-email {
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-width: 50vw;

  > :nth-child(2) {
    font-weight: normal;
    color: #ccc;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.network-connection-lost {
  display: grid;
  place-content: center;
  padding: 20px;
}

.view-only {
  margin-left: 8px;
  border-radius: var(--border-radius);
  line-height: 1em;
  width: 140px;
  height: 32px;
  font-weight: bold;
  background-color: var(--bg-color-4);
  display: grid;
  place-content: center;
}
</style>
