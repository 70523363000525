<template>
  <div class="settings">
    <div data-testid="settings-menu" class="settings-menu">
      <h2>Settings</h2>

      <div class="menu-item" @click="onMyAccountMenuClick">
        <FontAwesomeIcon class="menu-icon" icon="user" />
        My Account
      </div>

      <template v-if="isMyAccountMenuOpen">
        <RouterLink to="/settings/user/personal" class="sub-menu-item"> Personal </RouterLink>
        <RouterLink
          v-if="
            hasStudyReportPreliminaryFinalizePermission || hasStudyReportAmendmentCompletePermission
          "
          to="/settings/user/pin"
          class="sub-menu-item"
          data-testid="settings-user-pin-link"
        >
          PIN
        </RouterLink>
        <RouterLink
          v-if="hasStudyReportPreliminaryFinalizePermission"
          to="/settings/user/signature"
          class="sub-menu-item"
        >
          Signature
        </RouterLink>
        <RouterLink
          v-if="currentTenant.isApiKeyAccessEnabled && currentUser.isApiKeyAccessEnabled"
          to="/settings/user/api-keys"
          class="sub-menu-item"
        >
          API Keys
        </RouterLink>
        <RouterLink to="/settings/user/security" class="sub-menu-item"> Security </RouterLink>
        <RouterLink to="/settings/user/sessions" class="sub-menu-item"> Sessions </RouterLink>
        <RouterLink to="/settings/user/defaults" class="sub-menu-item"> User Defaults </RouterLink>
      </template>

      <template v-if="hasReportTemplateManagePermission">
        <div class="menu-item" data-testid="settings-reporting" @click="onReportMenuClick">
          <FontAwesomeIcon class="menu-icon" icon="book-medical" />
          Reporting
        </div>

        <template v-if="isReportingMenuOpen">
          <RouterLink to="/settings/reporting/templates" class="sub-menu-item">
            Templates
          </RouterLink>
          <RouterLink to="/settings/reporting/sentence-library" class="sub-menu-item">
            Sentence Library
          </RouterLink>
          <RouterLink to="/settings/reporting/calculations" class="sub-menu-item">
            Calculations
          </RouterLink>
          <RouterLink
            v-if="hasTenantManagePermission"
            to="/settings/reporting/pdf"
            class="sub-menu-item"
          >
            PDF Sending
          </RouterLink>
        </template>
      </template>

      <template
        v-if="
          hasTenantManagePermission ||
          hasMeasurementSequenceManagePermission ||
          hasMeasurementCalculationManagePermission
        "
      >
        <div class="menu-item" @click="onMeasurementsMenuClick">
          <FontAwesomeIcon class="menu-icon" icon="ruler" />
          Measurements
        </div>

        <template v-if="isMeasurementsMenuOpen">
          <RouterLink
            v-if="hasTenantManagePermission"
            to="/settings/measurements/tools"
            class="sub-menu-item"
          >
            Tools
          </RouterLink>
          <RouterLink
            v-if="hasMeasurementSequenceManagePermission"
            to="/settings/measurements/sequences"
            class="sub-menu-item"
          >
            Sequences
          </RouterLink>
          <RouterLink
            v-if="hasMeasurementCalculationManagePermission"
            to="/settings/measurements/calculations"
            class="sub-menu-item"
          >
            Calculations
          </RouterLink>
        </template>
      </template>

      <template
        v-if="
          hasDicomClientIdentityManagePermission ||
          hasHeartBoxIntegrationManagePermission ||
          hasHL7IntegrationManagePermission ||
          hasSftpIntegrationManagePermission ||
          hasWebhookIntegrationManagePermission
        "
      >
        <div class="menu-item" data-testid="settings-integrations" @click="onIntegrationsMenuClick">
          <FontAwesomeIcon class="menu-icon" icon="plug" />
          Integrations
        </div>

        <template v-if="isIntegrationsMenuOpen">
          <RouterLink
            v-if="hasApiKeyIntegrationManagePermission"
            to="/settings/integrations/api-key"
            class="sub-menu-item"
          >
            API Keys
          </RouterLink>
          <RouterLink
            v-if="hasAgentIntegrationManagePermission"
            to="/settings/integrations/agent"
            class="sub-menu-item"
          >
            Agent
          </RouterLink>
          <RouterLink
            v-if="hasDicomClientIdentityManagePermission"
            to="/settings/integrations/dicom"
            class="sub-menu-item"
          >
            DICOM
          </RouterLink>
          <RouterLink
            v-if="hasHeartBoxIntegrationManagePermission"
            to="/settings/integrations/heartbox"
            class="sub-menu-item"
          >
            HeartBox
          </RouterLink>
          <RouterLink
            v-if="hasHL7IntegrationManagePermission"
            to="/settings/integrations/hl7"
            class="sub-menu-item"
          >
            HL7
          </RouterLink>
          <RouterLink
            v-if="hasSftpIntegrationManagePermission"
            to="/settings/integrations/sftp"
            class="sub-menu-item"
          >
            SFTP
          </RouterLink>
          <RouterLink
            v-if="hasWebhookIntegrationManagePermission"
            to="/settings/integrations/webhook"
            class="sub-menu-item"
          >
            Webhooks
          </RouterLink>
        </template>
      </template>

      <RouterLink v-if="hasTenantManagePermission" to="/settings/study-list" class="menu-item">
        <FontAwesomeIcon class="menu-icon" icon="table-list" />
        Study List
      </RouterLink>

      <RouterLink
        v-if="hasDicomScheduledProcedureStepManagePermission"
        to="/settings/worklists"
        class="menu-item"
      >
        <FontAwesomeIcon class="menu-icon" icon="list-ul" />
        Worklists
      </RouterLink>

      <template
        v-if="hasUserViewPermission || hasUserRoleManagePermission || hasUserRoleViewPermission"
      >
        <div class="menu-item" data-testid="settings-access" @click="onAccessMenuClick">
          <FontAwesomeIcon class="menu-icon" icon="users-gear" />
          Users & Roles
        </div>

        <template v-if="isAccessMenuOpen">
          <RouterLink
            v-if="hasUserViewPermission"
            to="/settings/access/users/all"
            class="sub-menu-item"
          >
            Users
          </RouterLink>
          <RouterLink
            v-if="hasUserRoleViewPermission || hasUserRoleManagePermission"
            class="sub-menu-item"
            data-testid="settings-access-roles"
            to="/settings/access/roles"
          >
            Roles
          </RouterLink>
        </template>
      </template>

      <RouterLink v-if="hasChartingViewPermission" to="/settings/activity" class="menu-item">
        <FontAwesomeIcon class="menu-icon" icon="chart-line" />
        Activity
      </RouterLink>

      <RouterLink v-if="hasTenantManagePermission" to="/settings/advanced" class="menu-item">
        <FontAwesomeIcon class="menu-icon" icon="screwdriver-wrench" />
        Advanced
      </RouterLink>

      <template v-if="currentTenant.isGlobalAdmin">
        <div class="global-admin-separator" />
        <h2 v-if="hasAnyAdminPermission">Global Admin</h2>
        <RouterLink
          v-if="hasAdminOrganizationViewPermission"
          to="/settings/global-admin/organizations"
          class="menu-item"
        >
          <FontAwesomeIcon class="menu-icon" icon="sitemap" />
          Organizations
        </RouterLink>
        <div
          v-if="hasAdminRoleManagePermission"
          class="menu-item"
          data-testid="settings-access"
          @click="onAdminUsersAndRolesMenuClick"
        >
          <FontAwesomeIcon class="menu-icon" icon="users-gear" />
          Users & Roles
        </div>
        <template v-if="isAdminUsersAndRolesMenuOpen">
          <RouterLink to="/settings/global-admin/users/all" class="sub-menu-item">
            Users
          </RouterLink>
          <RouterLink
            class="sub-menu-item"
            data-testid="settings-global-admin-roles"
            to="/settings/global-admin/roles"
          >
            Roles
          </RouterLink>
        </template>

        <RouterLink
          v-if="hasAdminTenantViewPermission"
          to="/settings/global-admin/tenants"
          class="menu-item"
        >
          <FontAwesomeIcon class="menu-icon" icon="heartbeat" />
          Tenants
        </RouterLink>

        <RouterLink
          v-if="hasAdminBillingViewPermission"
          to="/settings/global-admin/billing"
          class="menu-item"
        >
          <FontAwesomeIcon class="menu-icon" icon="file-lines" />
          Billing
        </RouterLink>

        <RouterLink
          v-if="hasAdminDicomEndpointManagePermission"
          to="/settings/global-admin/dicom-endpoints"
          class="menu-item"
        >
          <FontAwesomeIcon class="menu-icon" icon="network-wired" />
          DICOM Endpoints
        </RouterLink>
      </template>
    </div>

    <div class="settings-content">
      <RouterView />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import {
  hasAdminBillingViewPermission,
  hasAdminDicomEndpointManagePermission,
  hasAdminOrganizationViewPermission,
  hasAdminRoleManagePermission,
  hasAdminTenantViewPermission,
  hasAgentIntegrationManagePermission,
  hasAnyAdminPermission,
  hasApiKeyIntegrationManagePermission,
  hasChartingViewPermission,
  hasDicomClientIdentityManagePermission,
  hasDicomScheduledProcedureStepManagePermission,
  hasHL7IntegrationManagePermission,
  hasHeartBoxIntegrationManagePermission,
  hasMeasurementCalculationManagePermission,
  hasMeasurementSequenceManagePermission,
  hasReportTemplateManagePermission,
  hasSftpIntegrationManagePermission,
  hasStudyReportAmendmentCompletePermission,
  hasStudyReportPreliminaryFinalizePermission,
  hasTenantManagePermission,
  hasUserRoleManagePermission,
  hasUserRoleViewPermission,
  hasUserViewPermission,
  hasWebhookIntegrationManagePermission,
} from "../auth/authorization";
import { currentTenant, currentUser } from "../auth/current-session";
import router from "../router";

const isUnderMyAccountMenu = computed(() =>
  router.currentRoute.value.fullPath.startsWith("/settings/user/")
);

const isUnderIntegrationsMenu = computed(() =>
  router.currentRoute.value.fullPath.startsWith("/settings/integrations/")
);

const isUnderReportingMenu = computed(() =>
  router.currentRoute.value.fullPath.startsWith("/settings/reporting/")
);

const isUnderMeasurementsMenu = computed(() =>
  router.currentRoute.value.fullPath.startsWith("/settings/measurements/")
);

const isUnderAccessMenu = computed(() =>
  router.currentRoute.value.fullPath.startsWith("/settings/access/")
);

const isUnderAdminUsersAndRolesMenu = computed(() => {
  return (
    router.currentRoute.value.fullPath.startsWith("/settings/global-admin/users") ||
    router.currentRoute.value.fullPath.startsWith("/settings/global-admin/roles")
  );
});

const isMyAccountMenuOpen = ref(isUnderMyAccountMenu.value);
const isIntegrationsMenuOpen = ref(isUnderIntegrationsMenu.value);
const isReportingMenuOpen = ref(isUnderReportingMenu.value);
const isMeasurementsMenuOpen = ref(isUnderMeasurementsMenu.value);
const isAccessMenuOpen = ref(isUnderAccessMenu.value);
const isAdminUsersAndRolesMenuOpen = ref(isUnderAdminUsersAndRolesMenu.value);

function onMyAccountMenuClick(): void {
  if (!isUnderMyAccountMenu.value) {
    isMyAccountMenuOpen.value = !isMyAccountMenuOpen.value;
  }
}

function onIntegrationsMenuClick(): void {
  if (!isUnderIntegrationsMenu.value) {
    isIntegrationsMenuOpen.value = !isIntegrationsMenuOpen.value;
  }
}

function onReportMenuClick(): void {
  if (!isUnderReportingMenu.value) {
    isReportingMenuOpen.value = !isReportingMenuOpen.value;
  }
}

function onMeasurementsMenuClick(): void {
  if (!isUnderMeasurementsMenu.value) {
    isMeasurementsMenuOpen.value = !isMeasurementsMenuOpen.value;
  }
}

function onAccessMenuClick(): void {
  if (!isUnderAccessMenu.value) {
    isAccessMenuOpen.value = !isAccessMenuOpen.value;
  }
}

function onAdminUsersAndRolesMenuClick(): void {
  if (!isUnderAdminUsersAndRolesMenu.value) {
    isAdminUsersAndRolesMenuOpen.value = !isAdminUsersAndRolesMenuOpen.value;
  }
}
</script>

<style scoped lang="scss">
.settings {
  display: flex;
  flex: 1;
  min-height: 0;
}

.settings-menu {
  background-color: var(--bg-color-2);
  border-right: 1px solid var(--border-color-1);
  flex: 0 0 200px;
  padding: 0 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;

  h2 {
    margin-left: 16px;
  }
}

.menu-item,
.sub-menu-item {
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  border-radius: var(--border-radius);

  &.router-link-active {
    color: var(--text-color-2);
    background-color: var(--bg-color-4);
  }
}

.menu-item {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 16px;

  font-size: 0.9rem;
  font-weight: bold;

  &:hover {
    color: var(--text-color-2);
  }

  .menu-icon {
    width: 18px;
    justify-self: center;
  }

  :deep(svg:nth-child(2)) {
    margin-left: auto;
  }
}

.sub-menu-item {
  padding: 4px 12px 4px 60px;

  &.router-link-active {
    background-color: var(--bg-color-4);
    font-weight: bold;
  }
}

.settings-content {
  --settings-content-vertical-padding: 24px;

  background-color: var(--bg-color-1);
  overflow-y: auto;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: var(--settings-content-vertical-padding) 36px;

  position: relative;
}

:deep(.settings-title) {
  padding-bottom: 22px;
  border-bottom: 1px solid var(--border-color-1);

  font-weight: bold;
  font-size: 20px;

  display: flex;
  align-items: flex-end;
  gap: 40px;
}

:deep(.settings-group-title) {
  font-size: 1.3em;
  font-weight: bold;
  padding-top: 12px;
  display: flex;
}

:deep(p) {
  max-width: 500px;
  margin: 0 0 4px;
}

.global-admin-separator {
  min-height: 1px;
  margin: 16px 0;
  background: var(--border-color-1);
}
</style>
