import axios, { isAxiosError, type AxiosResponse } from "axios";
import { reactive } from "vue";
import { HL7MessageFormat } from "../../../backend/src/integrations/hl7/hl7-message-format";
import { PatientNameFormat } from "../../../backend/src/tenants/patient-name-format";
import type { CurrentUserGetResponseDto } from "../../../backend/src/user/dto/current-user-get.dto";

//
// Current tenant and user
//

type CurrentTenant = Omit<CurrentUserGetResponseDto["tenant"], "isApiKeyAccessEnabled"> & {
  isApiKeyAccessEnabled: boolean;
};

type CurrentUser = CurrentUserGetResponseDto["user"];

export const currentTenant = reactive<CurrentTenant>(getBlankCurrentTenant());
export const currentUser = reactive<CurrentUser>(getBlankCurrentUser());

function getBlankCurrentTenant(): CurrentTenant {
  return {
    id: "",
    name: "",
    awsRegion: "",
    isApiKeyAccessEnabled: false,
    isStudySharingEnabled: true,
    isEmailSendingEnabled: false,
    isNotForClinicalUseBannerVisible: false,
    patientIdLabel: "",
    traineeLabel: "",
    technicianLabel: "",
    physicianLabel: "",
    traineeRoleId: null,
    technicianRoleId: null,
    physicianRoleId: null,
    studyListHiddenColumns: [],
    ethnicityOptions: [],
    patientNameFormat: PatientNameFormat.FirstNameMiddleNameLastName,
    isAreaMeasurementAutomaticResamplingEnabled: false,
    isVolumeMeasurementAutomaticResamplingEnabled: false,
    isVTIMeasurementAutomaticResamplingEnabled: false,
    isStudyAssigneeRequired: false,
    inAppDownloadReportFilenameTemplate: "",
    sftpSendingReportFilenameTemplate: "",
    agentDownloadReportFilenameTemplate: "",
    defaultStudyAssignedUserId: null,
    hasDicomEndpoints: false,
    isAmendmentReasonRequired: true,
    roles: [],
    sessionMaximumAge: 12 * 60 * 60,
    sessionInactivityExpirationTime: 60 * 10,
    hl7MessageFormat: HL7MessageFormat.NewZealand,
    isDicomVerboseLoggingEnabled: false,
    isHighPrioritySupportCaseCreationAllowed: false,
    isExternallyCreatedMeasurementValueSelectedByDefault: false,
    isStudyAssigneeClearedWhenFinalReportCompleted: false,
    isDictationPermitted: false,
    isDeidentificationPermitted: false,
    isCTModePermitted: false,
  };
}

function getBlankCurrentUser(): CurrentUser {
  return {
    id: "",
    hasPin: false,
    email: "",
    name: "",
    signatureDataUri: "",
    mfaEnabled: false,
    signatureText: "",
    signatureLogoDataUri: "",
    roleIds: [],
    roleNames: [],
    onboarded: true,
    onlyAssignedStudies: false,
    isDictationEnabled: false,
    isSpokenPunctuationEnabled: false,
    globalAdminRolePermissions: undefined,
    createdByGlobalAdminLogin: false,
  };
}

export async function fetchCurrentTenantAndUser(): Promise<number> {
  let response: AxiosResponse<CurrentUserGetResponseDto> | undefined = undefined;
  try {
    response = await axios.get<CurrentUserGetResponseDto>("/api/user/current");
  } catch (error: unknown) {
    // A 401 Unauthenticated response code is returned as the final result. Other error codes result
    // in a retry.
    if (isAxiosError(error) && error.response !== undefined && error.response.status === 401) {
      return error.response.status;
    }

    // Wait a second before retrying
    await new Promise((resolve) => setTimeout(resolve, 1000));

    return fetchCurrentTenantAndUser();
  }

  // Assign value to current user and tenant
  Object.assign(currentUser, response.data.user);
  Object.assign(currentTenant, {
    ...response.data.tenant,
    isGlobalAdmin: response.data.tenant.isGlobalAdmin ?? false,
  });

  return response.status;
}

export function clearCurrentTenantAndUser(): void {
  Object.assign(currentTenant, getBlankCurrentTenant());
  Object.assign(currentUser, getBlankCurrentUser());
}
