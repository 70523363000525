<template>
  <div class="study-view">
    <StudyViewTopToolbar
      :study="study"
      :patient-studies="patientStudies"
      :is-report-pane-visible="isReportPaneVisible"
      @open-comparison="(studyId) => (comparedStudyId = studyId)"
      @toggle-report="onToggleReporting"
      @create-report="onCreateReport"
    />

    <div class="main-content">
      <div class="content-except-report" :style="{ width: `${100 - reportPaneWidth}%` }">
        <PatientInfo
          :study="study"
          :is-report-open="isReportPaneVisible"
          @update="debouncedPatientInfoUpdate"
        />

        <div style="display: flex; height: 100%; min-height: 0">
          <div
            class="measurement-pane-container"
            :class="{ 'right-border': isMeasurementPaneVisible }"
          >
            <MeasurementPane
              v-if="isMeasurementPaneVisible"
              v-model:searchTerm="measurementPaneSearchTerm"
              :study="study"
              :reference-range-set-name="referenceRangeSetName"
              :patient-metrics="patientMetrics"
              :selected-clip-ids="selectedClipIds"
              :measurement-ids-to-expand="measurementPaneMeasurementIdsToExpand"
              :measurement-id-to-scroll-to="measurementIdToScrollTo"
              :highlighted-measurement-id="highlightedMeasurementId"
              :hovered-measurement-value-id="hoveredMeasurementValueId"
              :clips-grid-items="regularClipsGridItems"
              @jump-to-measurement-value="onJumpToMeasurementValue"
              @scroll-to-measurement="scrollMeasurementPaneToMeasurement($event, true)"
              @highlight-measurement-card="onHighlightMeasurementCard"
            />
          </div>

          <div
            v-if="isStressModeEnabled"
            class="stress-mode-container"
            data-testid="stress-container"
          >
            <StressModeHeader
              v-model:stress-mode-layout="stressModeLayout"
              v-model:is-clip-sync-enabled="isClipSyncEnabled"
              v-model:stress-mode-selected-view="stressModeSelectedView"
              v-model:stress-mode-show-all-clips-in-by-view="stressModeShowAllClipsInByView"
              :study="study"
              @disable-stress-mode="isStressModeEnabled = false"
            />

            <StressLayoutTable
              v-if="stressModeLayout === 'table'"
              v-model:focused-clip-index="focusedClipIndex"
              v-model:is-clip-sync-enabled="isClipSyncEnabled"
              v-model:show-measurements="showMeasurements"
              :study="study"
              :clips-grid-items="clipsGridItems"
              :selected-clip-ids="selectedClipIds"
              @update:is-stress-mode-enabled="isStressModeEnabled = $event"
              @scroll-to-measurement="scrollMeasurementPaneToMeasurement"
            />

            <StressLayoutSingleView
              v-else
              v-model:focused-clip-index="focusedClipIndex"
              v-model:is-clip-sync-enabled="isClipSyncEnabled"
              v-model:stress-mode-selected-view="stressModeSelectedView"
              v-model:show-measurements="showMeasurements"
              :study="study"
              :clips-grid-items="clipsGridItems"
              :selected-clip-ids="selectedClipIds"
              :stress-mode-show-all-clips-in-by-view="stressModeShowAllClipsInByView"
              @update:is-stress-mode-enabled="isStressModeEnabled = $event"
              @scroll-to-measurement="scrollMeasurementPaneToMeasurement"
            />
          </div>

          <ClipsAreaDefault
            v-else
            v-model:focused-clip-index="focusedClipIndex"
            v-model:is-clip-sync-enabled="isClipSyncEnabled"
            v-model:is-stress-mode-enabled="isStressModeEnabled"
            v-model:show-measurements="showMeasurements"
            style="grid-area: clips-area"
            :study="study"
            :clips-grid-items="clipsGridItems"
            :selected-clip-ids="selectedClipIds"
            :visible-measurement-values-with-contours-count="
              visibleMeasurementValuesWithContours.length
            "
            @open-measurement-pane="isMeasurementPaneVisible = true"
            @scroll-to-measurement="scrollMeasurementPaneToMeasurement"
            @scroll-clip-list-to-clip="scrollClipListToClip"
            @measurement-values-show-next="onShowNextMeasurementValues"
            @measurement-values-show-previous="onShowPreviousMeasurementValues"
            @highlight-measurement-card="onHighlightMeasurementCard"
            @measurement-value-hovered="hoveredMeasurementValueId = $event"
            @update:clip-color-map="onChangeColorMap"
          />

          <div
            v-if="!isStressModeEnabled"
            class="clip-list-container"
            :style="{ width: `${clipListWidth}px` }"
          >
            <ClipList
              :study="study"
              :selected-clip-ids="selectedClipIds"
              :scroll-target="clipListScrollTarget"
              @clip-thumbnail-click="onClipThumbnailClicked"
              @measurement-icon-click="
                (measurementValue) => {
                  scrollMeasurementPaneToMeasurement(measurementValue.measurementId, false);
                  onJumpToMeasurementValue(measurementValue);
                }
              "
              @clip-deleted="onStudyClipDeleted"
            />
            <DragMove
              class="vertical-resize-handle"
              @start="onClipListResizeStart"
              @move="onClipListResize"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isReportPaneVisible && study.reports.length > 0"
        ref="reportContainerElement"
        class="report-container"
        :style="{ width: `${reportPaneWidth}%` }"
      >
        <ReportPane
          :study="study"
          @update-patient-info="debouncedPatientInfoUpdate"
          @report-create="onCreateReport"
          @report-latest-deleted="onLatestReportDeleted"
        />

        <DragMove
          class="vertical-resize-handle"
          :class="{ resizing: isReportResizing }"
          @start="onResizeReportStart"
          @move="onResizeReport"
          @end="onResizeReportEnd"
        />
      </div>
    </div>

    <Transition name="fade">
      <div v-if="!isLoaded" class="study-loading-indicator" data-testid="study-loading-indicator">
        <LoadingIndicator size="2x" />
        Loading study
      </div>
    </Transition>

    <StudyComparisonModal
      v-if="comparedStudyId !== null"
      :patient-name="study.patientName"
      :patient-studies="patientStudies"
      :initial-left-study-id="comparedStudyId"
      :initial-right-study-id="study.id"
      @close="comparedStudyId = null"
    />
  </div>
</template>

<script setup lang="ts">
import { activeMeasurementCalculation } from "@/measurements/calculations/measurement-calculations";
import { changeBatchMeasurements } from "@/measurements/measurement-save-helpers";
import { getMatchingMeasurements } from "@/measurements/measurement-search";
import {
  activeMeasurement,
  restartMeasuring,
  stopMeasuring,
} from "@/measurements/measurement-tool-state";
import { MeasurementToolBatchChangeRequest } from "@/measurements/tools/measurement-tool";
import { ReferenceRangeSetName } from "@/reference-ranges/reference-range-sets";
import { useStudyListStore } from "@/state/stores/study-list";
import { createAuditLogEvent } from "@/utils/audit-log-utils";
import { ColorMap } from "@/utils/color-map";
import { onKeyboardShortcut } from "@/utils/keyboard-shortcut";
import { addNotification } from "@/utils/notifications";
import { usePatientStudies } from "@/utils/patient-studies";
import {
  getEmptyStudy,
  getLatestReport,
  type StudyClip,
  type StudyMeasurementValue,
} from "@/utils/study-data";
import { useQueryClient } from "@tanstack/vue-query";
import { useDebounceFn, useStorage } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import {
  WatchStopHandle,
  computed,
  nextTick,
  onBeforeUnmount,
  onUnmounted,
  provide,
  ref,
  watch,
  watchEffect,
} from "vue";
import { AuditAction } from "../../../backend/src/audit/audit-actions";
import type { BaseExceptionDto } from "../../../backend/src/exceptions/dtos/base-exception.dto";
import {
  getMeasurementDisplayName,
  getPatientMetrics,
} from "../../../backend/src/measurements/measurement-display";
import { PatientSex } from "../../../backend/src/patients/patient-sex";
import { clamp } from "../../../backend/src/shared/math-utils";
import {
  StudyGetOneResponseDto,
  StudyReportGetOneResponseDto,
} from "../../../backend/src/studies/dto/study-get-one.dto";
import { getClips } from "../../../backend/src/studies/study-helpers";
import DragMove from "../components/DragMove.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import MeasurementPane from "../measurements/measurement-pane/MeasurementPane.vue";
import ReportPane from "../reporting/ReportPane.vue";
import router from "../router";
import { useStudyViewImageData } from "../state/stores/study-image.store";
import ClipList from "./ClipList.vue";
import ClipsAreaDefault from "./ClipsAreaDefault.vue";
import PatientInfo from "./PatientInfo.vue";
import StudyViewTopToolbar from "./StudyViewTopToolbar.vue";
import { MAX_SELECTED_CLIPS } from "./clip-model";
import {
  ClipsGridItemType,
  createClipsGridItemsArray,
  createNullClipsGridItem,
  getRegularClipsGridItems,
  updateClipsGridItemsArrayFromSelectedClips,
} from "./clip-viewer/clips-grid-item";
import StudyComparisonModal from "./comparison/StudyComparisonModal.vue";
import { onPrimaryWindowMessageReceived } from "./multi-window/primary-window-messages";
import {
  SecondaryWindowState,
  WindowType,
  getSecondaryWindowState,
  getSecondaryWindowType,
  getWindowType,
} from "./multi-window/secondary-window";
import { postMessageToSecondaryWindow } from "./multi-window/secondary-window-messages";
import StressLayoutSingleView from "./stress-mode/StressLayoutSingleView.vue";
import StressLayoutTable from "./stress-mode/StressLayoutTable.vue";
import StressModeHeader from "./stress-mode/StressModeHeader.vue";
import { getStressViewNames, isStressClip } from "./study-clip-helpers";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const isLoaded = ref(false);
const isStudyLoadingIndicatorVisible = ref(false);

const queryClient = useQueryClient();

const study = ref(getEmptyStudy());
const patientMetrics = computed(() => getPatientMetrics(study.value));
const isClipSyncEnabled = ref(false);
const comparedStudyId = ref<string | null>(null);

async function loadStudy(): Promise<void> {
  study.value = getEmptyStudy();
  isLoaded.value = false;
  isStudyLoadingIndicatorVisible.value = true;

  let response: AxiosResponse<StudyGetOneResponseDto | BaseExceptionDto> | undefined = undefined;
  try {
    response = await axios.get<StudyGetOneResponseDto | BaseExceptionDto>(
      `/api/studies/${props.id}`,
      {
        validateStatus: (status) => status === 200,
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 202) {
        const errorData = error.response.data as BaseExceptionDto;
        addNotification({ type: "error", message: errorData.message });
      } else if (error.response?.status === 403) {
        addNotification({ type: "error", message: "Unauthorized access to study" });
      } else {
        addNotification({ type: "error", message: "Failed loading study" });
      }
    }

    await router.push({ name: "study-list" });

    return;
  }

  study.value = response.data as StudyGetOneResponseDto;
  isLoaded.value = true;

  // Wait 200ms to confirm the loading indicator has fully faded out
  setTimeout(() => {
    isStudyLoadingIndicatorVisible.value = false;
  }, 200);

  // Reset the measurement tool state
  stopMeasuring();
  activeMeasurementCalculation.value = null;

  // Automatically close the measurements pane if this study has no measurements
  if (study.value.measurements.length === 0) {
    isMeasurementPaneVisible.value = false;
  }

  // For ultrasound studies, if more than half of clips are stress clips then automatically enable
  // stress mode
  if (study.value.modality === "US") {
    const clips = getClips(study.value);
    const isStressStudy = clips.filter(isStressClip).length > clips.length * 0.5;

    isStressModeEnabled.value = isStressStudy;
    isClipSyncEnabled.value = isStressStudy;
  }
}

provide("study-loading-indicator-visible", isStudyLoadingIndicatorVisible);

// Load study data when study id changes
watch(() => props.id, loadStudy, { immediate: true });

//
// Stress mode
//

const isStressModeEnabled = ref(false);
const stressModeLayout = useStorage<"byView" | "table">("stress-mode-layout", "table");
const stressModeSelectedView = ref("");
const stressModeShowAllClipsInByView = useStorage("stress-mode-show-all-clips-in-by-view", false);

// Keep the selected view for stress mode valid when the study changes
const viewNames = computed(() => getStressViewNames(study.value));
watch(
  viewNames,
  () => {
    if (viewNames.value.includes(stressModeSelectedView.value)) {
      return;
    }

    stressModeSelectedView.value = viewNames.value[0] ?? "";
  },
  { immediate: true }
);

watch(isStressModeEnabled, () => {
  // Create audit log events for stress mode being enabled and disabled
  const action = isStressModeEnabled.value
    ? AuditAction.StressModeOpened
    : AuditAction.StressModeClosed;

  void createAuditLogEvent({ action, studyId: props.id });

  // Empty the grid items array when we open stress mode as we need to ensure we don't have any
  // details from the previous view being re-used as creation of a new grid item is skipped if
  // there's already one for the same clip
  for (let i = 0; i < clipsGridItems.length; i++) {
    clipsGridItems[i] = createNullClipsGridItem();
  }
});

//
// Secondary window interactions
//

if (getWindowType() === WindowType.Primary) {
  // When there's a secondary window, a deep watcher is added to the whole study that sends changes
  // to the study through to the secondary window so that it stays up to date with this primary
  // window
  let watchStopHandle: WatchStopHandle | undefined = undefined;
  function createSecondaryWindowUpdateWatcher(): void {
    watchStopHandle?.();

    watchStopHandle = watch(
      study,
      () =>
        postMessageToSecondaryWindow({ type: "set-study", studyJson: JSON.stringify(study.value) }),
      { deep: true, immediate: true }
    );
  }

  if (getSecondaryWindowState() === SecondaryWindowState.Open) {
    createSecondaryWindowUpdateWatcher();
  }

  onPrimaryWindowMessageReceived("secondary-window-opened", createSecondaryWindowUpdateWatcher);
  onPrimaryWindowMessageReceived("secondary-window-closed", () => watchStopHandle?.());

  onPrimaryWindowMessageReceived("scroll-and-highlight-measurement", (message) => {
    void (async () => {
      await scrollMeasurementPaneToMeasurement(message.measurementId, true);
      await onHighlightMeasurementCard(message.measurementId);
    })();
  });

  // Unload the study in the secondary window when this study view unmounts
  onBeforeUnmount(() =>
    postMessageToSecondaryWindow({ type: "set-study", studyJson: JSON.stringify(getEmptyStudy()) })
  );

  // Action any measurement creation requests coming from the secondary window
  onPrimaryWindowMessageReceived(
    "change-measurements",
    (message) =>
      void changeBatchMeasurements(
        study.value,
        JSON.parse(message.measurementsJson) as MeasurementToolBatchChangeRequest
      )
  );

  // Inform the secondary window about any changes to the stress mode and clip sync settings
  function setSecondaryWindowStressModeEnabled(): void {
    postMessageToSecondaryWindow({
      type: "set-stress-mode-enabled",
      isStressModeEnabled: isStressModeEnabled.value,
    });
  }
  watch(isStressModeEnabled, setSecondaryWindowStressModeEnabled);

  function setSecondaryWindowClipSyncEnabled(): void {
    postMessageToSecondaryWindow({
      type: "set-clip-sync-enabled",
      isClipSyncEnabled: isClipSyncEnabled.value,
    });
  }
  watch(isClipSyncEnabled, setSecondaryWindowClipSyncEnabled);

  function updateSecondaryWindow() {
    setSecondaryWindowStressModeEnabled();
    setSecondaryWindowClipSyncEnabled();
  }

  updateSecondaryWindow();

  // Set initial state on new secondary windows when they open
  onPrimaryWindowMessageReceived("secondary-window-opened", updateSecondaryWindow);

  // Handle requests for stress mode toggling from the secondary window
  onPrimaryWindowMessageReceived("set-stress-mode-enabled", (message) => {
    isStressModeEnabled.value = message.isStressModeEnabled;
  });
}

const selectedClipIds = ref<string[]>([]);

function onStudyClipDeleted(deletedClip: StudyClip): void {
  // When a clip is deleted in the clip list, remove it from the study
  const seriesIndex = study.value.series.findIndex((s) => s.id === deletedClip.seriesId);
  if (seriesIndex === -1) {
    return;
  }

  const clipIndex = study.value.series[seriesIndex].clips.findIndex(
    (clip) => clip.id === deletedClip.id
  );
  if (clipIndex === -1) {
    return;
  }

  study.value.series[seriesIndex].clips.splice(clipIndex, 1);

  // Remove any measurements related to the deleted clip
  for (const measurements of study.value.measurements) {
    measurements.values = measurements.values.filter(
      (measurementValue) => measurementValue.studyClipId !== deletedClip.id
    );
  }

  // Stop measuring if the clip being measured is deleted
  if (activeMeasurement.value.studyClipId === deletedClip.id) {
    stopMeasuring();
  }

  updateClipsGridItems();
}

//
// Clip models
//

const clipsGridItems = createClipsGridItemsArray(MAX_SELECTED_CLIPS);

const regularClipsGridItems = computed(() => getRegularClipsGridItems(clipsGridItems));

function updateClipsGridItems(): void {
  updateClipsGridItemsArrayFromSelectedClips(
    clipsGridItems,
    study.value,
    selectedClipIds.value,
    isClipSyncEnabled.value
  );
}
watch(selectedClipIds, updateClipsGridItems, { deep: true });

// Destroy grid items on unmount to stop any in-progress image loads
onBeforeUnmount(() => {
  for (const item of clipsGridItems) {
    item.destroy?.();
  }
});

// Index of the last clip clicked on of the currently selected clips
const focusedClipIndex = ref(0);

const measurementIdToScrollTo = ref<string | null>(null);

async function scrollMeasurementPaneToMeasurement(
  measurementId: string,
  openMeasurementPaneIfClosed: boolean
): Promise<void> {
  if (!isMeasurementPaneVisible.value) {
    if (openMeasurementPaneIfClosed) {
      isMeasurementPaneVisible.value = true;
    } else {
      return;
    }
  }

  measurementIdToScrollTo.value = null;
  await nextTick();
  measurementIdToScrollTo.value = measurementId;
}

async function onHighlightMeasurementCard(measurementId: string) {
  highlightedMeasurementId.value = null;
  await nextTick();
  highlightedMeasurementId.value = measurementId;
}

async function onJumpToMeasurementValue(measurementValue: StudyMeasurementValue): Promise<void> {
  const clip = getClips(study.value).find((c) => c.id === measurementValue.studyClipId);
  if (clip === undefined) {
    return;
  }

  // Only clips that have image data can be jumped to. This check prevents attempts to jump to
  // DICOM SR clips that have no image data and so can't be displayed.
  if ((clip.frameCount ?? 0) === 0 || (clip.width ?? 0) === 0 || (clip.height ?? 0) === 0) {
    return;
  }

  stopMeasuring();
  showMeasurements.value = true;

  // Select the clip wherever the focus is
  selectedClipIds.value[focusedClipIndex.value] = clip.id;
  updateClipsGridItems();

  if (measurementValue.frame === null || measurementValue.contour === null) {
    return;
  }

  // Jump to the frame containing the measurement value
  const item = clipsGridItems[selectedClipIds.value.indexOf(clip.id)];
  if (item.type === ClipsGridItemType.RegularClip) {
    item.setCurrentFrame(measurementValue.frame);
    if (item.soloMeasurementValueId.value === measurementValue.id) {
      item.soloMeasurementValueId.value = undefined;
    } else {
      item.soloMeasurementValueId.value = measurementValue.id;
    }
  } else if (item.type === ClipsGridItemType.CTClip && measurementValue.plane !== null) {
    await item.onJumpToPlane(measurementValue.plane);
  } else {
    return;
  }

  // Scroll the clip list to the clip containing the measurement that has been jumped to
  await scrollClipListToClip(clip.id);
}

function onClipThumbnailClicked(clip: StudyClip): void {
  selectedClipIds.value[focusedClipIndex.value] = clip.id;
}

async function onSavePatientInfo(): Promise<void> {
  try {
    await axios.patch(`/api/studies/${props.id}`, {
      indication: study.value.indication,
      patientMedicalHistory: study.value.patientMedicalHistory,
      patientEthnicity: study.value.patientEthnicity,
      patientHeight: study.value.patientHeight,
      patientWeight: study.value.patientWeight,
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving study" });
    return;
  }

  addNotification({ type: "info", message: "Saved study" });
}

const debouncedPatientInfoUpdate = useDebounceFn(() => {
  void onSavePatientInfo();
}, 1000);

const referenceRangeSetName = ref(ReferenceRangeSetName.ASEMale);
watchEffect(() => {
  if (study.value.patientSex === PatientSex.Female) {
    referenceRangeSetName.value = ReferenceRangeSetName.ASEFemale;
  } else {
    referenceRangeSetName.value = ReferenceRangeSetName.ASEMale;
  }
});

// When the selected clips change, restart measuring if the clip previously being measured is no
// longer selected
watch(
  selectedClipIds,
  () => {
    if (
      activeMeasurement.value.studyClipId !== "" &&
      !selectedClipIds.value.includes(activeMeasurement.value.studyClipId)
    ) {
      restartMeasuring({ studyClipId: "", region: undefined });
    }
  },
  { deep: true }
);

const clipListScrollTarget = ref<HTMLElement | null>(null);

async function scrollClipListToClip(studyClipId: string): Promise<void> {
  clipListScrollTarget.value = null;
  await nextTick();
  clipListScrollTarget.value = document.querySelector<HTMLElement>(
    `.clip-thumbnail[data-study-clip-id="${studyClipId}"]`
  );
}

const showMeasurements = ref(true);

function onChangeColorMap(colorMap: ColorMap): void {
  const item = clipsGridItems[focusedClipIndex.value];
  if (item.type !== ClipsGridItemType.RegularClip) {
    return;
  }

  item.colorMap.value = colorMap;
}

//
// Clip list resizing
//

const clipListWidth = useStorage("clip-list-width", 300);

const clipListMinimumWidth = 96;

let clipListWidthAtResizeStart = 0;
function onClipListResizeStart(): void {
  clipListWidthAtResizeStart = clipListWidth.value;
}

function onClipListResize(size: { xDelta: number }): void {
  clipListWidth.value = clamp(clipListWidthAtResizeStart + size.xDelta, clipListMinimumWidth, 600);
}

//
// Measurement pane
//

const isMeasurementPaneVisible = useStorage("measurement-pane-visible", true);
const measurementPaneSearchTerm = ref("");

const measurementPaneMeasurementIdsToExpand = ref<string[]>([]);
const highlightedMeasurementId = ref<string | null>(null);
const hoveredMeasurementValueId = ref<string | null>(null);

// Returns measurement values visible in the measurement pane that have displayable contours.
// This is the list of measurement values that can be stepped through.
const visibleMeasurementValuesWithContours = computed(() =>
  getMatchingMeasurements(
    study.value.measurements,
    measurementPaneSearchTerm.value,
    patientMetrics.value
  )
    .sort((a, b) =>
      getMeasurementDisplayName(a, "unindexed").localeCompare(
        getMeasurementDisplayName(b, "unindexed")
      )
    )
    .map((mmt) =>
      [...mmt.values]
        .filter((v) => v.studyClipId !== null && v.frame !== null && v.contour !== null)
        .sort((a, b) => (a.value ?? Number.MAX_SAFE_INTEGER) - (b.value ?? Number.MAX_SAFE_INTEGER))
    )
    .flat()
);

// Returns the largest index in the above list of measurement values that are currently visible
// and soloed on a selected clip. This is used to determine what to show next when stepping
// through measurement values.
function getIndexOfLastSoloedMeasurementValue(): number | undefined {
  const selectedMeasurementValuesWithContours = regularClipsGridItems.value
    .map((c) =>
      visibleMeasurementValuesWithContours.value.find(
        (mv) =>
          mv.id === c.soloMeasurementValueId.value &&
          mv.studyClipId === c.clip?.id &&
          mv.frame === c.getCurrentFrame()
      )
    )
    .filter((mv) => mv !== undefined) as StudyMeasurementValue[];

  if (selectedMeasurementValuesWithContours.length === 0) {
    return undefined;
  }

  return Math.max(
    ...selectedMeasurementValuesWithContours.map((mv) =>
      visibleMeasurementValuesWithContours.value.indexOf(mv)
    )
  );
}

async function onShowNextMeasurementValues(): Promise<void> {
  const lastIndex = getIndexOfLastSoloedMeasurementValue();
  if (lastIndex === undefined) {
    await loadClipsForVisibleMeasurementValuesWithContours(0);
    return;
  }

  // Find the index that moves to the next set of measurement values
  let firstIndex =
    lastIndex + selectedClipIds.value.length - (lastIndex % selectedClipIds.value.length);

  // Wrap around to the beginning of the measurement value list if moved past the end
  if (firstIndex >= visibleMeasurementValuesWithContours.value.length) {
    firstIndex = 0;
  }

  await loadClipsForVisibleMeasurementValuesWithContours(firstIndex);
}

async function onShowPreviousMeasurementValues(): Promise<void> {
  const lastIndex = getIndexOfLastSoloedMeasurementValue();
  if (lastIndex === undefined) {
    await loadClipsForVisibleMeasurementValuesWithContours(0);
    return;
  }

  // If we're at the beginning of the measurement value list then wrap around to the end
  if (lastIndex < selectedClipIds.value.length) {
    const total = visibleMeasurementValuesWithContours.value.length;
    const lastGroupSize = total % selectedClipIds.value.length;

    await loadClipsForVisibleMeasurementValuesWithContours(
      total - (lastGroupSize === 0 ? selectedClipIds.value.length : lastGroupSize)
    );
  } else {
    // Move to the start of the previous set of measurement values
    await loadClipsForVisibleMeasurementValuesWithContours(
      lastIndex - (lastIndex % selectedClipIds.value.length) - selectedClipIds.value.length
    );
  }
}

async function loadClipsForVisibleMeasurementValuesWithContours(firstIndex: number): Promise<void> {
  if (visibleMeasurementValuesWithContours.value.length === 0) {
    return;
  }

  const measurementValuesToSelect = selectedClipIds.value.map(
    (_, index) =>
      visibleMeasurementValuesWithContours.value[firstIndex + index] as
        | StudyMeasurementValue
        | undefined
  );

  // Select the clips for the measurement values
  for (let i = 0; i < measurementValuesToSelect.length; i++) {
    selectedClipIds.value[i] = measurementValuesToSelect[i]?.studyClipId ?? "";
  }
  updateClipsGridItems();

  measurementPaneMeasurementIdsToExpand.value = [];

  // Set the frames for the newly selected clips and solo the specific measurement value so that
  // only it is visible on the clip
  for (let i = 0; i < measurementValuesToSelect.length; i++) {
    const valueToSelect = measurementValuesToSelect[i];
    const item = clipsGridItems[i];

    if (valueToSelect === undefined || item.type !== ClipsGridItemType.RegularClip) {
      continue;
    }

    item.setCurrentFrame(valueToSelect.frame ?? 0);
    item.soloMeasurementValueId.value = valueToSelect.id;

    measurementPaneMeasurementIdsToExpand.value.push(valueToSelect.measurementId);
  }

  // Scroll the measurement pane to the measurement for the first measurement value
  measurementIdToScrollTo.value = measurementValuesToSelect[0]?.measurementId ?? null;

  // Scroll the clip list as well
  await scrollClipListToClip(selectedClipIds.value[0]);
}

//
// Report pane
//

const isReportPaneVisible = ref(false);

function onToggleReporting(): void {
  if (!isReportPaneVisible.value && study.value.reports.length === 0) {
    return;
  }

  isReportPaneVisible.value = !isReportPaneVisible.value;

  if (isReportPaneVisible.value) {
    clipListWidth.value = clipListMinimumWidth;
    isMeasurementPaneVisible.value = false;
  }
}

const isCreatingReport = ref(false);
const studyListStore = useStudyListStore();

async function onCreateReport(reportTemplateVersionId: string): Promise<void> {
  if (isCreatingReport.value) {
    return;
  }

  isCreatingReport.value = true;
  let response: AxiosResponse<StudyReportGetOneResponseDto> | undefined = undefined;

  try {
    response = await axios.post<StudyReportGetOneResponseDto>(`/api/studies/${props.id}/reports`, {
      reportTemplateVersionId,
    });
    await queryClient.invalidateQueries({ queryKey: ["studies"] });
    addNotification({ type: "info", message: "Created study report" });
  } catch (error) {
    addNotification({ type: "error", message: "Failed creating study report" });
    return;
  } finally {
    isCreatingReport.value = false;
  }

  study.value.reports.push(response.data);

  // Also update the study list store to immediately reflect the new report
  studyListStore.updateStudy(props.id, {
    reports: [...study.value.reports],
  });

  if (!isReportPaneVisible.value) {
    onToggleReporting();
  }
}

function onLatestReportDeleted(): void {
  const latestReport = getLatestReport(study.value.reports);
  if (latestReport === undefined) {
    return;
  }

  study.value.reports.splice(study.value.reports.indexOf(latestReport), 1);

  // Also update the study list store to immediately reflect the deleted report
  studyListStore.updateStudy(props.id, {
    reports: [...study.value.reports],
  });

  // Hide the report pane if the last report is deleted
  if (study.value.reports.length === 0) {
    isReportPaneVisible.value = false;
  }
}

const reportPaneWidth = useStorage("report-pane-width", 50);
const reportContainerElement = ref<HTMLDivElement>();

const isReportResizing = ref(false);

let initialReportPaneWidth = 0;
let reportPaneWidthPercentPerPixel = 0;

function onResizeReportStart(): void {
  initialReportPaneWidth = reportPaneWidth.value;
  reportPaneWidthPercentPerPixel =
    reportPaneWidth.value / reportContainerElement.value!.offsetWidth;

  isReportResizing.value = true;
}

function onResizeReport(details: { xDelta: number }): void {
  reportPaneWidth.value = clamp(
    initialReportPaneWidth + details.xDelta * reportPaneWidthPercentPerPixel,
    20,
    80
  );
}

function onResizeReportEnd(): void {
  isReportResizing.value = false;
}

// R toggles report visibility
onKeyboardShortcut("r", onToggleReporting);

// M toggles measurement pane visibility
onKeyboardShortcut("m", () => {
  isMeasurementPaneVisible.value = !isMeasurementPaneVisible.value;
});

const patientStudies = usePatientStudies(study);

onUnmounted(() => {
  useStudyViewImageData().unloadImageData();

  // Close any serial study secondary window when navigating away from the study
  if (getSecondaryWindowType() === WindowType.SerialStudy) {
    postMessageToSecondaryWindow({ type: "close-window" });
  }
});
</script>

<style scoped lang="scss">
.study-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  min-height: 0;
  position: relative;
  background: var(--bg-color-1);
}

.study-loading-indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(black, 0.5);

  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.main-content {
  display: flex;
  flex: 1;
  min-height: 0;
  align-items: stretch;
  border-top: 1px solid var(--border-color-1);
}

.content-except-report {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.stress-mode-container {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
}

.clip-list-container {
  display: flex;
  place-items: stretch;
  min-height: 0;
  position: relative;
  max-width: 50%;
  background-color: var(--border-color-1);
}

.vertical-resize-handle {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 8px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;

  // The resize handle div is made the full width of the report while resizing. This fixes an issue
  // where the report iframe would swallow mouse events over it causing the resize to only work at
  // slow mouse speeds.
  &.resizing {
    width: 100%;
  }
}

.report-container {
  max-width: calc(100vw - 300px);
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 16fr min-content;
  position: relative;
  flex-grow: 1;
}

.measurement-pane-container {
  position: relative;
  display: flex;

  &.right-border {
    border-right: 1px solid var(--border-color-1);
  }
}
</style>
