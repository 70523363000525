<template>
  <RoleEdit :id="id" :model-value="role" activity-text="" :is-global-admin-role="true">
    <PermissionGroups
      v-if="role"
      :permission-groups="GLOBAL_ADMIN_PERMISSION_GROUPS"
      :role="{
        permissions: role.permissions,
        type: role.type,
      }"
    />
  </RoleEdit>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUserAdminRoleList } from "../../utils/user-roles-list";
import PermissionGroups from "../components/PermissionGroups.vue";
import { GLOBAL_ADMIN_PERMISSION_GROUPS } from "../permission-toggle";
import RoleEdit from "../RoleEdit.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const userRoleList = useUserAdminRoleList();
const role = computed(() => userRoleList.value.find((r) => r.id === props.id));
</script>
