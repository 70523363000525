<template>
  <div class="simple-table" :class="{ 'is-loading': isLoading }">
    <div v-if="!isLoading" class="table-container" :style="{ '--column-count': columns.length }">
      <div class="header">
        <div
          v-for="column in columns"
          :key="column.key"
          class="header-item"
          @click="onSortColumn(column)"
        >
          <Tooltip v-if="column.tooltip" :content="column.tooltip">
            <div class="title">{{ column.title }}</div>
          </Tooltip>
          <div v-else class="title">{{ column.title }}</div>
          <FontAwesomeIcon
            v-if="column.sortable"
            class="sort-icon"
            :class="{ visible: sortColumnName === column.key }"
            :icon="sortDirection === 'ASC' ? 'chevron-up' : 'chevron-down'"
          />
        </div>
      </div>

      <div class="header-line" />

      <div
        v-for="(item, index) in items"
        :key="getItemKey(item, index)"
        class="grid-table-row"
        @click="onRowClick(item)"
      >
        <div v-for="column in columns" :key="column.key">
          <slot :name="`cell-${column.key}`" :item="item" :value="item[column.key]">
            <template v-if="column.format === 'boolean'">
              {{ item[column.key] === "true" || item[column.key] === true ? "Yes" : "No" }}
            </template>
            <template v-else>
              {{ item[column.key] }}
            </template>
          </slot>
        </div>
      </div>
    </div>

    <LoadingIndicator v-if="isLoading" style="align-self: center" size="2x" />
  </div>
</template>

<script setup lang="ts">
/**
 * SimpleTable.vue
 *
 * A simple table component that displays a list of items in a grid layout.
 *
 * @param {TableColumnHeaderOptions[]} columns - The columns to display in the table.
 * @param {TableItem[]} items - The items to display in the table.
 * @param {boolean} isLoading - Whether the table is loading.
 */

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoadingIndicator from "../LoadingIndicator.vue";
import Tooltip from "../Tooltip.vue";

// Column definition
export interface TableColumnHeaderOptions {
  key: string;
  title: string;
  sortable?: boolean;
  tooltip?: string;
  format?: "boolean"; // Only boolean autoformat is supported for now
}

// Sort direction
export type SortDirection = "ASC" | "DESC";

// TableItem interface with guaranteed string id property
export interface TableItem {
  id: string;
  [key: string]: string | number | boolean | null;
}

interface Props {
  columns: TableColumnHeaderOptions[];
  items: TableItem[];
  isLoading?: boolean;
  sortColumnName?: string;
  sortDirection?: SortDirection;
  itemKey?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  sortColumnName: "",
  sortDirection: "ASC",
  itemKey: "id",
});

const emit = defineEmits<{
  (e: "sort", columnName: string, direction: SortDirection): void;
  (e: "row-click", itemId: string): void;
}>();

function onSortColumn(column: TableColumnHeaderOptions): void {
  if (column.sortable !== true) return;

  const newDirection =
    props.sortColumnName === column.key && props.sortDirection === "ASC" ? "DESC" : "ASC";
  emit("sort", column.key, newDirection);
}

function onRowClick(item: TableItem): void {
  emit("row-click", item.id);
}

function getItemKey(item: TableItem, index: number): string | number {
  const key = item[props.itemKey];
  return typeof key === "string" || typeof key === "number" ? key : index;
}
</script>

<style scoped lang="scss">
.simple-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-container {
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  row-gap: 8px;
}

.header {
  display: contents;

  .header-item {
    display: flex;
    gap: 12px;
    cursor: pointer;
    transition: color 100ms ease;

    .title {
      font-weight: bold;
    }

    .sort-icon {
      color: var(--accent-color-1);
      transition: color 100ms ease;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }

    &:hover {
      color: var(--text-color-2);

      .sort-icon {
        color: var(--accent-color-2);
      }
    }
  }
}

.header-line {
  grid-column: 1 / -1;
  border-bottom: 2px solid var(--bg-color-3);
  margin-bottom: 8px;
}

.grid-table-row {
  display: contents;
  cursor: pointer;

  > div {
    padding: 8px 12px 8px 0;
    transition: color 100ms ease;
  }

  &:hover > div {
    color: var(--text-color-2);
  }
}
</style>
